import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./campaign.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Table } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import Loader from "../Loader";
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";
import { toast } from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";

const CampaignPageSaas = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState([]);
  const [loadershown, setloadershown] = useState(false);
  const [company_name, setCompany_Name] = useState("");
  const [email, setEmail] = useState("");
  const [client_name, setClient_Name] = useState("");
  const [client_password, setClient_Password] = useState("");
  const [client_confirm_password, setClient_Confirm_Password] = useState("");
  const [Countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total_pages, setTotal_Pages] = useState(null);
  const [selected_country, setSelected_country] = useState(null);
  const [selected_country_name, setSelected_country_name] = useState("");
  const [access_report_selected, setAccess_report_selected] = useState(null);
  const [advertiser_name, setAdvertiser_name] = useState("");
  const [advertiser_id, setAdvertiser_id] = useState(null);
  const [search_value, setSearch_value] = useState("");
  const [advertiser_name_list, setAdvertiser_name_list] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [show_div, setShow_div] = useState(false);
  const [blue_tick, setBlue_tick] = useState(false);
  const [status_id, setStatus_id] = useState(null);
  const [sort, setSort] = useState(false);
  const [searchByText, setSearchByText] = useState(false);
  const [enableSearchButton, setEnableSearchButton] = useState(false)
  const [enableClearButton, setEnableClearButton] = useState(false)

  const {
    //here in this path we get the client id from the prvious page and here we can hit a endpoint to get all the information of selected advertiser nitin 07/09/2023
    client_id,
    path,
    check_calue,
    client_deal_id
  } = location.state ? location.state : location;

  //this below variable is used to get the advertiser_id from the localstorage and we used this in get all clients endpoint
  const permission = JSON.parse(localStorage.getItem("userdata"));

  const handleChange = () => {
    setChecked(!checked);
  };

  //we are getting all the list od advertiser from this below function and this same function
  //is also used for filter i am not creating another seprat function for filters
  // nitin - 31/08/2023

  useEffect(() => {
    handleGetAdvertiser(false);
  }, []);

  //the below useEffect function is used when we change the page and pageSize this will not render when we first time accdess this poge
  useEffect(() => {
    if (page !== 1 || pageSize !== 10) {
      handleGetAdvertiser(false);
    }
  }, [pageSize, page]);

  const handleGetAdvertiser = (clearButton) => {
    // we are getting all advertiser from here nitin 31/08/2023
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    var raw;

    if (clearButton) {
      raw = JSON.stringify({
        country_id: null,
        access_report: null,
        advertiser_id: !check_calue ? permission?.AdvertiserId : client_id,
        advertiser: null,
        id: null,
      });
    } else {
      raw = JSON.stringify({
        country_id: selected_country ? selected_country : null,
        access_report: access_report_selected ? access_report_selected : null,
        advertiser_id: !check_calue ? permission?.AdvertiserId : client_id,
        advertiser: advertiser_name ? advertiser_name : null,
        id: advertiser_id ? advertiser_id : null,
      });
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/advertiserClient/GetAdvertiserClientsList/${page}/${pageSize}`,
      requestOptions
    )
      .then((response) => response.json())
      //setting up the response in the state and using this state in map function
      .then((result) => {
        setData(result?.data?.results);
        setloadershown(false);
        setTotal_Pages(result?.data?.page_count);
      })
      .catch((error) => {
        setloadershown(false);
      });
  };


  //this function is used to go to next page it takes an id and sent this id to next page
  const goToNext = (id, company, email, accessReport, country, country_id) => {
    navigate("/campaignsaas/mediaplansaas", {
      state: {
        client_id: id,
        company_name: company,
        email: email,
        accessReport: accessReport,
        country: country,
        country_id: country_id,
        deal_status_id: status_id,
        advertiser_id: client_id ? client_id : permission?.AdvertiserId,
        client_deal_id: client_deal_id,
        path: path
      },
    });
  };

  const validateEmail = (email) => {
    // Regular expression for validating an email address
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  //this function is used to save the clients nitin 31/08/2023
  const handleSaveClient = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (company_name === "") {
      toast.error("Client name can't be empty!");
    } else if (email === "") {
      toast.error("Email can't be empty!");
    } else if (selectedCountry == null) {
      toast.error("Please select a country!");
    } else if (!re.test(email)) {
      toast.error("Please Enter a Valid Email")
    } else {
      setloadershown(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      const requestBody = {
        company_name: company_name,
        advertiser_id: client_id ? client_id : permission?.AdvertiserId,
        access_report: checked,
        email: email,
        country_id: selectedCountry,
        delete: false,
      };

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/advertiserClient/add`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((result) => {
          // Handle the successful response here
          setloadershown(false); // Assuming this hides the loader
          handleClose();
          handleGetAdvertiser(false);
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
          setloadershown(false); // Make sure to hide the loader in case of errors
        });
    }
  };

  //thius function is for to get all the countries that we used in add clients section nitin 31/08/2023
  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}countries/getCountries`,
      {
        method: "GET",
      }
    );
    const result = await response.json();
    setCountries(result.data);
  };

  // this below function is used to get all the advertiser based on their names nitin 21/09/2023
  const handle_show_advertiser_on_name = (e) => {
    setSpinner(true);
    setShow_div(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      advertiser: e.target.value,
      advertiser_id: !check_calue ? permission?.AdvertiserId : client_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/AdvertiserFilter`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAdvertiser_name_list(result?.data);
        setSpinner(false);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (company_name !== "" && email !== "" && selectedCountry !== null) {
      setBlue_tick(true);
    }
  }, [company_name, email, selectedCountry]);

  // this below function is used to get all the status of deal based upon the advertiser_id nitin 26/09/2023
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      AdvertiserId: client_id ? client_id : permission?.AdvertiserId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getdealstatus`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setStatus_id(result?.data?.status_id))
      .catch((error) => console.log("error", error));
  }, []);

  // this below function is used to sort the data nitin 11/10/2023
  const handle_sort = (field_name) => {
    setSort(!sort)
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      country_id: selected_country ? selected_country : null,
      access_report: access_report_selected ? access_report_selected : null,
      advertiser_id: client_id ? client_id : permission?.AdvertiserId,
      advertiser: advertiser_name ? advertiser_name : null,
      id: advertiser_id ? advertiser_id : null,
      FieldName: field_name,
      Order: sort ? "desc" : "asc"
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/advertiserClient/GetAdvertiserClientsList/${page}/${pageSize}`,
      requestOptions
    )
      .then((response) => response.json())
      //setting up the response in the state and using this state in map function
      .then((result) => {
        setData(result?.data?.results);
        setloadershown(false);
        setTotal_Pages(result?.data?.page_count);
      })
      .catch((error) => {
        setloadershown(false);
      });
  }

  useEffect(() => {
    searchByText && handleGetAdvertiser(false);
  }, [advertiser_name]);

  const handleSearchFilters = () => {
    handleGetAdvertiser(false);
    setEnableSearchButton(false);
    setEnableClearButton(true);
  }

  const handleClearFilters = () => {
    setSelected_country(null);
    setSelected_country_name("");
    setAccess_report_selected(null);
    setAdvertiser_name("");
    setAdvertiser_id(null);
    setSearch_value("");
    setEnableSearchButton(false);
    setEnableClearButton(false);
    handleGetAdvertiser(true);
  }

  return (
    <div>
      <div className="content_outer">
        <Loader showLoader={loadershown} />
        <div className="cmn_header_top mb-3">
          <h3>
            {" "}
            {path == "/adminSaas" && (
              <img
                src="/assets/images/chevron_left.svg"
                onClick={() => navigate("/adminSaas")}
              />
            )}
            Campaign Manager
          </h3>
          <div className="flex-grow-1 text-center">
            <div className="cmn_header__search position-relative">
              <AiOutlineSearch className={search_value.length > 0 ? "changeIcon" : ""} size={24} />
              <input
                type="text"
                placeholder="Search by Id or Advertiser"
                value={search_value}
                onChange={(e) => {
                  handle_show_advertiser_on_name(e);
                  setSearch_value(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSearchByText(true);
                    setAdvertiser_name(e.target.value);
                    setShow_div(false);
                    setSearch_value(e.target.value)
                  }
                }}
                onClick={() => setShow_div(false)}
              />
              {show_div && (
                <div className="search_dropown">
                  {spinner && (
                    <div className="p-3">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                  <ul>
                    {advertiser_name_list?.map((data, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            setSearchByText(true);
                            setAdvertiser_name(data?.advertiser);
                            setShow_div(false);
                            setSearch_value(data?.advertiser)
                          }}
                        >
                          {data?.advertiser}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {(status_id === 1 || status_id === 2) && (Object.values(permission.Permissions).includes('editcampaignsaas')) && (
            <div className="">
              <button onClick={handleShow} className="cmn_btn ms-0">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.25 19v-6.25H5v-1.5h6.25V5h1.5v6.25H19v1.5h-6.25V19h-1.5z"
                    fill="#fff"
                  />
                </svg>
                Add Client
              </button>
            </div>
          )}
        </div>
        <div className="content pt-0">
          <div className="App">
            <div className="cmn__filters campiagn_filter filter_menu w-100">
              <ul className="w-100">
                <li>
                  <img src="/assets/images/filter_list.svg" />
                </li>
                <li>
                  <Dropdown className="owner_filter">
                    {/* first */}
                    <Dropdown.Toggle id="dropdown-basic">
                      {selected_country_name
                        ? selected_country_name
                        : "Country"}
                      <svg
                        width="16"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="none"
                          stroke="#343a40"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m2 5 6 6 6-6"
                        />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Countries?.map((all_country, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setSelected_country(all_country?.id);
                              setSelected_country_name(all_country?.labelKey);
                              setEnableSearchButton(true);
                            }}
                          >
                            {all_country?.labelKey}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                {/* <li>
                  <Dropdown className="owner_filter">
                    <Dropdown.Toggle id="dropdown-basic">
                      {access_report_selected != null
                        ? (access_report_selected ? 'True' : 'False')
                        : "Access report"}
                      <svg
                        width="16"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="none"
                          stroke="#343a40"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m2 5 6 6 6-6"
                        />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setAccess_report_selected(true);
                          setEnableSearchButton(true);
                        }}
                      >
                        True
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setAccess_report_selected(false)
                          setEnableSearchButton(true);
                        }}
                      >
                        False
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li> */}
                <button
                  className={enableSearchButton ? "clear__btn purple_bg" : "clear__btn"}
                  onClick={handleSearchFilters}
                  disabled={!enableSearchButton}
                >
                  Search
                </button>
                <button
                  className={enableClearButton ? "clear__btn1" : "clear__btn"}
                  onClick={handleClearFilters}
                  disabled={!enableClearButton}
                >
                  Clear
                </button>
              </ul>
            </div>
            <div className="campiagn_outer">
              <h3 className="filter_title">Clients</h3>
            </div>
            {/* filter ends */}
            <div className="advert_tabel campign_index_table">
              <Table responsive>
                <thead>
                  <tr style={{ cursor: "pointer" }}>
                    <th className="text-start" onClick={() => handle_sort("id")}>ID <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                    <th className="text-start" onClick={() => handle_sort("company_name")}>Name <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                    <th className="text-start" onClick={() => handle_sort("country_id")}>Country <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                    {/* <th className="text-start" onClick={() => handle_sort("email")}>E-mail <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th> */}
                    {/* <th className="text-start">Access report</th> */}
                    {/* <th className="text-start">Reset Password</th> */}
                    <th className="text-start">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length === 0 && (
                    <div className="no_data">
                      <img
                        className="nodata"
                        src="/assets/images/notfound.svg"
                      />
                    </div>
                  )}
                  {data?.map((advertiserList, index) => {
                    return (
                      <tr key={index}>
                        <td
                          className="advertiserListId"
                          onClick={() =>
                            goToNext(
                              advertiserList?.id,
                              advertiserList?.company_name,
                              advertiserList?.email,
                              advertiserList?.access_report,
                              advertiserList?.country?.value,
                              advertiserList?.country?.id
                            )
                          }
                        >
                          {advertiserList?.id}
                        </td>
                        <td
                          onClick={() =>
                            goToNext(
                              advertiserList?.id,
                              advertiserList?.company_name,
                              advertiserList?.email,
                              advertiserList?.access_report,
                              advertiserList?.country?.value,
                              advertiserList?.country?.id
                            )
                          }
                        >
                          {advertiserList?.company_name}
                        </td>
                        <td
                          onClick={() =>
                            goToNext(
                              advertiserList?.id,
                              advertiserList?.company_name,
                              advertiserList?.email,
                              advertiserList?.access_report,
                              advertiserList?.country?.value,
                              advertiserList?.country?.id
                            )
                          }
                        >
                          {advertiserList?.country?.value}
                        </td>
                        {/* <td
                          onClick={() =>
                            goToNext(
                              advertiserList?.id,
                              advertiserList?.company_name,
                              advertiserList?.email,
                              advertiserList?.access_report,
                              advertiserList?.country?.value,
                              advertiserList?.country?.id
                            )
                          }
                        >
                          {advertiserList?.email}
                        </td>
                        <td
                          onClick={() =>
                            goToNext(
                              advertiserList?.id,
                              advertiserList?.company_name,
                              advertiserList?.email,
                              advertiserList?.access_report,
                              advertiserList?.country?.value,
                              advertiserList?.country?.id
                            )
                          }
                        >
                          {advertiserList?.access_report ? "True" : "False"}
                        </td>
                        <td>
                          <div className="pdf_icon">
                            <img src="/assets/images/lock_reset.svg" />
                          </div>
                        </td> */}
                        <td
                          onClick={() =>
                            goToNext(
                              advertiserList?.id,
                              advertiserList?.company_name,
                              advertiserList?.email,
                              advertiserList?.access_report,
                              advertiserList?.country?.value,
                              advertiserList?.country?.id
                            )
                          }
                        >
                          <div className="pdf_icon">
                            <img src="/assets/images/description_file.svg" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {data?.length != 0 && (
                <div className="cmn__pagination">
                  <ul>
                    <span>Rows per page</span>
                    <li className="no__rows">
                      <select
                        name=""
                        id=""
                        onChange={(e) => {
                          setPageSize(e.target.value);
                        }}
                      >
                        {/* <option>select</option> */}
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                    </li>
                    <li className="total_pages me-3">
                      <span className="active_pages">{page}</span> of{" "}
                      <span>{total_pages}</span>
                    </li>
                    {/* here i used some validations if the total pages is 1 than these icons not shown to user nitin 31/08/2023 */}
                    {page !== 1 && (
                      <li>
                        <BsChevronBarLeft
                          onClick={() => {
                            setPage(1);
                          }}
                        />
                      </li>
                    )}
                    {page !== 1 && (
                      <li>
                        <BsChevronLeft
                          onClick={() => {
                            setPage(page - 1);
                          }}
                        />
                      </li>
                    )}
                    {page !== total_pages && (
                      <li>
                        <BsChevronRight
                          onClick={() => {
                            setPage(page + 1);
                          }}
                        />
                      </li>
                    )}
                    {page !== total_pages && (
                      <li>
                        <BsChevronBarRight
                          onClick={() => {
                            setPage(total_pages);
                          }}
                        />
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="saas_modal_head">
          <Loader showLoader={loadershown} />
          <Modal.Title id="contained-modal-title-vcenter">
            New client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <Row>
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">
                  Client Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Client Name*"
                  value={company_name}
                  onChange={(e) => setCompany_Name(e.target.value)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">
                  Email
                </label>
                <input
                  className="form-control"
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group select_country_container cmn_modal_field mb-3">
                <label className="lbl_position " for="campaignName">
                  Country
                </label>
                <select
                  name=""
                  id=""
                  className="form-select"
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  <option>Select</option>
                  {Countries?.map((allCountriesList, index) => {
                    return (
                      <option value={allCountriesList?.id} key={index}>
                        {allCountriesList?.labelKey}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
          </Row>
          {checked && (
            <div className="access_report">
              <h5>Access report</h5>
              <Row>
                <Col lg={12}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " for="campaignName">
                      User Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="User Name"
                      value={client_name}
                      onChange={(e) => setClient_Name(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " for="campaignName">
                      Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      value={client_password}
                      onChange={(e) => setClient_Password(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " for="campaignName">
                      Confirm Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Confirm Password"
                      value={client_confirm_password}
                      onChange={(e) =>
                        setClient_Confirm_Password(e.target.value)
                      }
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <div className="model_btn">
            <button className="cancel_cm_btn" onClick={handleClose}>
              Cancel
            </button>
            <button
              className={blue_tick ? "cmn_btn" : "cmn_btn cancel_cmn ms-2"}
              type="submit"
              // by hitting this button we will save the new clients nitin 31/08/2023
              onClick={() => handleSaveClient()}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CampaignPageSaas;