const incompatibleData = [
  {selectedRow: "App Name", incompatibleRow: "Youtube Channel"},
  {selectedRow: "Creative", incompatibleRow: "Country Youtube"},
  {selectedRow: "Youtube Channel", incompatibleRow: "Country App"},
  {selectedRow: "Country Youtube", incompatibleRow: "App Name"},
  {selectedRow: "Youtube Channel", incompatibleColumn: "Clicks"},
  {selectedRow: "Country Youtube", incompatibleColumn: "Clicks"},
  {selectedRow: "Youtube Channel", incompatibleColumn: "CTR"},
  {selectedRow: "Country Youtube", incompatibleColumn: "CTR"},
  {selectedColumn: "Quartiles", incompatibleRow: "Country Youtube"},
  {selectedColumn: "Quartiles", incompatibleRow: "Youtube Channel"},
  {selectedRow: "Devices", incompatibleRow: "Country Youtube"},
  {selectedRow: "Devices", incompatibleRow: "Youtube Channel"},
  {selectedRow: "Date", incompatibleRow: "Youtube Channel"},
  {selectedRow: "Date", incompatibleRow: "App Name"},
  {selectedInventoryType: "Apps", incompatibleRow: "Youtube Channel"},
  {selectedInventoryType: "Apps", incompatibleRow: "Country Youtube"},
  {selectedInventoryType: "Youtube", incompatibleRow: "Country App"},
  {selectedInventoryType: "Youtube", incompatibleRow: "App Name"}  
]

export default incompatibleData;