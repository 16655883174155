import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./campaign.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FaChevronRight } from "react-icons/fa";
import ProgressBar from 'react-bootstrap/ProgressBar'
import Form from "react-bootstrap/Form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  AiOutlineCheckCircle,
  AiOutlineSearch
} from "react-icons/ai";
import { FiAlertCircle } from 'react-icons/fi';

import { MdOutlineHandshake } from "react-icons/md"
import { BiCalendar } from "react-icons/bi"
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import {
  LineItemsSelector,
  getInventories,
  getLineitemFormats,
  getOfferTypes,
  getCountries,
  getLineItemStatus,
  getCampaign,
  changeLineItemStatus,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import {
  campaignManagerSelector,
  deal_currency,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import {
  updateBreadCrumb,
} from "../../app/features/Common/CommonSlice";
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";
import moment from "moment";
import { optimize_data_general } from "../../app/features/optimizeTab/optimizeTabGeneralSlice";
import Modal from "react-bootstrap/Modal";
import NewCreatives from "./newCreatives";
import NewCompaign from "./newCompaign";
import {
  ContextMenuTrigger,
} from "rctx-contextmenu";
import Loader from "../Loader";
import { toast } from "react-hot-toast";
import { Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getDevices } from "../../app/features/LineItemManager/LineItemManagerSlice";
import {
  changeCampaignStatus,
} from "../../app/features/CampaignManager/MediaPlanManager";
import EditLineItems from "./editLineItems";
import AddLineItems from "./AddLineItems";
import Spinner from 'react-bootstrap/Spinner';
import { upperCaseFirstLetter, lowerCaseAllWordsExceptFirstLetters } from "../../utils";
import { get_pacing } from "../../app/features/update_optimize_tab/get_pacing";
import { get_pacing_rate } from "../../app/features/update_optimize_tab/get_pacing_rate_slice";
import { clear_update_pacing_rate_state } from "../../app/features/update_optimize_tab/update_pacing_rate_slice";
import { clear_update_pacing_state } from "../../app/features/update_optimize_tab/update_pacing_slice";
import { optimize_tab_report_data } from "../../app/features/optimizeTab/optimize_tab_report_data_slice";
import { update_optimize_tab_general } from "../../app/features/optimizeTab/update_optimize_tab_slice";
import { kite_states } from '../../app/features/kiteStates/kite_states_slice';
import { clear_optimize_tab_general_state } from "../../app/features/optimizeTab/optimizeTabGeneralSlice";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

const LineItems = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [creativemodalShow, setCreativeModalShow] = useState(false);
  const [modalcampaignShow, setModalCampaignShow] = useState(false);
  const [loadershown, setloadershown] = useState(true);
  const [lineitemarray, setLineitemarray] = useState([]);
  const [page, setPage] = useState(1);
  const [statusmodalShow, setStatusModalShow] = useState(false);
  const [editLineItemModal, setEditLineItemModal] = useState(false)
  const [lineItemId, setLineItemId] = useState(null);
  const [modal_open, setModal_open] = useState(false);
  const [menu_item, setMenu_item] = useState(true);
  const [asc, setAsc] = useState(false);
  const [campaignSearch, setCampaignSearch] = useState("")
  const [searchCampaignId, setSearchCampaignId] = useState("")
  const [filteredLineData, setFilteredLineData] = useState([]);
  const [pageNo, setPageNo] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [page_Count, setPage_count] = useState(0)
  const [current_Page, setCurrent_Page] = useState(1)
  const [inventoryId, setInventoryId] = useState()
  const [formatId, setFormatId] = useState()
  const [offerId, setOfferId] = useState()
  const [countryId, setCountryId] = useState()
  const [statusId, setStatusId] = useState()
  const [inventoryTypeId, setInventoryTypeId] = useState()
  const [formatTypeId, setFormatTypeId] = useState()
  const [offerTypeId, setOfferTypeId] = useState()
  const [countryTypeId, setCountryTypeId] = useState()
  const [statusTypeId, setStatusTypeId] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [searchSet, setSearch] = useState(false)
  const [tempInventory, setTempInventory] = useState("Inventory")
  const [tempFormat, setTempFormat] = useState("Format")
  const [tempOffer, setTempOffer] = useState("Offer")
  const [tempCountry, setTempCountry] = useState("Country")
  const [tempStatus, setTempStatus] = useState("Status")
  const [startend, setStartend] = useState({ start: "", end: "" });
  const [daterange, setrange] = useState("");
  const pickerref = useRef(null);
  const [id, setId] = useState();
  const [billable, setBillable] = useState(false)
  const [campId, setCampId] = useState()
  const [editId, setEditId] = useState()
  const [editStatus, setEditStatus] = useState("")
  const [editStatusId, setEditStatusId] = useState()
  const [statusLineItem, setStatusLineItem] = useState(false)
  const [lineitem_list, setLineitem_list] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [show_div, setShow_div] = useState(false)
  const [optimizeQuery, setOptimizeQuery] = useState(false);
  const [lineitemIDS, setLineitemIDS] = useState([])
  const [update_bulk, setUpdate_bulk] = useState(false)
  const [reportData, setReportData] = useState([])
  const [states, setStates] = useState([])
  const [filterTrue, setFilterTrue] = useState(true)
  const [validate_apply_button, setValidate_apply_button] = useState(false)
  const [selected_budget, setSelected_budget] = useState(null)
  const [pdfTrue, setPdfTrue] = useState(false)
  const [hide_filter,setHide_filter] = useState(true)
  const [total_records,setTotal_records] = useState(null)
  const [searchQuery, setSearchQuery] = useState(false);
  const [statusmodel, setStatusModel] = useState({
    messageText: "",
    buttonText: "",
    buttonClass: "",
    lineItemId: "",
    lineItemStatus: "",
  });
  const {
    countries,
  } = useSelector(LineItemsSelector);
  

  useEffect(() => {
    if (countries.length == 0) {
      dispatch(getCountries({ token: localStorage.getItem("token") }));
    }
  }, [])

  let {
    dealid,
    deal_id,
    campaignId,
    advertiser,
    clientId,
    total_budget,
    sum_budget,
    startdate,
    enddate,
    currency_code,
    remainingBudget,
    campaignStatus,
    lineitem_id,
    lineitem_name,
    lineitem_inventory,
    lineitem_start_date,
    lineitem_end_date,
    lineitem_format,
    lineitem_offer,
    lineitem_country,
    lineitem_status,
    filterValue,
    fromCreatives
  } = location.state ? location.state : location;
  const {
    filtered_items,
    campaign_detail,
    line_item_status,
    inserted_lineitem_id,
    devices,
  } = useSelector(LineItemsSelector);
  const { dealCurrency, deal_detail } = useSelector(campaignManagerSelector);
  const optimize_data = useSelector((state) => { return state.optimize_general })
  const update_optimize_tab = useSelector((state) => { return state.update_optimize_general })
  const [mapData, setMapData] = useState([])

  useEffect(() => {
    dispatch(kite_states({ CountryId: [4, 12, 21] }))
  }, [])

  const all_states = useSelector((state) => { return state.kitestates })

  useEffect(() => {
    if (all_states?.isSuccess) {
      setStates(all_states?.data?.data[0])
    }
  }, [all_states])

  useEffect(() => {
    dispatch(clear_optimize_tab_general_state())
  }, [])


  useEffect(() => {
    setMapData(optimize_data?.data?.data)
  }, [optimize_data])
  var editable_data = []

  useEffect(() => {
    if (update_optimize_tab?.isSuccess) {
      dispatch(optimize_data_general({ id: campaign_detail?.id }))
      setUpdate_bulk(false)
      getPaginateData()
      getcampain()
    }
  }, [update_optimize_tab])

  useEffect(() => {
    if (fromCreatives) {
      getPaginateData()
    }
  }, [fromCreatives])

  useEffect(() => {
    let lineitem_ids = []

    optimize_data?.data?.data?.map((ids, i) => {
      lineitem_ids.push(ids?.id)
    })
    setLineitemIDS(lineitem_ids)
  }, [optimize_data])

  useEffect(() => {
    if (mapData?.length > 0) {
      let updatedMapData = [...mapData]; // Create a copy of mapData
      mapData?.forEach((data, i) => {
        let country_id = [];
        if (!data?.countryTrue) {
          data?.countryList?.forEach((count) => {
            country_id?.push(count?.id);
          });
          if (JSON.stringify(updatedMapData[i]?.countriesData) !== JSON.stringify(country_id)) {
            updatedMapData[i] = { ...updatedMapData[i], countriesData: country_id };
          }
        }
      });
      if (JSON.stringify(updatedMapData) !== JSON.stringify(mapData)) {
        setMapData(updatedMapData);
      }
    }
  }, [mapData, optimize_data]);


  useEffect(() => {
    if (mapData?.length > 0) {
      let updatedMapData = [...mapData]; // Create a copy of mapData
      mapData?.forEach((data, i) => {
        let state_ids = [];
        if (!data?.stateTrue) {
          data?.stateList?.forEach((state) => {
            state_ids?.push(state?.id);
          });
          if (JSON.stringify(updatedMapData[i]?.statesData) !== JSON.stringify(state_ids)) {
            updatedMapData[i] = { ...updatedMapData[i], statesData: state_ids };
          }
        }
      });
      if (JSON.stringify(updatedMapData) !== JSON.stringify(mapData)) {
        setMapData(updatedMapData);
      }
    }
  }, [mapData, optimize_data]);


  useEffect(() => {
    if (lineitemIDS?.length !== 0) {
      dispatch(optimize_tab_report_data({ lineitemIDS }))
    }
  }, [lineitemIDS])

  useEffect(() => {
    if (optimizeQuery && !searchQuery) {
      dispatch(optimize_data_general({ id: campaign_detail?.id }))
    }
  }, [campaign_detail?.id, optimizeQuery])

  const [searchfilter, SetSearchfilter] = useState([]);
  const date = Date.now();
  const [showBillableMenu, setShowBillableMenu] = useState(true)
    

  // here on below variables we are storing all pacings
  const pacing = useSelector((state) => { return state.all_pacing })

  // this api is get all the pacings for optimize tab nitin
  useEffect(() => {
    dispatch(get_pacing())
  }, [])

  // here on below variables we are storing all pacings rates
  const pacing_rate = useSelector((state) => { return state.all_pacing_rate })

  // this api is get all the pacings rates for optimize tab nitin
  useEffect(() => {
    dispatch(get_pacing_rate())
  }, [])

  const updated_pacing_data = useSelector((state) => { return state.update_pacing_value })
  const update_pacing_rate_data = useSelector((state) => { return state.update_pacing_rate_value })
  const optimize_report_details = useSelector((state) => { return state.optimizeTab_report_data })

  useEffect(() => {
    if (optimize_report_details?.isSuccess) {
      var data = Object.values(optimize_report_details?.data?.data)
    }
    setReportData(data);
  }, [optimize_report_details])

  useEffect(() => {
    if (updated_pacing_data?.isSuccess) {
      dispatch(optimize_data_general({ id: campaign_detail?.id }))
      dispatch(clear_update_pacing_state())
    }
  }, [updated_pacing_data])

  useEffect(() => {
    if (update_pacing_rate_data?.isSuccess) {
      dispatch(optimize_data_general({ id: campaign_detail?.id }))
      dispatch(clear_update_pacing_rate_state())
    }
  }, [update_pacing_rate_data])


  const [filter, setFilter] = useState({
    inventoryId: {
      name: "Inventory Type",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },
    formatId: {
      name: "Format",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },

    offerTypeId: {
      name: "Offer Type",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },
    countryId: {
      name: "Country",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },
    statusId: {
      name: "Status",
      type: "dropdown",
      values: [],
      shown: true,
      multiselect: false,
    },
  });

  const [filtervalue, setFilterValue] = useState({
    inventoryId: "",
    formatId: "",
    offerTypeId: "",
    countryId: "",
    statusId: "",
  });

  const [selectedfilter, SetSelectedfilter] = useState({
    inventoryId: {
      name: "",
      value: "",
      key: "inventoryId",
    },
    formatId: {
      name: "",
      value: "",
      key: "formatId",
    },
    offerTypeId: {
      name: "",
      value: [],
      key: "offerTypeId",
    },
    countryId: {
      name: "",
      value: "",
      key: "countryId",
    },
    statusId: {
      name: "",
      value: "",
      key: "statusId",
    },
  });


  useEffect(() => {

    dispatch(getInventories({ token: localStorage.getItem("token") }))
      .then((result) => {
        setInventoryId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getLineitemFormats({ token: localStorage.getItem("token") }))
      .then((result) => {
        setFormatId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getOfferTypes({ token: localStorage.getItem("token") }))
      .then((result) => {
        setOfferId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getCountries({ token: localStorage.getItem("token") }))
      .then((result) => {
        setCountryId(result?.payload?.data)
      })
      .catch((err) => { });
    dispatch(getLineItemStatus({ token: localStorage.getItem("token") }))
      .then((result) => {
        setStatusId(result?.payload?.data)
      })
      .catch((err) => { });
    getcampain();
  }, []);

  useEffect(() => {
    if (lineitem_id == undefined || lineitem_name == undefined || lineitem_inventory == undefined || lineitem_start_date == undefined || lineitem_end_date == undefined || lineitem_format == undefined || lineitem_offer == undefined || lineitem_country == undefined || lineitem_status == undefined) {
      setFilteredLineData(filtered_items?.results);
    }
  }, [filtered_items?.results]);

  useEffect(() => {
    setBillable(campaign_detail.is_billable)
    setCampId(campaign_detail.id)
  }, [])

  useEffect(() => {
    if (campaign_detail.is_billable === 2 && !(Object.values(permission.Permissions).includes('editBill'))) {
      setShowBillableMenu(false);
    }
  }, [campaign_detail.is_billable])

  useEffect(() => {
    if (devices.length == 0) {
      dispatch(getDevices({ token: localStorage.getItem("token") }));
    }
  }, []);

  useEffect(() => {
    if (deal_id && dealCurrency == "") {
      dispatch(
        deal_currency({ token: localStorage.getItem("token"), dealId: dealid })
      );
    }
    if (Object.keys(campaign_detail)?.length > 0) {
      dispatch(
        updateBreadCrumb([
          {
            name: advertiser,
            params: { dealid: dealid, deal_id: deal_id },
            path: "campaign/mediaplan",
          },
          {
            name: campaign_detail?.name,
            params: {
              dealid: dealid,
              deal_id: deal_id,
              campaignId: campaignId,
              advertiser: advertiser,
              clientId: clientId,
            },
            path: "campaign/lineitems",
          },
        ])
      );
    }
  }, [deal_id, campaign_detail]);

  const getcampain = () => {
    setloadershown(true)
    dispatch(
      getCampaign({
        token: localStorage.getItem("token"),
        campaignId: campaignId,
      })
    );
    setloadershown(false)
  };

  useEffect(() => {
    onRefresh();
  }, [campaign_detail]);

  const onRefresh = () => {
    return campaign_detail;
  };

  const filter_search = (e) => {
    let filtered_array = [];
    if (e.target.value.length > 2) {
      Object.keys(filter).filter((element, index) => {
        if (element == "inventoryId") {
          filter[element].values.filter((inventory, index) => {
            if (inventory.description) {
              if (
                inventory.description
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: inventory.id,
                    value: inventory.description,
                    name: element,
                  },
                });
              }
            }
          });
        }

        if (element == "formatId") {
          filter[element].values.filter((format, index) => {
            if (format.name) {
              if (
                format.name.toLowerCase().includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: format.id,
                    value: format.name,
                    name: element,
                  },
                });
              }
            }
          });
        }
        if (element == "offerTypeId") {
          filter[element].values.filter((offers, index) => {
            if (offers.descripcion) {
              if (
                offers.descripcion
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: offers.id,
                    value: offers.descripcion,
                    name: element,
                  },
                });
              }
            }
          });
        }
        if (element == "countryId") {
          filter[element].values.filter((country, index) => {
            if (country.value) {
              if (
                country.value
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: country.id,
                    value: country.value,
                    name: element,
                  },
                });
              }
            }
          });
        }
        if (element == "statusId") {
          filter[element].values.filter((status, index) => {
            if (status.description) {
              if (
                status.description
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ) {
                let filtername = filter[element].name;
                filtered_array.push({
                  [filtername]: {
                    id: status.id,
                    value: status.description,
                    name: element,
                  },
                });
              }
            }
          });
        }
      });
    }
    SetSearchfilter(filtered_array);
  };

  useEffect(() => {
    searchFilter();
  }, [page]);

  const searchFilter = () => {
    let posted_array = Object.assign({}, filtervalue);

    Object.keys(posted_array).forEach((key) => {
      if (
        posted_array[key] === "" ||
        (typeof posted_array[key] === "object" && posted_array[key].length == 0)
      ) {
        delete posted_array[key];
      }
    });
    const u = new URLSearchParams(posted_array).toString();
  };

  const billableOptions = [
    {
      id: 0,
      description: 'Not to bill',
    },
    {
      id: 1,
      description: 'Ready to bill',
    },
    {
      id: 2,
      description: 'Billed',
    }
  ]

  const gotonext = (lineItem, campaignId, status_id) => {
    navigation("/campaign/creatives", {
      state: {
        lineItem,
        startDate: campaign_detail?.date_from,
        endDate: campaign_detail?.date_to,
        creativemodalShow,
        campaignId: campaignId,
        advertiser: advertiser,
        campaignName: campaign_detail?.name,
        deal_id: deal_id,
        clientId: clientId,
        total_Budget: campaign_detail?.budge,
        sum_Budget: campaign_detail?.lineItemTotalBudget,
        status_id: status_id,
        dealCurrency: dealCurrency,
        lineitem_id: searchCampaignId,
        lineitem_name: campaignSearch,
        lineitem_inventory: inventoryTypeId,
        lineitem_start_date: startend.start,
        lineitem_end_date: startend.end,
        lineitem_format: formatTypeId,
        lineitem_offer: offerTypeId,
        lineitem_country: countryTypeId,
        lineitem_status: statusTypeId,
        currency_code: currency_code,
      },
    });
  };

  const statusPopup = (lineItemId, actualStatusDescription, newStatusDescription, newStatusId) => {
    if(actualStatusDescription=="ACTIVE" && !(newStatusDescription=="PAUSE" || newStatusDescription=="INACTIVE")){
      toast.error("You can only change status to Inactive or Pause from Active")      
    }
    else if(actualStatusDescription=="DRAFT" && !(newStatusDescription=="ACTIVE")){
        toast.error("You can only change status to Active from Draft")      
    }
    else if(actualStatusDescription=="PAUSE" && !(newStatusDescription=="ACTIVE" || newStatusDescription=="INACTIVE")){
        toast.error("You can only change status to Active or Inactive from Pause")
    }
    else if (actualStatusDescription=="INACTIVE"){
        toast.error("You cannot change Inactive status")
    }
    else {
      if (newStatusDescription === "ACTIVE" && (campaign_detail?.statusNavigation?.description !== "ACTIVE" && campaign_detail?.statusNavigation?.description !== undefined)) {
        setStatusLineItem(true)
      } else {
        setStatusModel({
          ...statusmodel, // Copy the old fields
          messageText: "Do you want to confirm the status change for the selected IDs",
          buttonText: "Confirm",
          buttonClass: "cmn_btn ",
          lineItemId: lineItemId,
          lineItemStatus: newStatusId,
        });
        setStatusModalShow(true);
      }
    };
  }
  const updateStatus = () => {
    setStatusModalShow(false);
    setloadershown(true);
    dispatch(
      changeLineItemStatus({
        token: localStorage.getItem("token"),
        lineitem_id: statusmodel.lineItemId,
        status_id: statusmodel.lineItemStatus,
      })
    )
      .then((res) => {
        getStatusResolved()
        setStatusLineItem(false)
        dispatch(optimize_data_general({ id: campaign_detail?.id }))
        setloadershown(false);
      })

  };

  const resetstatus = () => {
    setStatusModel({
      ...statusmodel, // Copy the old fields
      messageText: "",
      buttonText: "",
      buttonClass: "",
      lineItemId: "",
      lineItemStatus: "",
    });
  };
  const reset_filter = () => {
    let posted_array = Object.assign({}, filtervalue);
    Object.keys(posted_array).forEach((key) => {
      if (
        posted_array[key] !== "" ||
        (typeof posted_array[key] === "object" && posted_array[key].length > 0)
      ) {
        if (typeof posted_array[key] === "object") {
          posted_array[key] = [];
          SetSelectedfilter((prev_val) => ({
            ...prev_val,
            [key]: { ...selectedfilter[key], value: [] },
          }));
        } else {
          posted_array[key] = "";
          SetSelectedfilter((prev_val) => ({
            ...prev_val,
            [key]: { ...selectedfilter[key], value: "" },
          }));
        }
      }
    });
    setFilterValue(posted_array);
  };
  const editCampaign = () => {
    setModalCampaignShow(true);
  };

  useEffect(() => {
    if (lineitemarray.length != 0) {
      handleCreativeItems();
    }
  }, []);

  const handleCreativeItems = () => {
    const new_arr = [];
    campaign_detail?.creatives?.map((data) => {
      new_arr.push(...new_arr, data.lineitemId);
      setLineitemarray(new_arr);
    });
  };

  const handleSort = (field) => {
    setloadershown(true)
    setAsc(!asc);
    let assc;
    if (asc) {
      assc = "asc";
    } else {
      assc = "desc";
    }

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}?page=1&FieldName=${field}&Order=${assc}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setFilteredLineData(result?.data?.results);
		setloadershown(false)
      })
      .catch((error) => {
      		toast.error("Some internal error occured!!");
      		setloadershown(false)
      		});
  };

  const percentage = (campaign_detail?.lineItemTotalBudget / campaign_detail?.budge) * 100

  useEffect(() => {
    if (filterTrue && filterValue == undefined) {
      getPaginateData()
    }
  }, [pageLimit !== 10, pageNo !== 1])

  const getPaginateData = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}?page=${pageNo}&pageSize=${pageLimit}`, requestOptions)
      .then(response => response.json())
      .then(result => {setFilteredLineData(result?.data?.results); setPage_count(result?.data?.page_count); setCurrent_Page(result?.data?.current_page); setloadershown(false);setTotal_records(result?.data?.row_count);!hide_filter && setPdfTrue(true) })
      .catch(error => toast.error('Internal server error occured'));
  }

  useEffect(() => {
    if (lineitem_status || lineitem_inventory || lineitem_format || lineitem_offer || lineitem_country || lineitem_id || lineitem_name) {
      getPreviousSearchedData()
    }
  }, [lineitem_status, lineitem_inventory, lineitem_format, lineitem_offer, lineitem_country, lineitem_id, lineitem_name])

  const getPreviousSearchedData = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}?page=${pageNo}&pageSize=${pageLimit}`;

    if (lineitem_status) {
      url += `&statusId=${lineitem_status}`;
    }
    if (lineitem_inventory) {
      url += `&inventoryId=${lineitem_inventory}`;
    }
    if (lineitem_format) {
      url += `&formatId=${lineitem_format}`;
    }
    if (lineitem_offer) {
      url += `&offerTypeId=${lineitem_offer}`;
    }
    if (lineitem_country) {
      url += `&countryId=${lineitem_country}`;
    }
    if (lineitem_id) {
      url += `&Lineitemid=${lineitem_id}`;
    }
    if (lineitem_name) {
      url += `&name=${lineitem_name}`;
    }
    if (lineitem_start_date !== null) {
      url += `&date_from=${lineitem_start_date}`;
    }
    if (lineitem_end_date !== null) {
      url += `&date_to=${lineitem_end_date}`;
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        setFilteredLineData(result?.data?.results);
        setloadershown(false)

      })
      .catch(error => toast.error('internal server error occurred'));
  }

  const getSearchedData = (searchedName, searchedLineItemId) => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}?page=${pageNo}&pageSize=${pageLimit}`;

    if (statusTypeId) {
      url += `&statusId=${statusTypeId}`;
    }
    if (inventoryTypeId) {
      url += `&inventoryId=${inventoryTypeId}`;
    }
    if (formatTypeId) {
      url += `&formatId=${formatTypeId}`;
    }
    if (offerTypeId) {
      url += `&offerTypeId=${offerTypeId}`;
    }
    if (countryTypeId) {
      url += `&countryId=${countryTypeId}`;
    }
    if (searchedLineItemId) {
      url += `&Lineitemid=${searchedLineItemId}`;
    }
    if (searchedName) {
      url += `&name=${searchedName}`;
    }
    if (startend.start !== null) {
      url += `&date_from=${startend.start}`;
    }
    if (startend.end !== null) {
      url += `&date_to=${startend.end}`;
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        setFilteredLineData(result?.data?.results);
        setloadershown(false)

      })
      .catch(error => toast.error('Internal server error occurred'));
  }

  const get_filtered_optimize_data = (searchedName, searchedLineItemId) => {
    setloadershown(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineitemoptimization?campaignId=${campaignId}`;
    if (statusTypeId) {
      url += `&statusId=${statusTypeId}`;
    }
    if (inventoryTypeId) {
      url += `&inventoryId=${inventoryTypeId}`;
    }
    if (formatTypeId) {
      url += `&formatId=${formatTypeId}`;
    }
    if (offerTypeId) {
      url += `&offerTypeId=${offerTypeId}`;
    }
    if (searchedLineItemId) {
      url += `&Lineitemid=${searchedLineItemId}`;
    }
    if (searchedName) {
      url += `&name=${searchedName}`;
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        const newResult = result?.data.map(obj => ({
          ...obj,
          inversionBudgetOriginal: obj.inversionBudget
        }));
        setMapData(newResult);
        setloadershown(false)

      })
      .catch(error => {
        toast.error('internal server error occurred');
        setloadershown(false)
      });

  }

  const handleApply = (event, picker) => {
    setSearch(true)
    picker.element.val(
      picker.startDate.format("DD/MM/YYYY") +
      " - " +
      picker.endDate.format("DD/MM/YYYY")
    );
    setStartend({
      start: picker.startDate.format("YYYY-MM-DD"),
      end: picker.endDate.format("YYYY-MM-DD"),
    });
    setrange(
      picker.startDate.format("YYYY-MM-DD") +
      " - " +
      picker.endDate.format("YYYY-MM-DD")
    );
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  const permission = JSON.parse(localStorage.getItem('userdata'));

  const checkBillablePermissons = (selectedValue) => {
    return (
      (Object.values(permission.Permissions).includes('readyToBill') && campaign_detail?.is_billable == 0 && selectedValue == 1) ||
      (Object.values(permission.Permissions).includes('readyToBill') && campaign_detail?.is_billable == 1 && selectedValue == 0) ||
      (Object.values(permission.Permissions).includes('editBill') && campaign_detail?.is_billable == 1 && selectedValue == 2) ||
      (Object.values(permission.Permissions).includes('editBill') && campaign_detail?.is_billable == 2 && selectedValue == 0) ||
      (Object.values(permission.Permissions).includes('editBill') && campaign_detail?.is_billable == 2 && selectedValue == 1)
    )
  }

  const handleBillable = (selectedValue) => {
    if (checkBillablePermissons(selectedValue)) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "campaignId": campaignId,
        "is_billable": selectedValue
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/send-billMail`, requestOptions)
        .then(response => response.json())
        .then(result => {
          (campaign_detail?.is_billable == 0 && selectedValue == 1) && toast.success(`Campaign id ${campaignId} is Ready For billing`);
          (campaign_detail?.is_billable == 1 && selectedValue == 2) && toast.success(`Campaign id ${campaignId} is already billed`);
          (campaign_detail?.is_billable == 1 && selectedValue == 0) && toast.success(`Campaign id ${campaignId} is NOT Ready For billing`);
          (campaign_detail?.is_billable == 2 && selectedValue == 0) && toast.success(`Campaign id ${campaignId} is NOT Ready For billing`);
          (campaign_detail?.is_billable == 2 && selectedValue == 1) && toast.success(`Campaign id ${campaignId} is Ready For billing`);
          getcampain()
        })
        .catch(error => toast.error("Some Internal Error occured"));
    } else {
      toast.error('You dont have permissions to make this change');
    }
  }

  const handleEditLineItem = (id, status, statusId) => {
    setEditLineItemModal(true)
    setEditId(id)
    setEditStatus(status)
    setEditStatusId(statusId)
  }

  const handleStatusUpdate = () => {
    setStatusLineItem(false);
    setloadershown(true);    
    dispatch(
      changeCampaignStatus({
        token: localStorage.getItem("token"),
        campaign_id: campaign_detail?.id,
        status_id: 2,
      })
    ).then(() => {
      getcampain();
      dispatch(
        changeLineItemStatus({
          token: localStorage.getItem("token"),
          lineitem_id: lineItemId,
          status_id: 2,
        })
      )
        .then(() => {
          getStatusResolved()
          setloadershown(false);
        })
    });

  }

  const getStatusResolved = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}?page=${pageNo}&pageSize=${pageLimit}`, requestOptions)
      .then(response => response.json())
      .then(result => { setFilteredLineData(result?.data?.results); setPage_count(result?.data?.page_count); setCurrent_Page(result?.data?.current_page); setloadershown(false) })
      .catch(error => toast.error('Internal server error occured'));

  }

  const handle_search_lineitem_name = (e) => {
      setSpinner(true)
      setShow_div(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "name": e.target.value,
        "campaign_id": campaign_detail?.id,
        "id": !isNaN(e.target.value) && e.target.value !== '' ? e.target.value : 0
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManager/filter-lineitem`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLineitem_list(result?.data);
          setSpinner(false)
        })
    }
  const handleTabClick = () => {
    setOptimizeQuery(true);
  };

  const handleEditData = (data, i) => {
    setUpdate_bulk(true);
    editable_data = mapData?.map((item, index) => {
      if (index === i && data === "name") {
        return { ...item, nameTrue: true };
      }
      if (index === i && data === "inversionBudget") {
        return { ...item, budgetTrue: true };
      }
      if (index === i && data === "inversionSellRate") {
        return { ...item, sellRateTrue: true };
      }
      if (index === i && data === "marginSetup") {
        return { ...item, marginTrue: true };
      }
      if (index === i && data === "marginSetup") {
        return { ...item, marginTrue: true };
      }
      if (index === i && data === "startDate") {
        return { ...item, startDateTrue: true };
      }
      if (index === i && data === "endDate") {
        return { ...item, endDateTrue: true };
      }
      if (index === i && data === "countryTrue") {
        return { ...item, countryTrue: true };
      }
      if (index === i && data === "stateTrue") {
        return { ...item, stateTrue: true };
      }
      return item;
    });
    setMapData(editable_data)
  };

  const handleChangeValues = (i, name, e, id) => {
    setUpdate_bulk(true);
    const updatedData = mapData.map((item, index) => {
      if (index === i) {
        if (name === "name") {
          return { ...item, name: e.target.value };
        }
        if (name === "inversionBudget") {
          const spentToDate = reportData
          .filter(itemSpentFilter => itemSpentFilter.lineitemId === item.id)
          .map(itemSpentMap => itemSpentMap.spent);

          if(e.target.value < spentToDate[0]) {
            setValidate_apply_button(true)
            toast.error(`Budget can't be lower than ${spentToDate[0].toLocaleString()}`)
          } else {
            const total_budget = parseInt(campaign_detail?.budge) - parseInt(campaign_detail?.lineItemTotalBudget)
            if (e.target.value > (total_budget + selected_budget)) {
              setValidate_apply_button(true)
              toast.error(`Budget can't be greater then ${total_budget + selected_budget}`)
            }
            else if (!e.target.value == "") {
              setValidate_apply_button(false)
              return { ...item, inversionBudget: e.target.value };
            }
          }
        }
        if (name === "inversionSellRate") {
          if (!(e.target.value==="" || e.target.value===" ")) {
            return { ...item, inversionSellRate: e.target.value };
          }
        }
        if (name === "marginSetup") {
          return { ...item, marginSetup: e.target.value };
        }
        if (name === "inventoryTypeName") {
          return { ...item, inventoryTypeName: e, inventoryType: id };
        }
        if (name === "formatName") {
          return { ...item, formatName: e, format: id };
        }
        if (name === "offerTypeName") {
          return { ...item, offerTypeName: e, offerType: id };
        }
        if (name === "pacingName") {
          return { ...item, pacingName: e, pacing: id };
        }
        if (name === "pacingRateName") {
          return { ...item, pacingRateName: e, pacingRate: id };
        }
        if (name === "lineItemStatusName") {
          return { ...item, lineItemStatusName: e, lineItemStatus: id };
        }
      }
      return item;
    });
    setMapData([...updatedData]);
  };

  const handleChangeValuesDates = (i, name, e, id) => {
    setUpdate_bulk(true);

    // Create a copy of the mapData array
    const updatedData = [...mapData];

    // Find the item in the array based on the index
    const itemToUpdate = updatedData[i];

    // Update the startDate or endDate property based on the name
    if (name === "startDate") {
      itemToUpdate.startDate = e;
    } else if (name === "endDate") {
      itemToUpdate.endDate = e;
    }
    setMapData(updatedData);
  };
  
  // multiselect show outside code
  const parentRef = useRef(null);
  const demoData = () => {
    let elements = document.getElementsByClassName('test_class');
    let elementArray = Array.from(elements); // Convert HTMLCollection to array
    if (elementArray.length > 0) {
      elementArray.forEach((element) => {
        let rect = element.getBoundingClientRect();
        let siblingElement = element?.lastElementChild;
        siblingElement.style.left = rect.left + 'px';
      });
    }
  };
  // 
  const handleClickOutside = (event) => {
    let elements = document.getElementsByClassName('test_class');
    let elementArray = Array.from(elements); // Convert HTMLCollection to array
    let isOutside = true;
    elementArray.forEach((element) => {
      if (element.contains(event.target)) {
        isOutside = false;
      }
      if (isOutside) {
        let siblingElement = element?.lastElementChild;
        siblingElement.style.removeProperty('left');
      }
    });
  };


  useLayoutEffect(() => {
    demoData();
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handle_validate_budhet = (i) => {
    let budge = 0
    optimize_data?.data?.data?.map((data, index) => {
      if (i == index) {
        budge = data?.inversionBudget
      }
    })
    setSelected_budget(budge)
  }

  const handleUpdateOptimizeTab = () => {
    let validate_budget = 0
    let errorFound = false

    mapData?.forEach((val, i) => {
      if (val?.inversionBudget <= 0) {
        toast.error(`Budget must be higher than 0`)
        errorFound = true
      } else {
        validate_budget += parseInt(val?.inversionBudget)
      }
    })


    if (!errorFound) {
      const total_budget = parseInt(campaign_detail?.budge) - parseInt(campaign_detail?.lineItemTotalBudget)
      if (total_budget == 0) {
        dispatch(update_optimize_tab_general({ updated_items: mapData }))
      } else {
        dispatch(update_optimize_tab_general({ updated_items: mapData }))
      }
    }
  }

  let current = new Date();
  let cDate = current.getDate() + '/' + (current.getMonth() + 1) + '/' + current.getFullYear();

  let hours = current.getHours();
  let minutes = current.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12' in PM

  let cTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;

  let dateTime = cDate + ' ' + cTime;

  const export_pdf = () => {
    setloadershown(true);
  
    html2canvas(document.getElementsByClassName('content')[0], {
      scale: 2, // Adjust scale as needed
      scrollX: 0,
      scrollY: 0,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const ratio = Math.min(pdfWidth / canvas.width, pdfHeight / canvas.height);
      const imgWidth = canvas.width * ratio;
      const imgHeight = canvas.height * ratio;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save("Lineitem_General.pdf");
      setPdfTrue(false);
      setHide_filter(true)
      setloadershown(false);
      setPageLimit(10)
    }).catch((error) => {
      console.error("Error generating PDF:", error);
      setloadershown(false);
    });
  };

  useEffect(() => {
    if(pdfTrue){
      export_pdf()
    }
  }, [pdfTrue])

  return (
    <div className="content_outer position-unset">
      <Loader showLoader={loadershown || optimize_data?.loading || update_optimize_tab?.loading} />
      <div className="cmn_header_top">
        <h3>

          <img src="/assets/images/chevron_left.svg" onClick={() => navigation("/campaign/mediaplan", {
            state: {
              dealid,
              deal_id,
              campaignId,
              advertiser,
              clientId,
              total_budget,
              sum_budget,
              startdate,
              enddate,
              currencyCode: currency_code,
              remainingBudget,
              campaignStatus
            }
          })} />
          Campaign Manager</h3>
        <div className="cmn_header__search position-relative">
          <AiOutlineSearch size={24} className={searchValue.length > 0 ? "changeIcon" : ""} />
            <input
              type="text"
              placeholder="Search by Id or Line Item"
              value={lineitem_id ? lineitem_id : lineitem_name ? lineitem_name : searchValue}          
              onChange={(e) => {
                handle_search_lineitem_name(e);
                setSearchValue(e.target.value);
                setSearch(true);
              }}
              onClick={() => {
                setLineitem_list([])
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if(isNaN(searchValue)){
                    getSearchedData(searchValue, null);
                    get_filtered_optimize_data(searchValue, null);
                  } else {
                    getSearchedData(null, searchValue);
                    get_filtered_optimize_data(null, searchValue);
                  }
                  setSearchQuery(true);
                  setSearchValue(searchValue);
                  setShow_div(false);
                }
              }}
            />
            {show_div &&
              <div className="search_dropown">
                {spinner &&
                  <div className="p-3 text-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                }
                <ul>
                  {
                    lineitem_list?.map((data, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            getSearchedData(data?.name, null);
                            get_filtered_optimize_data(data?.name, null);
                            setSearchValue(data?.name);
                            setShow_div(false);
                            setSearchQuery(true);
                          }}>
                            {`${data?.name} (${data.id})`}
                        </li>
                      )
                    })
                  }
                </ul>
            </div>}
        </div>
        {(Object.values(permission.Permissions).includes('readyToBill') || Object.values(permission.Permissions).includes('editBill')) &&
          <div className="billableDivDropdown">
            {showBillableMenu ?
              <Dropdown>
                <Dropdown.Toggle className="billableDropdown">
                  {campaign_detail?.is_billable == 0 && 'Not to bill'}
                  {campaign_detail?.is_billable == 1 && 'Ready to bill'}
                  {campaign_detail?.is_billable == 2 && 'Billed'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {billableOptions.map((option) =>
                    (
                      (campaign_detail?.is_billable !== option.id &&
                        ((Object.values(permission.Permissions).includes('editBill') && option.id !== 0) ||
                          (!(Object.values(permission.Permissions).includes('editBill')) && option.id !== 2)))
                    ) && (
                      <Dropdown.Item
                        key={option.id}
                        onClick={() => {
                          !(campaign_detail?.is_billable == 0 && option.id == 2) ?
                            handleBillable(option.id) :
                            toast.error('It is not possible to make this change')
                        }}
                      >
                        {option.description}
                      </Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>

              </Dropdown>
              :
              <Dropdown className="billableDropdownBilled">Billed</Dropdown>
            }
          </div>
        }
        <div className="bill_btn" title="Print Pdf" onClick={() => {setHide_filter(false);setPageLimit(total_records);setPageNo(1) }}>
          <button className="cmn_btn cmn_bordered_btn">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="24" width="24" className="me-2" xmlns="http://www.w3.org/2000/svg"><path d="M96 96V320c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zm64 160c35.3 0 64 28.7 64 64H160V256zM224 96c0 35.3-28.7 64-64 64V96h64zM576 256v64H512c0-35.3 28.7-64 64-64zM512 96h64v64c-35.3 0-64-28.7-64-64zM288 208a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V360c0 66.3 53.7 120 120 120H520c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-39.8 0-72-32.2-72-72V120z"></path></svg>
            Print Pdf
          </button>
        </div>
        {Object.values(permission.Permissions).includes('editcampaign') && <div className="">
          {campaign_detail?.lineItemTotalBudget <
            campaign_detail?.budge ? (
            <button
              className="cmn_btn ms-0"
              onClick={() => {
                setModalShow(true);
                reset_filter();
                setEditId()
              }}
            >
              Add New Line Item{" "}
              <FontAwesomeIcon className="ms-2" icon={faPlus} />
            </button>
          ) : (
            <button
              className="cmn_btn ms-0"
              onClick={() => {
                setModal_open(true);
              }}
            >
              New Line Item{" "}
              <FontAwesomeIcon className="ms-2" icon={faPlus} />
            </button>
          )}
        </div>}

      </div>
      <div className="content">
        <div className="App">
          <div className="mediaplan_details kite_space_cmn">
            {pdfTrue ? <div className=" p-3 pdf_head mb-5">
              <div className="d-flex justify-content-between align-items-center mb-5">
              <img src="/assets/images/kidtech.svg" width="150" />
                <img src="/assets/images/mariano.png" width="150" />
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <h2 style={{ fontSize: "32px", fontWeight: "bold" }} className="mb-0 header_title">{advertiser}</h2>
                <h2 style={{ fontSize: "32px" }} className="mb-0 header_title">{dateTime}</h2>
              </div>
            </div> : null}
            <div className="title__active">
              <h3>{deal_detail?.campaign}</h3>
              <div className="status_badge">
                {campaign_detail?.statusNavigation?.description == "PAUSE " && <p><AiOutlineCheckCircle size={24} className="me-2" />Pause</p>}
                {campaign_detail?.statusNavigation?.description == "ACTIVE" && <p><AiOutlineCheckCircle size={24} className="me-2" />Active</p>}
                {campaign_detail?.statusNavigation?.description == "DRAFT" && <p><AiOutlineCheckCircle size={24} className="me-2" />DRAFT</p>}
                {campaign_detail?.statusNavigation?.description == "INACTIVE" && <p><AiOutlineCheckCircle size={24} className="me-2" />INACTIVE</p>}
              </div>
            </div>
            <Row>
              <Col lg={filteredLineData?.length === 0 ? 8 : 8}>
                <div className="advert_tabel">
                  <div className="media_plan_info_list">

                    <ul className="header__media">
                      <li>
                        <MdOutlineHandshake size={24} />
                        <strong>Campaign Id</strong><p> {campaign_detail?.id}</p>
                      </li>
                      <li>
                        <BiCalendar size={20} />
                        <strong>Start Date:</strong>
                        <p>
                          {""}
                          <Moment format="DD/MM/YYYY">
                            {campaign_detail?.date_from}
                          </Moment>
                        </p>
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li>
                        <strong>End Date:</strong>
                        <p>
                          {""}
                          <Moment format="DD/MM/YYYY">
                            {campaign_detail?.date_to}
                          </Moment>
                        </p>
                      </li>
                      <li className="block_divider"><span></span></li>
                      <li><strong>Flight days</strong><p>{<p>{moment(campaign_detail?.date_to).diff(
                        moment(campaign_detail?.date_from),
                        "days"
                      )}</p>}</p></li>
                      <li className="block_divider"><span></span></li>
                      <li><strong>Remaining days</strong><p>{Date.now() > moment(campaign_detail?.date_from) ? moment(campaign_detail?.date_to).diff(
                        moment(),
                        "days"
                      ) : moment(campaign_detail?.date_to).diff(
                        moment(campaign_detail?.date_from),
                        "days"
                      )}</p></li>
                    </ul>
                    <ul>
                      <li>
                        <strong>Advertiser</strong><p> {advertiser}</p>
                      </li>

                      <li>
                        <strong>Product</strong>
                        <p> {campaign_detail?.productNavigation?.name}</p>
                      </li>
                      <li>
                        <strong>Currency</strong><p> {currency_code}</p>
                      </li>

                      <li>
                        <strong>Status</strong>
                        <p> {campaign_detail?.statusNavigation?.description}</p>
                      </li>
                      <li>
                        {Object.values(permission.Permissions).includes('editcampaign') && <div className="pdf_icon " title="Edit Campaign" onClick={editCampaign}><img src="/assets/images/edit_fill.svg" /></div>}
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4} >

                <div>

                  <ul className="budget single_budget">
                    <li>
                      Budget
                    </li>
                    <li className="progress_result">
                      <div className="w-100">
                        <ProgressBar className="bar_color w-100" now={percentage} />
                      </div>
                      <p>Total: <strong> {campaign_detail?.budge?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}</strong></p>
                    </li>
                    <li className="total_used">
                      <div className="totalbuget">
                        <div className="dot_circle">
                        </div>
                        <p>Used : <strong>{campaign_detail?.lineItemTotalBudget ? campaign_detail?.lineItemTotalBudget?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        }) : "0"}</strong></p>
                      </div>
                      <div className="totalbuget">
                        <div className="dot_circle">
                        </div>
                        <p>Remaining : <strong>{(campaign_detail?.budge -
                          campaign_detail?.lineItemTotalBudget)?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}</strong></p>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="new_compaign tabs_wrappers">
          {/* tabs starts here */}
          <div className="mediaplan__tabs single_tab">
            <div className=" border-bottom-0 mb-0">
              <Tabs
                defaultActiveKey="setup"
                id="uncontrolled-tab-example"
                className="tabs_outer_wrapper single_tab"
                onSelect={handleTabClick}
              >
                <Tab eventKey="setup" title="Setup">
                  <div className="campiagn_outer border-0 p-0">
                   { hide_filter && <div className="cmn__filters campiagn_filter filter_menu without_filter_head line_items_filter">
                      <ul>
                        <li><img src="/assets/images/filter_list.svg" /></li>
                        <li>
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {lineitem_inventory ? lineitem_inventory : tempInventory} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {inventoryId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setInventoryTypeId(option?.id); setSearch(true); setTempInventory(option?.description) }}>{option?.description}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <li>

                          <Form.Group className=" owner_filter mt-0 round_select" controlId="dateselect">
                            <DateRangePicker
                              initialSettings={{
                                autoUpdateInput: false,
                                showDropdowns: true,
                              }}
                              onApply={handleApply}
                              onCancel={handleCancel}
                              ref={pickerref}
                            >
                              <Form.Control
                                type="text"
                                placeholder="yyyy/mm/dd"
                                className="form-control"
                                // value={startend.start + " " + startend.end}
                                value={startend.start && startend.end ? startend.start + " " + startend.end : "Start Date"}
                              />
                            </DateRangePicker>
                          </Form.Group>

                        </li>
                        <li>
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {lineitem_format ? lineitem_format : tempFormat}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {formatId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setFormatTypeId(option?.id); setSearch(true); setTempFormat(option?.name) }}>{option?.name}</Dropdown.Item>
                                  </>
                                )

                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>

                        <li className="smallerLi">
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {lineitem_offer ? lineitem_offer : tempOffer}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {offerId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setOfferTypeId(option?.id); setSearch(true); setTempOffer(option?.descripcion) }}>{option?.descripcion}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>

                        <li>
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {lineitem_country ? lineitem_country : tempCountry}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {countryId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setCountryTypeId(option?.id); setSearch(true); setTempCountry(option?.labelKey) }}>{option?.labelKey}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>


                        <li className="smallerLi">
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(lineitem_status ? lineitem_status : tempStatus))}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {statusId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setStatusTypeId(option?.id); setSearch(true); setTempStatus(option.description) }}>{upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(option.description))}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <button className="cmn_search_btn"
                          onClick={() => (inventoryTypeId || offerTypeId || statusTypeId || countryTypeId || formatTypeId || campaignSearch) ? getSearchedData(null, null) : toast.error("Search field can't be empty")}
                        >
                          Search
                        </button>
                        <button className={!searchSet ? "clear__btn" : "clear__btn1"} onClick={() => {
                          getPaginateData()
                          setStatusTypeId();
                          setCountryTypeId();
                          setOfferTypeId();
                          setFormatTypeId();
                          setInventoryTypeId();
                          setSearchValue("");
                          setCampaignSearch("")
                          setSearchCampaignId("")
                          setSearchValue("")
                          setSearch(false)
                          setTempInventory("Inventory")
                          setTempCountry("Country")
                          setTempFormat("Format")
                          setTempOffer("offer")
                          setTempStatus("Status")
                          setStartend({
                            start: "",
                            end: "",
                          });
                          pickerref.current.value = "";


                        }}>Clear</button>



                      </ul>
                    </div>}

                    <div className="advert_tabel campign_index_table">
                      <ContextMenuTrigger id="my-context-menu-1" >
                        <Table
                          responsive
                          className={
                            filtered_items?.results?.length === 0
                              ? "no_data"
                              : null
                          }
                        >
                          <thead>
                            <tr>
                              <th
                                onClick={() => handleSort("id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Line Item ID
                              </th>
                              <th
                                onClick={() => handleSort("name")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                LI Name{" "}
                              </th>
                              <th
                                onClick={() => handleSort("inventory_type_id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Inventory Type{" "}
                              </th>
                              <th
                                onClick={() => handleSort("format_id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Format{" "}
                              </th>
                              <th
                                onClick={() =>
                                  handleSort("inversion_offerType_id")
                                }
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Offer Type
                              </th>
                              <th
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Country{" "}
                              </th>
                              <th
                                onClick={() => handleSort("budget")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Budget in{" "} {currency_code}
                              </th>
                              <th
                                onClick={() => handleSort("sell")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Sell Rate in{" "}{currency_code}
                              </th>
                              <th
                                onClick={() => handleSort("inversion_goal")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Goal{" "}
                              </th>
                              <th
                                onClick={() => handleSort("date_from")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Start date
                              </th>
                              <th
                                onClick={() => handleSort("date_to")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                End date
                              </th>
                              <th
                                onClick={() => handleSort("status_id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Status{" "}
                              </th>
                              <th
                                onClick={() => handleSort("status_id")}
                                onContextMenu={() => {
                                  setMenu_item(false);
                                }}
                                className="text-start"
                              >
                                Delivery Status{" "}
                              </th>
                              <th style={{ opacity: "0" }}>pdf</th>
                            </tr>
                          </thead>
                          <tbody className={filteredLineData?.length === 0 ? "position-relative" : ""}>
                            {filteredLineData?.length === 0 && (
                              <div className="No_Data">
                                {" "}
                                <img
                                  className="nodata"
                                  src="/assets/images/notfound.svg"
                                />
                              </div>
                            )}
                            {(
                              filteredLineData?.map((curelem, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.id}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.name}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                    >
                                      {
                                        curelem?.inventoryNavigation
                                          ?.description
                                      }
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.formatNavigation?.name}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {
                                        curelem?.offerTypeNavigation
                                          ?.descripcion
                                      }
                                    </td>
                                    {
                                      <td
                                        onContextMenu={() => {
                                          setId(curelem?.id);
                                          setMenu_item(true);
                                        }}
                                        className="text-start"
                                        onClick={() => {
                                          gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                            ?.description);
                                        }}
                                      >
                                        {curelem?.countryList?.flatMap(
                                          (data, index) => {
                                            return `${data?.country?.ab?.toUpperCase()},`;
                                          }
                                        )}
                                      </td>
                                    }
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.inversion_budget?.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 2 }
                                      )}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.offerTypeNavigation?.id === 6 ||
                                        curelem?.offerTypeNavigation?.id === 7
                                        ? curelem?.inversion_sell_rate?.toLocaleString(
                                          undefined,
                                          { minimumFractionDigits: 4 }
                                        )
                                        : curelem?.inversion_sell_rate?.toLocaleString(
                                          undefined,
                                          { minimumFractionDigits: 2 }
                                        )}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {parseInt(
                                        curelem?.inversion_goal
                                      )?.toLocaleString()}
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      <Moment format="DD/MM/YYYY">
                                        {curelem?.date_from}
                                      </Moment>
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-start"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      <Moment format="DD/MM/YYYY">
                                        {curelem?.date_to}
                                      </Moment>
                                    </td>

                                    <td>
                                      <Dropdown className="status_dropdown media_plan_dropdown status_design">
                                        <Dropdown.Toggle className={`filter_btn ${curelem?.lineItemStatusNavigation?.description.toLowerCase()}`}>

                                          {curelem?.lineItemStatusNavigation
                                            ?.description == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                          {curelem?.lineItemStatusNavigation
                                            ?.description == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                          {curelem?.lineItemStatusNavigation
                                            ?.description == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                          {curelem?.lineItemStatusNavigation
                                            ?.description == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}
                                          <span>  {
                                            upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(curelem?.lineItemStatusNavigation
                                              ?.description))
                                          }</span>
                                          <FaChevronRight className="ms-auto" />
                                        </Dropdown.Toggle>
                                        {Object.values(permission.Permissions).includes('editcampaign') && (
                                          <Dropdown.Menu>
                                            {line_item_status?.map(
                                              (status, index) => {
                                                return (
                                                  <Dropdown.Item
                                                    className={status?.description.toLowerCase()}
                                                    key={`status_${index}`}
                                                    onClick={() => {
                                                      statusPopup(
                                                        curelem?.id,
                                                        curelem?.lineItemStatusNavigation?.description,
                                                        status?.description,
                                                        status?.id,
                                                      );
                                                      setLineItemId(curelem?.id)
                                                    }}
                                                  >
                                                    {status?.description == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                                    {status?.description == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                                    {status?.description == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                                    {status?.description == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}
                                                    {upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(status?.description))}
                                                  </Dropdown.Item>
                                                );
                                              }
                                            )}
                                          </Dropdown.Menu>
                                        )}
                                      </Dropdown>
                                    </td>
                                    <td
                                      onContextMenu={() => {
                                        setId(curelem?.id);
                                        setMenu_item(true);
                                      }}
                                      className="text-center"
                                      onClick={() => {
                                        gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                          ?.description);
                                      }}
                                    >
                                      {curelem?.platform_delivering_status ? <img title="This lineitem has at least 1 impression delivered" src="/assets/images/check_circle.svg" /> : <img title="This lineitem do not have any impressions delivered" src="/assets/images/cancel_FILL.svg" />}
                                    </td>

                                    <td className="text-start" >
                                      <div className="icons_downloads">
                                        <div className="pdf_icon" title="View Placement List"
                                          onContextMenu={() => {
                                            setId(curelem?.id);
                                            setMenu_item(true);
                                          }}
                                          onClick={() =>
                                            navigation(
                                              `/campaignlist/${curelem?.id}`
                                            )
                                          }>
                                          <img src="/assets/images/play_circle.svg" />
                                        </div>
                                        {Object.values(permission.Permissions).includes('editcampaign') && <div className="pdf_icon" title="Edit Line Item"
                                          onContextMenu={() => {
                                            setId(curelem?.id);
                                            setMenu_item(true);
                                          }}
                                          onClick={() => {
                                            handleEditLineItem(curelem?.id, curelem?.lineItemStatusNavigation?.description, curelem?.lineItemStatusNavigation?.id)
                                          }}>
                                          <img src="/assets/images/edit_fill.svg" />
                                        </div>}
                                        <div className="pdf_icon" title="View Creatives" onClick={() => {
                                          gotonext(curelem?.id, campaignId, curelem?.lineItemStatusNavigation
                                            ?.description);
                                        }}>
                                          <img src="/assets/images/description_file.svg" />
                                        </div>
                                        <div className="pdf_icon" title="Change history" style={{ cursor: "pointer" }}>
                                          <img src="/assets/images/clock.svg" onClick={() => navigation("/lineItemshistory", { state: { id: curelem?.id, pathname: location?.pathname, campaignId: campaignId, advertiser: advertiser, currency_code: currency_code, dealid: dealid, deal_id: deal_id,currency_code} })} />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </Table>
                      </ContextMenuTrigger>
                      {(filteredLineData?.length !== 0) &&(hide_filter) && <div className="cmn__pagination">
                        <ul>
                          <span>Rows per page</span>
                          <li className="no__rows">
                            <select
                              name=""
                              id=""
                              onChange={(e) => { setPageLimit(e.target.value); setFilterTrue(true) }}

                            >
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="20">20</option>
                            </select>
                          </li>
                          <li className="total_pages me-3">
                            <span className="active_pages">{current_Page}</span> of <span>{page_Count}</span>
                          </li>
                          {pageNo != 1 && <li>
                            <BsChevronBarLeft onClick={() => { setPageNo(1); setFilterTrue(true) }} />
                          </li>}
                          {pageNo != 1 && <li>
                            <BsChevronLeft onClick={() => { setPageNo(pageNo - 1); setFilterTrue(true) }} />
                          </li>}
                          {pageNo != page_Count && <li>
                            <BsChevronRight onClick={() => { setPageNo(pageNo + 1); setFilterTrue(true) }} />
                          </li>}
                          {pageNo != page_Count && <li>
                            <BsChevronBarRight onClick={() => { setPageNo(page_Count); setFilterTrue(true) }} />
                          </li>}
                        </ul>
                      </div>}
                    </div>
                  </div>
                </Tab>
                {Object.values(permission.Permissions).includes('viewOptimizeTab') &&
                <Tab eventKey="profile" title="Optimize" className="position-unset">
                  <div className="campiagn_outer border-0 p-0">
                    <div className="cmn__filters campiagn_filter filter_menu without_filter_head line_items_filter">
                      <ul className="mb-2">
                        <li><img src="/assets/images/filter_list.svg" /></li>
                        <li>
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {tempInventory} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {inventoryId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setInventoryTypeId(option?.id); setSearch(true); setTempInventory(option?.description) }}>{option?.description}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <li>
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {tempFormat}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {formatId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setFormatTypeId(option?.id); setSearch(true); setTempFormat(option?.name) }}>{option?.name}</Dropdown.Item>
                                  </>
                                )
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>

                        <li className="smallerLi">
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {tempOffer}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {offerId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setOfferTypeId(option?.id); setSearch(true); setTempOffer(option?.descripcion) }}>{option?.descripcion}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>

                        <li className="smallerLi">
                          <Dropdown className="owner_filter">
                            <Dropdown.Toggle id="dropdown-basic">
                              {upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(tempStatus))}  <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' /></svg>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {statusId?.map((option, index) => {
                                return (
                                  <>
                                    <Dropdown.Item key={index} onClick={() => { setStatusTypeId(option?.id); setSearch(true); setTempStatus(option.description) }}>{upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(option.description))}</Dropdown.Item>
                                  </>
                                )
                              })}

                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <button className="cmn_search_btn"
                          onClick={() => (inventoryTypeId || offerTypeId || statusTypeId || formatTypeId) ? get_filtered_optimize_data(null, null) : toast.error("Search field can't be empty")}
                        >
                          Search
                        </button>
                        <button className={!searchSet ? "clear__btn" : "clear__btn1"} onClick={() => {
                          dispatch(optimize_data_general({ id: campaign_detail?.id }))
                          // dispatch(clear_optimize_tab_general_state())
                          setStatusTypeId();
                          setCountryTypeId();
                          setOfferTypeId();
                          setFormatTypeId();
                          setInventoryTypeId();
                          setSearchValue("");
                          setCampaignSearch("")
                          setSearchCampaignId("")
                          setSearchValue("")
                          setSearch(false)
                          setTempInventory("Inventory")
                          setTempCountry("Country")
                          setTempFormat("Format")
                          setTempOffer("offer")
                          setTempStatus("Status")
                          setStartend({
                            start: "",
                            end: "",
                          });
                          pickerref.current.value = "";
                        }}>Clear</button>
                      </ul>
                    </div>

                    <div className="advert_tabel campaign_showlist campign_index_table optimize_table">
                      <div className="applyCenter marginApply mb-3">
                        {update_bulk &&
                          <div className="divApplyChanges">
                            <button
                              className="aplly_btn"
                              disabled={validate_apply_button}
                              onClick={() => (!validate_apply_button && handleUpdateOptimizeTab())}>
                                Apply changes
                              </button>
                              <button
                                className="clear__btn1 cancelChanges"
                                onClick={() => {
                                  setUpdate_bulk(false);
                                  get_filtered_optimize_data();
                                }}
                              >
                                Cancel changes
                              </button>
                            </div>
                        }
                      </div>
                      <Table
                        responsive
                        className="optimize_tab_table"
                      >
                        <thead>
                          <tr>
                            <th>
                              Name(ID)
                            </th>
                            <th>LI Details</th>
                            <th>Status</th>
                            <th>Pacing Rate</th>
                            <th>Budget</th>
                            <th>Sell rate</th>
                            <th>Margin setup</th>
                            <th>Spent to date</th>
                            <th>Goal</th>
                            <th>Delivered to date</th>
                            <th>Pacing to date</th>
                            <th>Delivery Completion</th>
                            <th style={{ cursor: "pointer" }}>Daily delivery
                              <svg
                                className="ms-2"
                                stroke="currentColor"
                                fill="none"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                                height="20"
                                width="20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                <title>You can see each day's delivery by hovering over the bar for that day</title>
                              </svg>
                            </th>
                            <th>Remaining days</th>
                            {Object.values(permission.Permissions).includes('editOptimizeTab') && <th>General Edit</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {mapData?.map((data, index) => {

                            return (
                              <tr key={index}>
                                <td style={{ width: "252px" }} title={data?.id + " " + data?.name}>{data?.name}({data?.id})</td>
                                <td>
                                  {data?.inventoryTypeName} <br />  {data?.formatName}<br /> {data?.offerTypeName}
                                </td>
                                <td>
                                  <div className="filter_menu ">
                                    <Dropdown className="advert">
                                      <Dropdown.Toggle id="dropdown-autoclose-true">
                                        <span className="active__option">
                                          {data?.lineItemStatus == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                          {data?.lineItemStatus == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                          {data?.lineItemStatus == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                          {data?.lineItemStatus == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}
                                          {upperCaseFirstLetter(lowerCaseAllWordsExceptFirstLetters(data?.lineItemStatusName))}
                                        </span>
                                        {Object.values(permission.Permissions).includes('editOptimizeTab') && <FaChevronRight />}
                                      </Dropdown.Toggle>
                                      {Object.values(permission.Permissions).includes('editOptimizeTab') && (
                                      <Dropdown.Menu className="child_filters">
                                        <Dropdown.Item
                                          onClick={() => {
                                            data?.lineItemStatus == 2 || data?.lineItemStatus == 3 ?
                                              toast.error("Can't change to status Draft")
                                              : handleChangeValues(index, "lineItemStatusName", "Draft", 1)
                                          }}
                                        > <img className="me-2" src="/assets/images/draft_FILL.svg" />Draft</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleChangeValues(index, "lineItemStatusName", "Active", 2)}> <img className="me-2" src="/assets/images/check_circle.svg" />Active</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleChangeValues(index, "lineItemStatusName", "Pause", 3)}><img className="me-2" src="/assets/images/pause_circle.svg" />Pause</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleChangeValues(index, "lineItemStatusName", "Inactive", 28)}> <img className="me-2" src="/assets/images/cancel_FILL.svg" />Inactive</Dropdown.Item>
                                      </Dropdown.Menu>
                                      )}
                                    </Dropdown>
                                  </div>
                                </td>
                                <td>
                                  <div className="filter_menu ">
                                    <Dropdown className="advert">
                                      <Dropdown.Toggle id="dropdown-autoclose-true">
                                        <span>
                                          {data?.pacingRateName}
                                        </span>
                                        {Object.values(permission.Permissions).includes('editOptimizeTab') && <FaChevronRight />}
                                      </Dropdown.Toggle>
                                      {Object.values(permission.Permissions).includes('editOptimizeTab') && (
                                      <Dropdown.Menu className="child_filters">
                                        {pacing_rate?.data?.data?.map((val, i) => {
                                          return (
                                            <Dropdown.Item onClick={() => handleChangeValues(index, "pacingRateName", val?.description, val?.id)}>{val?.description}</Dropdown.Item>
                                          )
                                        })}
                                      </Dropdown.Menu>
                                      )}
                                    </Dropdown>
                                  </div>
                                </td>
                                {Object.values(permission.Permissions).includes('editOptimizeTab') ? (
                                  <td
                                    onClick={() => {
                                      handleEditData("inversionBudget", index);
                                      handle_validate_budhet(index)
                                    }}>
                                      {data?.budgetTrue ?
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={data?.inversionBudget}
                                          onBlur={(e) => { handleChangeValues(index, "inversionBudget", e) }}
                                        /> :
                                        <div className="edit_wrapper d-flex gap-2 align-items-center">
                                          <div className="pdf_icon" title="Edit Line Item" >
                                            <img src="/assets/images/edit_fill.svg"/>
                                          </div>
                                          {!data?.budgetTrue && "$"}
                                          {data?.inversionBudget?.toLocaleString()}
                                        </div>
                                      }
                                  </td>
                                  ) : (
                                    <td>
                                      <div className="edit_wrapper d-flex gap-2 align-items-center">
                                        {!data?.budgetTrue && "$"}
                                        {data?.inversionBudget?.toLocaleString()}
                                      </div>
                                    </td>
                                  )
                                }
                                {Object.values(permission.Permissions).includes('editOptimizeTab') ? (
                                  <td onClick={() => { handleEditData("inversionSellRate", index) }}>
                                    {data?.sellRateTrue ?
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder={data?.inversionSellRate}
                                        onBlur={(e) => { handleChangeValues(index, "inversionSellRate", e) }}
                                      /> :
                                      <div className="edit_wrapper d-flex gap-2 align-items-center">
                                        <div className="pdf_icon" title="Edit Line Item">
                                          <img src="/assets/images/edit_fill.svg" />
                                        </div>
                                        {!data?.sellRateTrue && "$"}{data?.inversionSellRate?.toLocaleString()}
                                      </div>
                                    }
                                  </td>
                                  ) : (
                                    <td>
                                      <div className="edit_wrapper d-flex gap-2 align-items-center">
                                        {!data?.sellRateTrue && "$"}{data?.inversionSellRate?.toLocaleString()}
                                      </div>
                                    </td>
                                  )
                                }
                                {Object.values(permission.Permissions).includes('editOptimizeTab') ? (
                                  <td
                                    onClick={() => { handleEditData("marginSetup", index) }}
                                  >
                                    {data?.marginTrue ?
                                      <select
                                        onChange={(e) => handleChangeValues(index, "marginSetup", e)}
                                        value={data?.marginSetup}
                                      >
                                        <option value="5">5%</option>
                                        <option value="10">10%</option>
                                        <option value="15">15%</option>
                                        <option value="20">20%</option>
                                        <option value="25">25%</option>
                                        <option value="30">30%</option>
                                        <option value="35">35%</option>
                                        <option value="40">40%</option>
                                        <option value="45">45%</option>
                                        <option value="50">50%</option>
                                        <option value="55">55%</option>
                                        <option value="60">60%</option>
                                        <option value="65">65%</option>
                                        <option value="70">70%</option>
                                        <option value="75">75%</option>
                                        <option value="80">80%</option>
                                        <option value="85">85%</option>
                                        <option value="90">90%</option>
                                      </select> :
                                      <div className="edit_wrapper d-flex gap-2 align-items-center">
                                        <div className="pdf_icon" title="Edit Line Item" ><img src="/assets/images/edit_fill.svg" /></div>
                                        {data?.marginSetup}
                                      </div>
                                    }
                                  </td>
                                  ) : (
                                    <td>
                                      <div className="edit_wrapper d-flex gap-2 align-items-center">
                                        {data?.marginSetup}
                                      </div>                                    
                                    </td>
                                  )
                                }
                                <td>{optimize_report_details?.loading ? (
                                  <li>Loading..</li>
                                ) : optimize_report_details?.isSuccess && (
                                  reportData?.map((val, i) => {
                                    if (val?.lineitemId === data?.id) {
                                      return (
                                        <span key={i}>{val?.success ? "$" + val?.spent?.toLocaleString(undefined,{ maximumFractionDigits: 2 }) : "Has not delivered"}</span>
                                      )
                                    }
                                  })

                                )}</td>
                                <td>{data?.inversionGoal?.toLocaleString()}</td>
                                <td>{optimize_report_details?.loading ? <li>Loading..</li> : optimize_report_details?.isSuccess && (reportData?.map((val, i) => {
                                  if (val?.lineitemId === data?.id) {
                                    return (
                                      <span key={i}>{val?.success ? val?.impressions?.toLocaleString() : "Has not delivered"}</span>
                                    )
                                  }
                                }))}</td>
                                <td>
                                  {optimize_report_details?.loading ?
                                    <li>
                                      Loading..
                                    </li> :
                                    optimize_report_details?.isSuccess &&
                                      (reportData?.map((val, i) => {
                                        if (val?.lineitemId === data?.id) {
                                          return (
                                            <span>
                                              {
                                                val?.success ?
                                                  (Math.abs((val?.spent / ((data?.inversionBudgetOriginal / (moment(campaign_detail?.date_to).diff(moment(campaign_detail?.date_from), "days") + 1)) * (moment(campaign_detail?.date_from).diff(moment(), "days"))))) * 100)?.toFixed(2) + "%"
                                                  : "Has not delivered"
                                              }

                                            </span>
                                          )
                                        }
                                      }))
                                  }
                                </td>
                                <td>{optimize_report_details?.loading ? <li>Loading..</li> : optimize_report_details?.isSuccess && (reportData?.map((val, i) => {
                                  if (val?.lineitemId === data?.id) {
                                    return (
                                      <span key={i}>
                                        {val?.success ? `${((val?.impressions / data?.inversionGoal) * 100)?.toFixed(2)}%` : "Has not delivered"}
                                      </span>
                                    )
                                  }
                                }))}</td>
                                <td>
                                  <ul className="days_weeks p-0">
                                    {optimize_report_details?.loading ? (
                                      <li>Loading..</li>
                                    ) : optimize_report_details?.isSuccess &&
                                    reportData?.map((val, i) => {
                                      if (val?.lineitemId === data?.id) {
                                        if (val?.success) {
                                          const dates = val?.dates || [];
                                          const lastDate = dates.length > 0 ? new Date(dates[dates.length - 1].date) : new Date();

                                          const nextDates = dates.length < 7 ? Array.from({ length: 7 - dates.length }).map((_, index) => {
                                            const date = new Date(lastDate);
                                            date.setDate(date.getDate() + index + 1);
                                            return date.toISOString().split('T')[0];
                                          }) : [];

                                          const allDates = [...dates.map(date => date.date), ...nextDates];
                                          const offerType = val?.offerType;
                                          return allDates.map((singleDay, index) => {
                                            const spent = val?.dates[index]?.spent ?? 0;
                                            const impressions = val?.dates[index]?.impressions ?? 0;
                                            return (
                                              <li key={index}>
                                                <span className="block_bg">
                                                  <span
                                                    title={offerType=="CPM" ?
                                                      ('Impressions ' + impressions) :
                                                      ('Views ' + impressions)
                                                     }
                                                    style={{ height: `${spent * 10}px` }} // Adjusted height calculation
                                                  ></span>
                                                </span>
                                                <span>
                                                  <Moment format="D">{singleDay}</Moment>
                                                </span>
                                              </li>
                                            );
                                          });
                                        } else {
                                          return (
                                            <span>Has not delivered</span>
                                          );
                                        }
                                      }
                                      return null;
                                    })}
                                  </ul>
                                </td>
                                <td>{data?.remainingDays}</td>
                                {Object.values(permission.Permissions).includes('editOptimizeTab') && (
                                <td title="Edit all the properties of the lineitem">
                                  <div className="pdf_icon" onClick={() => {
                                    setEditLineItemModal(true);
                                    setEditId(data?.id)
                                    setEditStatus(data?.lineItemStatusName)
                                  }}>
                                    <img src="/assets/images/edit_fill.svg" />
                                  </div>
                                </td>
                                )}
                              </tr>
                            )
                          })}

                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Tab>
                }
              </Tabs>
            </div>
          </div>
        </div>

      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New Line Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddLineItems
            dealid={dealid}
            dealCurrency={dealCurrency}
            start_date={campaign_detail?.date_from}
            end_date={campaign_detail?.date_to}
            budget_validate={campaign_detail?.budge - campaign_detail?.lineItemTotalBudget}
            campaignId={campaignId}
            setEditLineItemModal={setModalShow}
            onRefresh={searchFilter}
            getcampain={getPaginateData}
            getData={getcampain}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={editLineItemModal}
        onHide={() => setEditLineItemModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Line Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditLineItems
            setEditLineItemModal={setEditLineItemModal}
            lineItemId={editId}
            startDate={campaign_detail?.date_from}
            endDate={campaign_detail?.date_to}
            total_Budget={campaign_detail?.budge}
            sum_Budget={campaign_detail?.lineItemTotalBudget}
            dealid={dealid}
            campaignId={campaignId}
            onRefresh={searchFilter}
            getcampain={getPaginateData}
            getData={getcampain}
            editStatus={editStatus}
            statusId={editStatusId}
            dealCurrency={dealCurrency}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={creativemodalShow}
        onHide={() => setCreativeModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Header closeButton>
          <Modal.Title>New Creative</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewCreatives
            setCreativeModalShow={setCreativeModalShow}
            dealid={dealid}
            deal_id={deal_id}
            campaignId={campaignId}
            lineItem={inserted_lineitem_id}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={statusmodalShow}
        onHide={() => {
          setStatusModalShow(false);
          resetstatus();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>

          <div className="model_content">
            <div className='text-center mb-4'>
              <FiAlertCircle className='alert_icon' size={64} />
            </div>

            <h4 className="text-center border-bottom pb-5"> {statusmodel?.messageText}</h4>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="me-4 cancel_cm_btn"
                onClick={() => setStatusModalShow(false)}
              >
                Cancel
              </button>
              <button
                className={statusmodel?.buttonClass}
                onClick={() => updateStatus()}
              >
                {statusmodel?.buttonText}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalcampaignShow}
        onHide={() => setModalCampaignShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {campaign_detail ? "Update Campaign" : "New Campaign"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewCompaign
            setModalShow={setModalCampaignShow}
            dealid={dealid}
            deal_id={deal_id}
            clientId={clientId}
            start_date={startdate}
            total_Budget={total_budget}
            sum_Budget={sum_budget}
            end_date={enddate}
            dealCurrency={dealCurrency}
            searchFilter={getCampaign}
            campaign_detail={campaign_detail}
            onRefresh={searchFilter}
            getcampain={getcampain}
            budget_used={campaign_detail?.lineItemTotalBudget}
            currency_code={currency_code}
            remainingBudget={remainingBudget}
            campaignStat={campaign_detail?.statusNavigation?.description}
            lineitem_count={campaign_detail?.lineitemCount}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modal_open}
        onHide={() => setModal_open(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h6 className="text-center">
            Because there is no extra budget decrease the budget from Line-item and return.
          </h6>
        </Modal.Body>
      </Modal>

      <Modal
        show={statusLineItem}
        onHide={() => setStatusLineItem(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-4">
          <div className='text-center mb-4'>
            <FiAlertCircle className='alert_icon' size={64} />
          </div>
          <h4 className="text-center border-bottom pb-5">The Campaign is {campaignStatus}, Do you want to Active Campaign?</h4>
          <div className="d-flex justify-content-end mt-5">
            <button className="me-4 cancel_cm_btn" onClick={() => setStatusLineItem(false)}>Cancel</button>
            <button className="cmn_btn ms-2 " onClick={handleStatusUpdate}>Confirm</button>
          </div>

        </Modal.Body>
      </Modal>
    </div>
  );
};
export default LineItems;