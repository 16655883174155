const dimensionsMap = [
  {dimension: "Advertiser", dimensionParameter: "advertiser"},
  {dimension: "Campaign", dimensionParameter: "campaign"},
  {dimension: "Campaign Id", dimensionParameter: "campaign_id"},
  {dimension: "Creative", dimensionParameter: "creative"},
  {dimension: "Creative Id", dimensionParameter: "creative_id"},
  {dimension: "Line Item Id", dimensionParameter: "lineitem_id"},
  {dimension: "Format", dimensionParameter: "format"},
  {dimension: "Date", dimensionParameter: "date"},
  {dimension: "Offer Type", dimensionParameter: "offerType"},
  {dimension: "Inventory Type", dimensionParameter: "inventoryType"},
  {dimension: "Country App", dimensionParameter: "country_app"},
  {dimension: "Country Youtube", dimensionParameter: "country_youtube"},
  {dimension: "Devices", dimensionParameter: "device"},
  {dimension: "App Name", dimensionParameter: "placement"},
  {dimension: "Youtube Channel", dimensionParameter: "placement"},
]

export default dimensionsMap;