import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import PerformanceReport from "./performanceReport";

const AdvanceReports = () => {
  const [audienceReportTab, setAudienceReportTab] = useState(false);
	const [performanceReportTab, setPerformanceReportTab] = useState(true);
  const [savedReportsTab, setSavedReportsTab] = useState(false);

  const handleAudienceReport = () => {
		setAudienceReportTab(true);
		setPerformanceReportTab(false);
    setSavedReportsTab(false);
	}

  const handlePerformanceReport = () => {
		setAudienceReportTab(false);
		setPerformanceReportTab(true);
    setSavedReportsTab(false);
	}

  const handleSavedReports = () => {
		setAudienceReportTab(false);
		setPerformanceReportTab(false);
    setSavedReportsTab(true);
	}

  return (
    <div className="content_outer">
      <div className="content pb-0">
        <div className="divSuperiorAdvanceReports">
            <div className="divContentAdvanceReports">
              <Row className="divAdvanceReportsTitles text-center">
                <Col xs={4} /*onClick={handleAudienceReport}*/>
                  <div className={`display-none divAdvanceReportsTitlesBottom ${audienceReportTab ? 'colReportBorderBottom' : ''}`}>
                    <h4>Audience Report</h4>
                  </div>
                </Col>
                <Col xs={4} /*onClick={handlePerformanceReport}*/ >
                  <div className={`divAdvanceReportsTitlesBottom ${performanceReportTab ? 'colReportBorderBottom' : ''}`}>
                    <h4>Performance Report</h4>
                  </div>
                </Col>
                <Col xs={4} /*onClick={handleSavedReports}*/ >
                  <div className={`display-none divAdvanceReportsTitlesBottom ${savedReportsTab ? 'colReportBorderBottom' : ''}`}>
                    <h4>My Reports</h4>
                  </div>
                </Col>
              </Row>
            </div>

            {performanceReportTab &&
              <PerformanceReport />  
            }
        </div>
      </div>
    </div>
  );
}

export default AdvanceReports;