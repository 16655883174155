import { AiOutlineSearch } from "react-icons/ai";
import "./style.css";


const SearcherInput = (props) => {
  return (
    <div className={`regularSearchDiv ${props.divStyle}`}>
      <AiOutlineSearch size={props.iconSize} className={`regularSearchIcon ${props.iconStyle}`}/>
      <input onChange={props.onChange} placeholder={props.placeholder} className={`regularSearchInput ${props.inputStyle}`}/>
    </div>
  );
};

export default SearcherInput;