import React, { useState, useEffect } from 'react'
import './campaign.css'
import { useSelector, useDispatch } from 'react-redux';
import { developedBy, CreativeSelector, getCreativeTypes } from '../../app/features/Creative/CreativeSlice';
import { toast } from "react-hot-toast";
import Loader from "../Loader";



const NewCreativesSaas = (props) => {
	const dispatch = useDispatch();
	const [creative_impressions, setCreative_impressions] = useState("")
	const [creative_clicks_tracking, setCreative_clicks_tracking] = useState("")
	const [creative_landing_url, setCreative_landing_url] = useState("")
	const [add_creative_name, setAdd_creative_name] = useState("")
	const [add_developer_by, setAdd_developed_by] = useState(null)
	const [add_creative_type, setAdd_creative_type] = useState(null)
	const [add_creative, setAdd_creative] = useState("")
	const [add_creative_file, setAdd_creative_file] = useState("")
	const [add_creative_tag, setAdd_creative_tag] = useState("")
	const [add_vast_tag, setAdd_vast_tag] = useState("")
	const [add_creative_link, setAdd_creative_link] = useState("")
	const [loadershown, setloadershown] = useState(false);
	const [disableImpressionTracking, setDisableImpressionTracking] = useState(false)
	const [disabled_button,setDisabled_button] = useState(false)
	const [uploadFile, setUploadFile] = useState('')

	//these below variables have values of developer_by and creative type nitin 11/09/2023
	const {
		developed_by,
		creativeTypes
	} = useSelector(CreativeSelector);

	//this below function is used to get the all developed by and other things that needed in creative saas we use redux here because these endpoints are already created so we use previous ones nitin 11/09/2023
	useEffect(() => {
		dispatch(developedBy({ token: localStorage.getItem('token') }));
		if (props?.format_id) {
			dispatch(getCreativeTypes({ type_id: props.format_id, token: localStorage.getItem('token') }));
		}
	}, [])


	// this below function is used to store the new creative nitin 15/09/2023
	const handle_save_creative = () => {
		var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		const allowedExtensions = ["mp4"];

		if (!creative_landing_url.includes("https://")) {
			toast.error("The input URL landing must contain https://");
		}
		else if (add_creative_name === "") {
			toast.error("Creative name field can't be empty !!")
		} else if (format.test(add_creative_name)) {
			toast.error("Creative name must be without special chracters")
		}
		else if (add_developer_by === null) {
			toast.error("Please select developed_by !!")
		}
		else if (add_creative_type === null) {
			toast.error("Please select Creative type !!")
		}
		else if (add_creative_type === 3 && add_creative_link === "") {
			toast.error("Please add creative link !!")
		}
		else if ((add_creative_type === 4 || add_creative_type === 6) && add_creative === "") {
			toast.error("Please add creative name !!")
		}
		else if ((add_creative_type === 1 || add_creative_type === 2) && add_creative_file === "") {
			toast.error("Please select file !!")
		}
		else if (add_creative_type === 5 && add_creative_tag === "") {
			toast.error("Creative tag field can't be empty !!")
		}
		else if (creative_landing_url === creative_clicks_tracking) {
			toast.error("Landing Page and Click Tracker must be different");
		}		
		else if (add_creative_type == 3 && !(add_creative_link.startsWith("https://www.youtube.com/") || add_creative_link.startsWith("https://youtu.be/"))) {
			toast.error("Youtube Creative must start with “https://www.youtube.com/” or “https://youtu.be/”");
		}
		else if (add_creative_file && (add_creative_file.size > 10 * 1024 * 1024)) {
			toast.error("File size should not be more than 10 MB");
		}
		else if (add_creative_file && !allowedExtensions.includes(add_creative_file.name.split(".").pop().toLowerCase())) {
			toast.error("File should be video with extension .mp4");
		}
		else {
			setloadershown(true);

			var myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

			var formdata = new FormData();
			formdata.append("name", add_creative_name);
			formdata.append("developedBy", add_developer_by);
			formdata.append("creativeTypeId", add_creative_type);
			formdata.append("impressionTracking", creative_impressions);
			formdata.append("clicksTracking", creative_clicks_tracking);
			formdata.append("lineItemId", props?.lineitem_id);
			formdata.append("urlLandingPage", creative_landing_url);
			formdata.append("formatId", props?.format_id);
			formdata.append("vastTags", add_vast_tag ? add_vast_tag : "");
			formdata.append("tags", add_creative_tag);
			formdata.append("link", add_creative_link);
			formdata.append("file", add_creative_file ? add_creative_file : "");
			formdata.append("designid", "");

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			};

			fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/store-creative`, requestOptions)
				.then(response => {
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					return response.json();
				})
				.then(result => {
					toast.success("Creative saved successfully. It will be Under Review for up to 48hs");
					props?.setCreativeModalShow(false);
					props?.get_all_creatives();
				})
				.catch(error => {
					toast.error(error.message);
				});
		}
	}

	const handleUploadFile = (e) => {
		const fileExtension = e.currentTarget.files[0].name.split(".").pop().toLowerCase();
		const allowedExtensions = ["mp4"];

		if (!allowedExtensions.includes(fileExtension)) {
				toast.error("File should be video with extension .mp4");
		}
		if (e.currentTarget.files[0].size > 10 * 1024 * 1024) {
				toast.error("File size should not be more than 10 MB");
		}
		
		const video = document.createElement('video');
  	video.src = URL.createObjectURL(e.currentTarget.files[0]);
		video.onloadedmetadata = function() {
			if (video.duration > 30) {
				toast.error("Video duration should not be more than 30 seconds");
			}
		};
		setUploadFile(e.currentTarget.files[0]);
		setAdd_creative_file(e.target.files[0])
	}

	const validate_length = (e) => {
		if(e.target.value?.length > 250){
			toast.error("You Cannot add more than 250 chracters in Landing Page !!!")
			setDisabled_button(true)
		}
		else{
			setDisabled_button(false)
		}
	}

	return (
		<>
			<div className=''>
				<Loader showLoader={loadershown} />
				<div className='row'>
					<div className="col-md-6">
						<div className="form-group mb-4 select_country_container cmn_modal_field">
							<label htmlFor="creativeName" className='lbl_position'>Creative Name</label>
							<input type="text" className='form-control' placeholder='Creative Name' name="name" maxLength={255}
								value={add_creative_name} onChange={(e) => setAdd_creative_name(e.target.value)} />
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group mb-4 select_country_container cmn_modal_field" >
							<label htmlFor="developedBy" className='lbl_position'>Developed By</label>

							<select name="developedBy" className='form-control form-select'
								value={add_developer_by}
								onChange={(e) => setAdd_developed_by(e.target.value)}
							>
								<option>Select</option>
								{developed_by?.map((data, index) => {
									return (
										<option key={index} value={data?.id}>{data?.name}</option>
									)
								})}
							</select>

						</div>
					</div>
					<div className='col-12'>
						<div className="">
							<div className="row">
								<div className="col-md-6">
									<div className="form-group mb-4 select_country_container cmn_modal_field">
										<label htmlFor="developedBy" className='lbl_position'>Creative Type</label>

										<select name="developedBy" className='form-control form-select'
											value={add_creative_type}
											onChange={(e) => {
												setAdd_creative_type(e.target.value);
												e.target.value === '3' ? setDisableImpressionTracking(true) : setDisableImpressionTracking(false);
											}}
										>
											<option>Select</option>
											{creativeTypes?.map((data, index) => {
												return (
													<option key={index} value={data?.id}>{data?.description}</option>
												)
											})}
										</select>
									</div>
								</div>

								{(add_creative_type == 4) && <div className="col-md-6">
									<div className="form-group mb-4 select_country_container cmn_modal_field">
										<label htmlFor="creative" className='lbl_position'>Creative</label>
										<input type="text" name="" className='form-control' placeholder='Creative'
											value={add_creative}
											onChange={(e) => setAdd_creative(e.target.value)}
										/>
									</div>
								</div>}
								{(add_creative_type == 6) && <div className="col-md-6">
									<div className="form-group mb-4 select_country_container cmn_modal_field">
										<label htmlFor="creative" className='lbl_position'>Creative</label>
										<input type="text" name="" className='form-control' placeholder='Creative'
											value={add_vast_tag}
											onChange={(e) => setAdd_vast_tag(e.target.value)}
										/>
									</div>
								</div>}
								{add_creative_type == 3 && <div className="col-md-6">
									<div className="form-group mb-4 select_country_container cmn_modal_field">
										<label htmlFor="creative" className='lbl_position'>Creative</label>
										<input type="text" name="" className='form-control' placeholder='Creative'
											value={add_creative_link}
											onChange={(e) => setAdd_creative_link(e.target.value)}
										/>
									</div>
								</div>}
								{(add_creative_type == 1 || add_creative_type == 2) &&
									<div className="col-md-6">
										<div className="form-group mb-4 select_country_container cmn_modal_field">
											<label htmlFor="creative" className='lbl_position'>
												Creative
											</label>
											<div className="boxCreativeFile">
												<label className="labelFileCreative">
													{uploadFile ? uploadFile.name : 'File'}
												</label>
												<>
													<input
														id="creativeFile"
														type="file"
														name="file"
														disabled={props?.edit}
														style={{ display: 'none' }}
														onChange={handleUploadFile}
													/>
													<label className="labelChooseFileCreative" htmlFor="creativeFile">
														Select File
													</label>
												</>
											</div>
										</div>
									</div>
								}
								{add_creative_type == 5 && <div className="col-md-6">
									<div className="form-group mb-4 select_country_container cmn_modal_field">
										<label htmlFor="creative" className='lbl_position'>Creative</label>
										<textarea type="text" name="" className='form-control' placeholder='Creative'
											value={add_creative_tag}
											onChange={(e) => setAdd_creative_tag(e.target.value)}
										/>
									</div>
								</div>}
								{props.inventoryNavigationId != 4 &&
								<div className="col-md-6">
									<div className={"form-group mb-4 select_country_container cmn_modal_field " + (disableImpressionTracking && 'bg-secondary')}>
										<label htmlFor="creative" className={'lbl_position ' + (disableImpressionTracking && 'text-white')}>Impression Tracking</label>
										<input
											type="text"
											name="text"
											className={'form-control ' + (disableImpressionTracking && 'bg-secondary text-white placeholderWhite')}
											placeholder='Impression Tracking'
											value={creative_impressions}
											onChange={(e) => setCreative_impressions(e.target.value)}
											disabled={disableImpressionTracking}
										/>
									</div>
								</div>
								}
								<div className="col-md-6">
									<div className="form-group mb-4 select_country_container cmn_modal_field">
										<label htmlFor="creative" className='lbl_position'>Click Tracking</label>
										<input type="text" name="text" className='form-control' placeholder='Click Tracking' value={creative_clicks_tracking} onChange={(e) => setCreative_clicks_tracking(e.target.value)} />

									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group mb-4 select_country_container cmn_modal_field">
										<label htmlFor="creative" className='lbl_position'>Landing Pages</label>
										<input type="text" name="text" className='form-control' onBlur={(e) => validate_length(e)} placeholder='Landing Pages' value={creative_landing_url} onChange={(e) => setCreative_landing_url(e.target.value)} />
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className='col-12 mt-4'>
								<div className="d-flex justify-content-end mt-4"><button type="button" className="me-4 ms-auto cancel_cm_btn" onClick={() => { props.setCreativeModalShow(false) }}>Cancel</button>
									{/* this below function is used to store the new creative nitin 15/09/2023 */}
									<button className="cmn_btn ms-2 " type="submit" disabled={disabled_button} onClick={() => handle_save_creative()}>Save</button>
								</div>
								{/* <button type='submit' className="cmn_btn ms-auto">Save</button> */}

								{/* {!edit_data ? <button type="submit" className="cmn_btn ms-auto">Save</button> : <button type="submit" className="cmn_btn ms-auto" onClick={() => {handle_update_creative(values)}}>Edit</button>} */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default NewCreativesSaas