import { useState, useEffect } from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight, MdOutlineCancel } from "react-icons/md";
import rowReportsList from "./constants/rowsReportsList";
import columnsReportsList from "./constants/columnsReportsList";
import filterReportsList from "./constants/filterReportsList";
import SearcherInput from "../General/SearcherInput";
import ModalFiltersReports from "./modalFiltersReports";
import ModalCustomDate from "./constants/modalCustomDate";
import saveIcon from "../../assets/images/saveIcon.svg";
import resetIcon from "../../assets/images/resetIcon.svg";
import downloadIcon from "../../assets/images/downloadIcon.svg";
import saveIconDisabled from "../../assets/images/saveIconDisabled.svg";
import resetIconDisabled from "../../assets/images/resetIconDisabled.svg";
import downloadIconDisabled from "../../assets/images/downloadIconDisabled.svg";
import { getPerformanceReport } from '../../services/reportsService';
import { downloadCsv } from '../../utils/index';
import Spinner from 'react-bootstrap/Spinner';
import incompatibleData from './constants/performanceReportIncompatibleData';
import dimensionMap from './constants/dimensionsMap';

const PerformanceReport = () => {
  const [rowTab, setRowTab] = useState(true);
	const [columnTab, setColumnTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  // const [selectedRows, setSelectedRows] = useState(['Campaign', 'Date', 'Format']);  //  useState([]);
  // const [selectedColumns, setSelectedColumns] = useState(['Impressions', 'Goal', 'Clicks']);  // useState([]);
  // const [selectedRows, setSelectedRows] = useState(['Campaign', 'Creative', 'Date', 'Format', 'Offer Type', 'Inventory Type']);
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedColumns, setSelectedColumns] = useState([]);
  /*const [selectedColumns, setSelectedColumns] = useState([
    'Impressions',
    'Views',
    'Goal',
    'Clicks',
    'Engagements',
    'VTR',
    'CTR',
    'ER',
    'Inversion budget Currency',
    'Inversion budget USD',
    'Spent (USD)',
    'Currency',
    'Spent Currency Deal',
    'ID HS Deal',
    'Campaign ID',
    'LI ID',
    'Start Date',
    'End Date'
  ]);*/
  const [rowReports, setRowReports] = useState(rowReportsList);
  const [columnsReports, setColumnsReports] = useState(columnsReportsList);
  const [showFilterList, setShowFilterList] = useState(false);
  const [showModalFiltersReports, setShowModalFiltersReports] = useState(false);
  const [showModalCustomDate, setShowModalCustomDate] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedBillingStatus, setSelectedBillingStatus] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [selectedInventoryType, setSelectedInventoryType] = useState([]);
  const [openColRows, setOpenColRows] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedStartEndDate, setSelectedStartEndDate] = useState(null);
  const [applyButton, setApplyButton] = useState(false);
  const [data, setData] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [reportName, setReportName] = useState(null);

  const mapSelectedRowsToDimensionParameters = (selectedRows, dimensionsMap) => {
    return selectedRows.map(row => {
      const matchingDimension = dimensionsMap.find(dim => dim.dimension === row);
      return matchingDimension ? matchingDimension.dimensionParameter : row;
    });
  };

  const handlePerformanceReport = () => {
    if (selectedDateRange !== null && selectedDateRange !== 'someOption' && selectedRows.length > 0) {
        setShowSpinner(true);
        const period = (selectedDateRange && selectedDateRange!=="customDate") ? selectedDateRange : null;
        const startDate = selectedDateRange === "customDate" ? selectedStartEndDate.startDate : null;
        const endDate = selectedDateRange === "customDate" ? selectedStartEndDate.endDate : null;
        const advertisers = selectedAdvertisers.map((advertiser) => advertiser.name);
        const campaigns = selectedCampaigns.map((campaign) => campaign.name);
        const selectedBilling = selectedBillingStatus.map((billOption) => billOption.id);
        const markets = selectedMarket.map((market) => market.name);
        const inventoryType = selectedInventoryType.map((inventory) => inventory.name);
        getPerformanceReport(mapSelectedRowsToDimensionParameters(selectedRows, dimensionMap), advertisers, campaigns, startDate, endDate, period, selectedBilling, inventoryType, markets)
        .then((res) => {
          setData(res.data)
          setShowSpinner(false);
        });
    }
  };

  useEffect(() => {
    handlePerformanceReport();
  }, [selectedDateRange, selectedRows, selectedStartEndDate]);

  const getSelectedItems = (filter) => {
    switch (filter) {
      case 'Advertiser':
        return selectedAdvertisers;
      case 'Campaign':
        return selectedCampaigns;
      case 'Billing Status':
        return selectedBillingStatus;
      case 'Market':
        return selectedMarket;
      case 'Inventory Type':
        return selectedInventoryType;
      default:
        return [];
    }
  };

  const getSetSelectedItems = (filter) => {
    switch (filter) {
      case 'Advertiser':
        return setSelectedAdvertisers;
      case 'Campaign':
        return setSelectedCampaigns;
      case 'Billing Status':
        return setSelectedBillingStatus;
      case 'Market':
        return setSelectedMarket;
      case 'Inventory Type':
        return setSelectedInventoryType;
      default:
        return () => {};
    }
  };

  const handleRowTab = () => {
		setRowTab(true);
		setColumnTab(false);
	}

  const handleColumnTab = () => {
		setRowTab(false);
		setColumnTab(true);
	}

  const handleSearchAttribute = (e) => {
    setSearchTerm(e.target.value);
  }

  const handleSearchFilter = (e) => {
    setSearchFilter(e.target.value);
  }

  const filteredReports = (reports, searchType) => {
    return reports.filter((item) =>
      item.toLowerCase().includes(searchType.toLowerCase())
    ).sort((a, b) => a.localeCompare(b));
  };

  const handleRowClick = (item) => {
    setSelectedRows([...selectedRows, item]);
    setRowReports(rowReports.filter(report => report !== item));
  };

  const handleColumnClick = (item) => {
    setSelectedColumns([...selectedColumns, item]);
    setColumnsReports(columnsReports.filter(report => report !== item));
  };

  const handleCloseFiltersReportsModal = () => {
    setShowModalFiltersReports(false);
  }

  const handleCloseCustomDateModal = () => {
    setShowModalCustomDate(false);
  }

  let lastSelectValue = null;

  const handleReset = () => {
    setSelectedDateRange("someOption");
    setSelectedFilter([]);
    setSelectedRows([]);
    setSelectedColumns([]);
    setRowReports(rowReportsList);
    setColumnsReports(columnsReportsList);
    setSelectedAdvertisers([]);
    setSelectedCampaigns([]);
    setSelectedBillingStatus([]);
    setSelectedMarket([]);
    setSelectedInventoryType([]);
    setData(null);
  };

  const handleDownload = () => {
    const csvData = [
      [
        selectedRows.find(item => item === "Advertiser") ? 'Advertiser' : undefined,
        selectedRows.find(item => item === "Campaign") ? 'Campaign' : undefined,
        selectedRows.find(item => item === "Line Item") ? 'Line Item' : undefined,
        selectedRows.find(item => item === "Creative") ? 'Creatives' : undefined,
        selectedRows.find(item => item === "Date") ? 'Date' : undefined,
        selectedRows.find(item => item === "Format") ? 'Format' : undefined,
        selectedRows.find(item => item === "Inventory Type") ? 'Inventory Type' : undefined,
        selectedRows.find(item => item === "Offer Type") ? 'Offer Type' : undefined,
        selectedRows.find(item => item === "Country App") ? 'Country App' : undefined,
        selectedRows.find(item => item === "Country Youtube") ? 'Country Youtube' : undefined,
        selectedRows.find(item => item === "Devices") ? 'Devices' : undefined,
        selectedRows.find(item => item === "App Name") ? 'App Name' : undefined,
        selectedRows.find(item => item === "Youtube Channel") ? 'Youtube Channel' : undefined,
        selectedColumns.find(item => item === "Impressions") ? 'Impressions' : undefined,
        selectedColumns.find(item => item === "Views") ? 'Views' : undefined,
        selectedColumns.find(item => item === "Goal") ? 'Goal' : undefined,
        selectedColumns.find(item => item === "Clicks") ? 'Clicks' : undefined,
        selectedColumns.find(item => item === "Engagements") ? 'Engagements' : undefined,
        selectedColumns.find(item => item === "VTR") ? 'VTR' : undefined,
        selectedColumns.find(item => item === "CTR") ? 'CTR' : undefined,
        selectedColumns.find(item => item === "ER") ? 'ER' : undefined,
        selectedColumns.find(item => item === "Inversion budget Currency") ? 'Inversion budget Currency' : undefined,
        selectedColumns.find(item => item === "Inversion budget USD") ? 'Inversion budget USD' : undefined,
        selectedColumns.find(item => item === "Spent (USD)") ? 'Spent (USD)' : undefined,
        selectedColumns.find(item => item === "Currency") ? 'Currency' : undefined,
        selectedColumns.find(item => item === "Spent Currency Deal") ? 'Spent Currency Deal' : undefined,
        selectedColumns.find(item => item === "ID HS Deal") ? 'ID HS Deal' : undefined,
        selectedColumns.find(item => item === "Campaign ID") ? 'Campaign ID' : undefined,
        selectedColumns.find(item => item === "LI ID") ? 'LI ID' : undefined,
        selectedColumns.find(item => item === "Creative ID") ? 'Creative ID' : undefined,
        selectedColumns.find(item => item === "Start Date") ? 'Start Date' : undefined,
        selectedColumns.find(item => item === "End Date") ? 'End Date' : undefined,
        selectedColumns.find(item => item === "Quartiles") ? 'First Quartile' : undefined,
        selectedColumns.find(item => item === "Quartiles") ? 'Mid Quartile' : undefined,
        selectedColumns.find(item => item === "Quartiles") ? 'Third Quartile' : undefined,
      ].filter((item) => item !== undefined),
      ...data.map((item) => ([
        selectedRows.find(item => item === "Advertiser") ? item.advertiser + (item.country ? ' - ' + item.country : '') : undefined,
        selectedRows.find(item => item === "Campaign") ? item.campaign : undefined,
        selectedRows.find(item => item === "Line Item") ? item.lineItem : undefined,
        selectedRows.find(item => item === "Creative") ? item.creative : undefined,
        selectedRows.find(item => item === "Date") ? item.date : undefined,
        selectedRows.find(item => item === "Format") ? item.format : undefined,
        selectedRows.find(item => item === "Inventory Type") ? item.inventoryType : undefined,
        selectedRows.find(item => item === "Offer Type") ? item.offerType : undefined,
        selectedRows.find(item => item === "Country App") ? item.country : undefined,
        selectedRows.find(item => item === "Country Youtube") ? item.country : undefined,
        selectedRows.find(item => item === "Devices") ? item.devices : undefined,
        selectedRows.find(item => item === "App Name") ? item.placement : undefined,
        selectedRows.find(item => item === "Youtube Channel") ? item.placement : undefined,
        selectedColumns.find(item => item === "Impressions") ? String(item.impressions).replaceAll(',', '') : undefined,
        selectedColumns.find(item => item === "Views") ? (item.completeViewsVideo ? String(item.completeViewsVideo).replaceAll(',', '') : '-') : undefined,
        selectedColumns.find(item => item === "Goal") ? (item.goal ? String(item.goal).replaceAll(',', '') : '-') : undefined,
        selectedColumns.find(item => item === "Clicks") ? String(item.clicks).replaceAll(',', '') : undefined,
        selectedColumns.find(item => item === "Engagements") ? (item.engangements ? String(item.engangements).replaceAll(',', '') : '-') : undefined,
        selectedColumns.find(item => item === "VTR") ? (item.vtr ? item.vtr.toFixed(2) + "%" : "-") : undefined,
        selectedColumns.find(item => item === "CTR") ? (item.ctr ? item.ctr.toFixed(2) + "%" : "-") : undefined,
        selectedColumns.find(item => item === "ER") ? (item.er ? item.er.toFixed(2) + "%" : "-") : undefined,
        selectedColumns.find(item => item === "ER") ? (item.er ? item.er.toFixed(2) + '%' : '-') : undefined,
        selectedColumns.find(item => item === "Inversion budget Currency") ? ((item?.inversionBudget?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : undefined,
        selectedColumns.find(item => item === "Inversion budget USD") ? ((item?.inversionBudgetUsd?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : undefined,
        selectedColumns.find(item => item === "Spent (USD)") ? (item.spentUsd ? item.spentUsd.toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-') : undefined,
        selectedColumns.find(item => item === "Currency") ? item.currencyCode : undefined,
        selectedColumns.find(item => item === "Spent Currency Deal") ? item.spent.toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : undefined,
        selectedColumns.find(item => item === "ID HS Deal") ? item.dealId : undefined,
        selectedColumns.find(item => item === "Campaign ID") ? item.campaignID : undefined,
        selectedColumns.find(item => item === "LI ID") ? item.kiteLineItemId : undefined,
        selectedColumns.find(item => item === "Creative ID") ? (item.creativeId ? item.creativeId : '-') : undefined,
        selectedColumns.find(item => item === "Start Date") ? item.startDate.split('T')[0] : undefined,
        selectedColumns.find(item => item === "End Date") ? item.endDate.split('T')[0] : undefined,
        selectedColumns.find(item => item === "Quartiles") ? (item.firstQuartile ? item.firstQuartile : '-') : undefined,
        selectedColumns.find(item => item === "Quartiles") ? (item.midQuartile ? item.midQuartile : "-" ): undefined,
        selectedColumns.find(item => item === "Quartiles") ? (item.thirdQuartile ? item.thirdQuartile : "-" ) : undefined,
      ].filter((item) => item !== undefined)),
      )];
    let date = new Date();
    
    downloadCsv(reportName ? (reportName) : ('reportPerformanceUntitled-' + date.getDate() + (date.getMonth() + 1) + date.getFullYear()) + '.csv', csvData);
  }

  const disabledRowHandle = (item) => {
    let incompatibleItems = [];
  
    for (const selectedRow of selectedRows) {
      const incompatibleItem = incompatibleData.find(
        incompatible => 
          (incompatible.selectedRow === selectedRow && incompatible.incompatibleRow === item) ||
          (incompatible.selectedRow === item && incompatible.incompatibleRow === selectedRow)
      );
      
      if (incompatibleItem) {
        incompatibleItems.push(selectedRow);
      }
    }
  
    for (const selectedColumn of selectedColumns) {
      const incompatibleItem = incompatibleData.find(
        incompatible => 
          (incompatible.selectedColumn === selectedColumn && incompatible.incompatibleRow === item) ||
          (incompatible.selectedRow === item && incompatible.incompatibleColumn === selectedColumn)
      );
      
      if (incompatibleItem) {
        incompatibleItems.push(`column ${selectedColumn}`);
      }
    }
  
    incompatibleData.forEach(incompatible => {
      if (incompatible.selectedInventoryType &&
          selectedInventoryType.some(type => type.name === incompatible.selectedInventoryType) &&
          incompatible.incompatibleRow === item) {
        incompatibleItems.push(`inventory type ${incompatible.selectedInventoryType}`);
      }
    });
  
    const isDisabled = incompatibleItems.length > 0;
    const message = isDisabled ? `This field is not compatible with ${incompatibleItems.join(' and ')}` : '';
  
    return { isDisabled, message };
  };
  
  const disabledColumnHandle = (item) => {
    let incompatibleItems = [];
  
    for (const selectedRow of selectedRows) {
      const incompatibleItem = incompatibleData.find(
        incompatible => 
          (incompatible.selectedRow === selectedRow && incompatible.incompatibleColumn === item) ||
          (incompatible.selectedColumn === item && incompatible.incompatibleRow === selectedRow)
      );
      
      if (incompatibleItem) {
        incompatibleItems.push(selectedRow);
      }
    }
  
    for (const selectedColumn of selectedColumns) {
      const incompatibleItem = incompatibleData.find(
        incompatible => 
          (incompatible.selectedColumn === selectedColumn && incompatible.incompatibleColumn === item) ||
          (incompatible.selectedColumn === item && incompatible.incompatibleColumn === selectedColumn)
      );
      
      if (incompatibleItem) {
        incompatibleItems.push(selectedColumn);
      }
    }
  
    incompatibleData.forEach(incompatible => {
      if (incompatible.selectedInventoryType &&
          selectedInventoryType.some(type => type.name === incompatible.selectedInventoryType) &&
          incompatible.incompatibleColumn === item) {
        incompatibleItems.push(`inventory type ${incompatible.selectedInventoryType}`);
      }
    });
  
    const isDisabled = incompatibleItems.length > 0;
    const message = isDisabled ? `This field is not compatible with ${incompatibleItems.join(' and ')}` : '';
  
    return { isDisabled, message };
  };

  window.addEventListener('click', () => {
    setSearchFilter("");
    setShowFilterList(false);
  });

  const handleClearRowsColumns = () => {
    setSelectedRows([]);
    setSelectedColumns([]);
  }

  return (
    <div className="divContentPerformanceReport">
      <div className="divBoxPerformanceReport">
        <section className="divSectionPerformanceReport">
          <Row>
            <Col xs={5}>
              <input
                className="reportInputName lettersPerformanceReport backgroundColorAdvanceReports"
                placeholder="Name of report"
                type="text"
                name="name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
              />
            </Col>
            <Col xs={5}>
              <select
                className="reportsInputDates lettersPerformanceReport backgroundColorAdvanceReports"
                value={selectedDateRange}
                onFocus={(e) => {
                  if (e.target.value === "customDate") {
                    lastSelectValue = e.target.value;
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") e.target.value = lastSelectValue;
                }}
                onChange={(e) => {
                  e.target.value === "customDate"
                    ? setShowModalCustomDate(true)
                    : setSelectedDateRange(e.target.value);
                  lastSelectValue = e.target.value;
                  e.target.blur()
                }}
                
              >
                <option value="someOption">Date range</option>
                <option value="yesterday">Yesterday</option>
                <option value="today">Today</option>
                <option value="thisMonth">This month</option>
                <option value="lastSevenDays">Last seven days</option>
                <option value="last_month">Last month</option>
                <option value="customDate">
                  {selectedDateRange === "customDate" ? selectedStartEndDate.startDate + " - " + selectedStartEndDate.endDate : "Custom date"}
                </option>
              </select>
            </Col>
            <Col xs={2} className="coldivIconsReports">
              <div className="divIconsReports">
                
                {!data ? (
                  <>
                    <img src={saveIconDisabled} className="display-none reportIcons" /> 
                    <img src={resetIconDisabled} className="reportIcons" /> 
                    <img src={downloadIconDisabled} className="reportIcons" /> 
                  </>
                ): (
                  <>
                    <img src={saveIcon} className="display-none reportIcons" />
                    <img src={resetIcon} className="reportIcons" onClick={handleReset} />
                    <img src={downloadIcon} className="reportIcons" onClick={handleDownload} />
                  </>
                )
                }
              </div>
            </Col>
          </Row>
        </section>
        <section className="divSectionPerformanceReport">
          <div className="divFiltersPerformanceReport backgroundColorAdvanceReports">
            <p className="pFilters lettersPerformanceReport">Filters</p>
            {selectedAdvertisers.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Advertiser")
                  setShowModalFiltersReports(true)
                }}
              >
                Advertiser
              </button>
            )}
            {selectedCampaigns.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Campaign")
                  setShowModalFiltersReports(true)
                }}
              >
                Campaign
              </button>
            )}
            {selectedBillingStatus.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Billing Status")
                  setShowModalFiltersReports(true)
                }}
              >
                Billing Status
              </button>
            )}
            {selectedMarket.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Market")
                  setShowModalFiltersReports(true)
                }}
              >
                Market
              </button>
            )}
            {selectedInventoryType.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter("Inventory Type")
                  setShowModalFiltersReports(true)
                }}
              >
                Inventory Type
              </button>
            )}
            <div className="divFilterList">
              <button
                className="btn btn-primary regularButton buttonFilters buttonAdd"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFilterList(!showFilterList)
                }}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.25 19v-6.25H5v-1.5h6.25V5h1.5v6.25H19v1.5h-6.25V19h-1.5z"
                    fill="#fff"
                  />
                </svg>
                Add
              </button>
              {showFilterList && (
                <div
                  className="divFiltersListPerformanceReport"
                  tabIndex={1}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="divSearcherFilters">
                    <SearcherInput
                      iconSize={18}
                      onChange={handleSearchFilter}
                      placeholder="Search ..."
                      divStyle="searchDivGrey"
                      inputStyle="searchInputGrey"
                    />
                  </div>
                  <div className="divSearcherFilters cursorPointer lettersPerformanceReport">
                    {
                      filteredReports(filterReportsList, searchFilter).map((item, index) => (
                        <p
                          key={index}
                          onClick={() => {
                            setSelectedFilter(item)
                            setShowModalFiltersReports(true)
                          }}
                        >
                          {item}
                        </p>
                      ))
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="divSectionPerformanceReport">
          <div className="divRowsColumnsPerformanceReport">
            <Collapse dimension={"width"} in={openColRows}>
              <div className="divCollapseRowColPerformanceReport">
                <div className="divItemsPerformanceReport backgroundColorAdvanceReports">
                  <Row className="rowItemsPerformanceReport">
                    <Col xs={7}>
                      <h5 className="lettersPerformanceReport fontBold">Select or search the items with which you want to build the report</h5>
                      <div className="tabsColumnRow">
                        <Row>
                          <Col xs={6} onClick={handleRowTab} className={`colColumnRows ${rowTab ? 'colReportBorderBottom' : ''}`}>
                            <h5 className={`${rowTab ? 'colReportFont' : ''}`}>Row</h5>
                          </Col>
                          <Col xs={6} onClick={handleColumnTab} className={`colColumnRows ${columnTab ? 'colReportBorderBottom' : ''}`}>
                            <h5 className={`${columnTab ? 'colReportFont' : ''}`}>Column</h5>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <SearcherInput
                          iconSize={18}
                          onChange={handleSearchAttribute}
                          placeholder="Search..."
                        />
                        {(selectedRows.length > 0 || selectedColumns.length > 0) && (
                          <button className="btnClearRowsColumns" onClick={handleClearRowsColumns}>Clear all</button>
                        )}
                        <div className={`listColRowReports lettersPerformanceReport ${(selectedRows.length > 0 || selectedColumns.length > 0) ? 'listColRowReportsWithClearButton' : ''}`}>
                        {
                          rowTab
                            ? filteredReports(rowReports, searchTerm).map((item, index) => {
                                const { isDisabled, message } = disabledRowHandle(item);
                                return (
                                  <button
                                    className={`rowButton ${isDisabled ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={() => handleRowClick(item)}
                                    disabled={isDisabled}
                                    title={isDisabled ? message : ''}
                                  >
                                    {item}
                                  </button>
                                );
                              })
                            : filteredReports(columnsReports, searchTerm).map((item, index) => {
                                const { isDisabled, message } = disabledColumnHandle(item);
                                return (
                                  <button
                                    className={`rowButton ${isDisabled ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={() => handleColumnClick(item)}
                                    disabled={isDisabled}
                                    title={isDisabled ? message : ''}
                                  >
                                    {item}
                                  </button>
                                );
                              })
                          }
                        </div>
                      </div>
                    </Col>
                    <Col xs={5}>
                      <div className="divContentSelectedAttributesPerformanceReport">
                        <div className="divSelectedAttributesPerformanceReport">
                          <h5 className="lettersPerformanceReport fontBold titleSelectedAttributes">Rows</h5>
                          {
                            selectedRows.map((item, index) => (
                              <div key={index} className="divIndividualSelectedAttributesPerformanceReport lettersPerformanceReport">
                                <p key={index} className="divIndividualParagraphSelectedAttributesPerformanceReport">{item}</p>
                                <MdOutlineCancel
                                  className="cursorPointer"
                                  size={16}
                                  onClick={() => {
                                    setSelectedRows(selectedRows.filter(selectedItem => selectedItem !== item));
                                    setRowReports([...rowReports, item]);
                                  }}
                                />
                              </div>
                            ))
                          }
                        </div>
                        <div className="divSelectedAttributesPerformanceReport">
                          <h5 className="lettersPerformanceReport fontBold titleSelectedAttributes">Columns</h5>
                          {
                            selectedColumns.map((item, index) => (
                              <div key={index} className="divIndividualSelectedAttributesPerformanceReport lettersPerformanceReport">
                                <p key={index} className="divIndividualParagraphSelectedAttributesPerformanceReport">{item}</p>
                                <MdOutlineCancel
                                  className="cursorPointer"
                                  size={16}
                                  onClick={() => {
                                    setSelectedColumns(selectedColumns.filter(selectedItem => selectedItem !== item));
                                    setColumnsReports([...columnsReports, item]);
                                  }}
                                />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
            <button className="collapsableButton backgroundColorAdvanceReports" onClick={() => setOpenColRows(!openColRows)}>
              {openColRows ?
                <MdOutlineKeyboardDoubleArrowLeft /> :
                <MdOutlineKeyboardDoubleArrowRight />
              }
            </button>
            {
              showSpinner ? (
                <div className="divSpinner">
                  <Spinner className="loaderSpinner" animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                !data ? (
                  <>
                    <div className="divWithoutResults lettersPerformanceReport">
                      <h3>Your report is currently empty. </h3>                    
                      <h3>To get started, drag and drop metrics dimensions in the relevant section.</h3>
                    </div>
                  </>
                ) : (
                  data == [] || data.length == 0 ? (
                    <h3>No matches for your filters</h3>
                  ) : (
                    <div className={`divContentResultsPerformanceReport ${openColRows ? 'contentHalfResultsPerformanceReport' : 'contentFullResultsPerformanceReport'}`}>
                      <div className="divResultsRowColPerformanceReport">
                        <div className="divItemsPerformanceReport divTablePerformanceReportOverflow">
                          {data &&
                            <table className="tablePerformanceReport">
                              <thead>
                                <tr className="trPerformanceReport">
                                  {selectedRows.find(item => item === "Advertiser") && <th className="thPerformanceReport">Advertiser</th>}
                                  {selectedRows.find(item => item === "Campaign") && <th className="thPerformanceReport">Campaign</th>}
                                  {selectedRows.find(item => item === "Line Item") && <th className="thPerformanceReport">Line Item</th>}
                                  {selectedRows.find(item => item === "Creative") && <th className="thPerformanceReport">Creative</th>}
                                  {selectedRows.find(item => item === "Date") && <th className="thPerformanceReport">Date</th>}
                                  {selectedRows.find(item => item === "Format") && <th className="thPerformanceReport">Format</th>}
                                  {selectedRows.find(item => item === "Inventory Type") && <th className="thPerformanceReport">Inventory Type</th>}
                                  {selectedRows.find(item => item === "Offer Type") && <th className="thPerformanceReport">Offer Type</th>}
                                  {selectedRows.find(item => item === "Country App") && <th className="thPerformanceReport">Country App</th>}
                                  {selectedRows.find(item => item === "Country Youtube") && <th className="thPerformanceReport">Country Youtube</th>}
                                  {selectedRows.find(item => item === "Devices") && <th className="thPerformanceReport">Devices</th>}
                                  {selectedRows.find(item => item === "App Name") && <th className="thPerformanceReport">App Name</th>}
                                  {selectedRows.find(item => item === "Youtube Channel") && <th className="thPerformanceReport">Youtube Channel</th>}
                                  {selectedRows.find(item => item === "Campaign Id") && <th className="thPerformanceReport">Campaign Id</th>}
                                  {selectedRows.find(item => item === "Line Item Id") && <th className="thPerformanceReport">Line Item Id</th>}
                                  {selectedRows.find(item => item === "Creative Id") && <th className="thPerformanceReport">Creative Id</th>}
                                  {selectedColumns.find(item => item === "Impressions") && <th className="thPerformanceReport">Impressions</th>}
                                  {selectedColumns.find(item => item === "Views") && <th className="thPerformanceReport">Views</th>}
                                  {selectedColumns.find(item => item === "Goal") && <th className="thPerformanceReport">Goal</th>}
                                  {selectedColumns.find(item => item === "Clicks") && <th className="thPerformanceReport">Clicks</th>}
                                  {selectedColumns.find(item => item === "Engagements") && <th className="thPerformanceReport">Engagements</th>}
                                  {selectedColumns.find(item => item === "VTR") && <th className="thPerformanceReport">VTR</th>}
                                  {selectedColumns.find(item => item === "CTR") && <th className="thPerformanceReport">CTR</th>}
                                  {selectedColumns.find(item => item === "ER") && <th className="thPerformanceReport">ER</th>}
                                  {selectedColumns.find(item => item === "Inversion budget Currency") && <th className="thPerformanceReport thPerformanceReportLarge">Inversion budget Currency</th>}
                                  {selectedColumns.find(item => item === "Inversion budget USD") && <th className="thPerformanceReport thPerformanceReportLarge">Inversion budget USD</th>}
                                  {selectedColumns.find(item => item === "Spent (USD)") && <th className="thPerformanceReport">Spent (USD)</th>}
                                  {selectedColumns.find(item => item === "Currency") && <th className="thPerformanceReport">Currency</th>}
                                  {selectedColumns.find(item => item === "Spent Currency Deal") && <th className="thPerformanceReport thPerformanceReportLarge">Spent Currency Deal</th>}
                                  {selectedColumns.find(item => item === "ID HS Deal") && <th className="thPerformanceReport">ID HS Deal</th>}
                                  {selectedColumns.find(item => item === "Start Date") && <th className="thPerformanceReport">Start Date</th>}
                                  {selectedColumns.find(item => item === "End Date") && <th className="thPerformanceReport">End Date</th>}
                                  {selectedColumns.find(item => item === "Quartiles") && <th className="thPerformanceReport">First Quartile</th>}
                                  {selectedColumns.find(item => item === "Quartiles") && <th className="thPerformanceReport">Mid Quartile</th>}
                                  {selectedColumns.find(item => item === "Quartiles") && <th className="thPerformanceReport">Third Quartile</th>}
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((item, index) => (
                                  <tr key={index} className="trPerformanceReport">
                                    {selectedRows.find(item => item === "Advertiser") && <td className="tdPerformanceReport tdPerformanceReportLarge">{item.advertiser}{item.country ? ' - ' + item.country : ''}</td>}
                                    {selectedRows.find(item => item === "Campaign") && <td className="tdPerformanceReport tdPerformanceReportLarge">{item.campaign}</td>}
                                    {selectedRows.find(item => item === "Line Item") && <td className="tdPerformanceReport tdPerformanceReportLarge">{item.lineItem}</td>}
                                    {selectedRows.find(item => item === "Creative") && <td className="tdPerformanceReport">{item.creative}</td>}
                                    {selectedRows.find(item => item === "Date") && <td className="tdPerformanceReport">{item.date && item.date.split('T')[0]}</td>}
                                    {selectedRows.find(item => item === "Format") && <td className="tdPerformanceReport">{item.format}</td>}
                                    {selectedRows.find(item => item === "Inventory Type") && <td className="tdPerformanceReport">{item.inventoryType}</td>}
                                    {selectedRows.find(item => item === "Offer Type") && <td className="tdPerformanceReport">{item.offerType}</td>}
                                    {selectedRows.find(item => item === "Country App") && <td className="thPerformanceReport">{item.country ? item.country : '-'}</td>}
                                    {selectedRows.find(item => item === "Country Youtube") && <td className="thPerformanceReport">{item.country ? item.country : '-'}</td>}
                                    {selectedRows.find(item => item === "Devices") && <td className="thPerformanceReport">{item.devices ? item.devices : '-'}</td>}
                                    {selectedRows.find(item => item === "App Name") && <td className="thPerformanceReport">{item.placement ? item.placement : '-'}</td>}
                                    {selectedRows.find(item => item === "Youtube Channel") && <td className="thPerformanceReport">{item.placement ? item.placement : '-'}</td>}
                                    {selectedRows.find(item => item === "Campaign Id") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.campaignID}</td>}
                                    {selectedRows.find(item => item === "Line Item Id") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.lineitemId}</td>}
                                    {selectedRows.find(item => item === "Creative Id") && <td className="thPerformanceReport tdPerformanceReportRight">{item.creativeId ? item.creativeId : "-"}</td>}
                                    {selectedColumns.find(item => item === "Impressions") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.impressions ? item.impressions.toLocaleString('en-US') : '-'}</td>}
                                    {selectedColumns.find(item => item === "Views") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.completeViewsVideo ? item.completeViewsVideo.toLocaleString('en-US') : '-'}</td>}
                                    {selectedColumns.find(item => item === "Goal") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.goal ?item.goal.toLocaleString('en-US') : '-'}</td>}
                                    {selectedColumns.find(item => item === "Clicks") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.clicks ? item.clicks.toLocaleString('en-US') : '-'}</td>}
                                    {selectedColumns.find(item => item === "Engagements") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.engangements ? item.engangements.toFixed(2) : '-'}</td>}
                                    {selectedColumns.find(item => item === "VTR") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.vtr ? item.vtr.toFixed(2) + "%" : "-"}</td>}
                                    {selectedColumns.find(item => item === "CTR") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.ctr ? item.ctr.toFixed(2) + "%" : "-"}</td>}
                                    {selectedColumns.find(item => item === "ER") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.er ? item.er.toFixed(2) + "%" : "-"}</td>}
                                    {selectedColumns.find(item => item === "Inversion budget Currency") && <td className="tdPerformanceReport tdPerformanceReportRight">{item?.inversionBudget ? (parseFloat(item?.inversionBudget?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>}
                                    {selectedColumns.find(item => item === "Inversion budget USD") && <td className="tdPerformanceReport tdPerformanceReportRight">{item?.inversionBudgetUsd ? (parseFloat(item?.inversionBudgetUsd?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>}
                                    {selectedColumns.find(item => item === "Spent (USD)") && <td className="tdPerformanceReport tdPerformanceReportRight">{item?.spentUsd ? (parseFloat(item?.spentUsd?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>}
                                    {selectedColumns.find(item => item === "Currency") && <td className="tdPerformanceReport">{item.currencyCode}</td>}
                                    {selectedColumns.find(item => item === "Spent Currency Deal") && <td className="tdPerformanceReport tdPerformanceReportRight">{item?.spent ? (parseFloat(item?.spent?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>}
                                    {selectedColumns.find(item => item === "ID HS Deal") && <td className="tdPerformanceReport tdPerformanceReportRight">{item.dealId}</td>}
                                    {selectedColumns.find(item => item === "Start Date") && <td className="tdPerformanceReport">{item.startDate.split('T')[0]}</td>}
                                    {selectedColumns.find(item => item === "End Date") && <td className="tdPerformanceReport">{item.endDate.split('T')[0]}</td>}
                                    {selectedColumns.find(item => item === "Quartiles") && <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.firstQuartile ? item.firstQuartile : "-"}</td>}
                                    {selectedColumns.find(item => item === "Quartiles") && <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.midQuartile ? item.midQuartile : "-"}</td>}
                                    {selectedColumns.find(item => item === "Quartiles") && <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.thirdQuartile ? item.thirdQuartile : "-"}</td>}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                        </div>
                      </div>
                    </div>
                  )
                )
              )
            }
          </div>
        </section>
        <ModalFiltersReports
          show={showModalFiltersReports}
          handleClose={handleCloseFiltersReportsModal}
          title={selectedFilter}
          selectedItems={getSelectedItems(selectedFilter)}
          setSelectedItems={getSetSelectedItems(selectedFilter)}
          setApplyButton={setApplyButton}
          handlePerformanceReport={handlePerformanceReport}
          selectedAdvertisers={selectedAdvertisers}
        />

        <ModalCustomDate
          show={showModalCustomDate}
          handleClose={handleCloseCustomDateModal}
          setSelectedStartEndDate={setSelectedStartEndDate}
          setSelectedDateRange={setSelectedDateRange}
          setShowModalCustomDate={setShowModalCustomDate}
        />
      </div>
    </div>
  )
};

export default PerformanceReport;