import "./style.css";

const Button = (props) => {
  return (
    <button
      className={`btn btn-primary regularButton ${props.className}`}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  )
}

export default Button;