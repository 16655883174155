const rowReportsList = [
  'Advertiser',
  'Campaign',
  'Campaign Id',
  'Line Item',
  'Line Item Id',
  'Creative',
  'Creative Id',
  'Format',
  'Date',
  'Offer Type',
  'Inventory Type',
  'Country App',
  'Country Youtube',
  'Devices',
  'App Name',
  'Youtube Channel'
]

export default rowReportsList;