import React, { useEffect, useState } from 'react'
import Loader from '../Loader'
import { developedBy, CreativeSelector, getCreativeTypes } from '../../app/features/Creative/CreativeSlice';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-hot-toast";
const EditCreativesSaas = (props) => {
    const dispatch = useDispatch();
    const [loadershown, setloadershown] = useState(false);
    const [creative_data, setCreative_data] = useState([])
    const [creative_impressions, setCreative_impressions] = useState("")
    const [creative_clicks_tracking, setCreative_clicks_tracking] = useState("")
    const [creative_landing_url, setCreative_landing_url] = useState("")
    const [add_creative_name, setAdd_creative_name] = useState("")
    const [add_developer_by, setAdd_developed_by] = useState(null)
    const [add_creative_type, setAdd_creative_type] = useState(null)
    const [add_creative, setAdd_creative] = useState("")
    const [add_creative_file, setAdd_creative_file] = useState("")
    const [add_creative_tag, setAdd_creative_tag] = useState("")
    const [add_vast_tag, setAdd_vast_tag] = useState("")
    const [add_creative_link, setAdd_creative_link] = useState("")
    const [enableRead, setEnableread] = useState("")
    const [disableImpressionTracking, setDisableImpressionTracking] = useState(false)
    const [disabled_button,setDisabled_button] = useState(false)
    //these below variables have values of developer_by and creative type nitin 24/10/2023
    const {
        developed_by,
        creativeTypes
    } = useSelector(CreativeSelector);

    //this below function is used to get the all developed by and other things that needed in creative saas we use redux here because these endpoints are already created so we use previous ones nitin 24/10/2023
    useEffect(() => {
        dispatch(developedBy({ token: localStorage.getItem('token') }));
        if (props?.format_id) {
            dispatch(getCreativeTypes({ type_id: props.format_id, token: localStorage.getItem('token') }));
        }
    }, [])

    // this below function is used to get the details of the selected creative nitin 24/10/2023
    useEffect(() => {
        setloadershown(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManagerSaas/creative/${props?.selected_creative_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setCreative_data(result?.data);
                setAdd_developed_by(result?.data?.developedBy);
                setAdd_creative_type(result?.data?.creativeTypeId);
                setAdd_creative_name(result?.data?.name);
                setCreative_landing_url(result?.data?.urlLandingPage);
                setCreative_clicks_tracking(result?.data?.clicksTracking);
                setCreative_impressions(result?.data?.impressionTracking);
                setEnableread(result?.data?.enabled);
                result?.data?.creativeTypeId === 3 && setDisableImpressionTracking(true);
                setloadershown(false)
            })
            .catch(error => toast.error(error));
    }, [])

    useEffect(() => {
        if (add_creative_type === 6) setAdd_vast_tag(creative_data.object)
        if (add_creative_type === 3) setAdd_creative_link(creative_data.object)
        if (add_creative_type === 1 || add_creative_type === 2) setAdd_creative_file(creative_data.object)
        if (add_creative_type === 5) setAdd_creative_tag(creative_data.object)
        if (add_creative_type === 4) setAdd_creative(creative_data.object)
    }, [add_creative_type])

    // this below function is used to update the creative nitin 24/10/2023
    const handle_update_creative = (e) => {
        e.preventDefault()
        var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (add_creative_name === "") {
            toast.error("Creative name field can't be empty !!")
        } else if (format.test(add_creative_name)) {
            toast.error("Creative name must be without special chracters")
        }
        else if (add_developer_by === null) {
            toast.error("Please select developed_by !!")
        }
        else if (add_creative_type === null) {
            toast.error("Please select Creative type !!")
        }
        // else if (add_creative_type === 3 && add_creative_link === "") {
        //     toast.error("Please add creative link !!")
        // }
        // else if ((add_creative_type === 4 || add_creative_type === 6) && add_creative === "") {
        //     toast.error("Please add creative name !!")
        // }
        // else if ((add_creative_type === 1 || add_creative_type === 2) && add_creative_file === "") {
        //     toast.error("Please select file !!")
        // }
        // else if (add_creative_type === 5 && add_creative_tag === "") {
        //     toast.error("Creative tag field can't be empty !!")
        // }
        else if (!creative_landing_url.includes("https://")) {
            toast.error("The input must contain https://");
        }
        else {
            setloadershown(true)
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            var formdata = new FormData();
            formdata.append("name", add_creative_name);
            formdata.append("developedBy", add_developer_by);
            formdata.append("creativeTypeId", add_creative_type);
            formdata.append("impressionTracking", creative_impressions);
            formdata.append("clicksTracking", creative_clicks_tracking);
            formdata.append("lineItemId", props?.lineitem_id);
            formdata.append("urlLandingPage", creative_landing_url);
            formdata.append("formatId", props?.format_id);
            formdata.append("vastTags", add_vast_tag ? add_vast_tag : "");
            formdata.append("tags", add_creative_tag);
            formdata.append("link", add_creative_link);
            formdata.append("file", add_creative_file ? add_creative_file : "");
            formdata.append("designid", "");

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManagersaas/update-creative/${props?.selected_creative_id}`, requestOptions)
                .then(response => response.json())
                .then(result => {toast.success("Creative Updated successfully"); props?.setedit_creatives(false); setloadershown(false); props?.get_all_creatives() })
                .catch(error => { toast.error('Internal Error Occurred'); setloadershown(false) });
        }
    }

    const validate_length = (e) => {
		if(e.target.value?.length > 250){
			toast.error("You Cannot add more than 250 chracters in Landing Page !!!")
			setDisabled_button(true)
		}
		else{
			setDisabled_button(false)
		}
	}
    return (
        <div className=''>
            <Loader showLoader={loadershown} />


            <div className='row'>
                <div className="col-md-6 mb-4">
                    <div className="form-group  select_country_container cmn_modal_field">
                        <label htmlFor="creativeName" className='lbl_position'>Creative Name</label>
                        <input type="text" className='form-control' placeholder='Creative Name' name="name"
                            value={add_creative_name}
                            onChange={(e) => setAdd_creative_name(e.target.value)}
                            readOnly={enableRead == 1}
                        />

                    </div>
                    <p>
                    </p>
                </div>
                <div className="col-md-6 mb-4">
                    <div className="form-group  select_country_container cmn_modal_field" >
                        <label htmlFor="developedBy" className='lbl_position'>Developed By</label>

                        <select name="developedBy" className='form-control form-select'
                            value={add_developer_by}
                            onChange={(e) => setAdd_developed_by(e.target.value)}
                            disabled={enableRead == 1 ? "disabled" : null}
                        >
                            <option>Select</option>
                            {developed_by?.map((data, index) => {
                                return (
                                    <option key={index} value={data?.id}>{data?.name}</option>
                                )
                            })}
                        </select>

                    </div>
                </div>
                <div className='col-12'>
                    <div className="">
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <div className="form-group  select_country_container cmn_modal_field bg-secondary">
                                    <label htmlFor="developedBy" className='lbl_position text-white'>Creative Type</label>

                                    <select
                                        name="developedBy"
                                        className='form-control form-select bg-secondary text-white'
                                        value={add_creative_type}
                                        onChange={(e) => setAdd_creative_type(e.target.value)}
                                        disabled={true}
                                    >
                                        <option>Select</option>
                                        {creativeTypes?.map((data, index) => {
                                            return (
                                                <option key={index} value={data?.id}>{data?.description}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            {(add_creative_type == 4) && <div className="col-md-6 mb-4">
                                <div className="form-group  select_country_container cmn_modal_field">
                                  <label htmlFor="creative" className='lbl_position'>Creative</label>
                                  <input type="text" name="" className='form-control' placeholder='Creative'
                                    value={add_creative}
                                    onChange={(e) => setAdd_creative(e.target.value)}
                                    readOnly={true}
                                    disabled={true}
                                  />
                                </div>
                            </div>}

                            {(add_creative_type == 6) && <div className="col-md-6 mb-4">
                                <div className="form-group  select_country_container cmn_modal_field">
                                  <label htmlFor="creative" className='lbl_position'>Creative</label>
                                  <input type="text" name="" className='form-control' placeholder='Creative'
                                    value={add_vast_tag}
                                    onChange={(e) => setAdd_vast_tag(e.target.value)}
                                    readOnly={true}
                                    disabled={true}
																	/>
                                </div>
                            </div>}
                            {add_creative_type == 3 && <div className="col-md-6 mb-4">
                                <div className="form-group  select_country_container cmn_modal_field">
                                  <label htmlFor="creative" className='lbl_position'>Creative</label>
                                    <input type="text" name="" className='form-control' placeholder='Creative'
                                        value={add_creative_link}
                                        onChange={(e) => setAdd_creative_link(e.target.value)}
                                        readOnly={true}
                                        disabled={true}
																		/>
                                </div>
                            </div>}
                            {(add_creative_type == 1 || add_creative_type == 2) && <div className="col-md-6 mb-4">
                                <div className="form-group  select_country_container cmn_modal_field">
                                  <label htmlFor="creative" className='lbl_position'>Creative</label>
																	<div className="boxCreativeFile">
																		<label className="labelFileCreative">
																			{add_creative_file ?
																			(add_creative_file.length < 25 ? add_creative_file : add_creative_file.substring(0,25) + '...')
																			: 'File'}
																		</label>
																		<>
																			<input
																				id="creativeFile"
																				type="file"
																				name="file"
																				readOnly={true}
                                        disabled={true}
																				style={{ display: 'none' }}
                                        onChange={(e) => setAdd_creative_file(e.target.files[0])}
																			/>
																			<label className="labelChooseFileCreative" htmlFor="creativeFile">
																				Select File
																			</label>
																		</>
																	</div>

                                </div>
                                <p className='mb-0 mt-2'><strong>File: </strong>
                                {add_creative_file.length < 52 ?
                                    add_creative_file :
                                    add_creative_file.substring(0,40) + '...'
                                }</p>
                            </div>}
                            {add_creative_type == 5 && <div className="col-md-6 mb-4">
                                <div className="form-group  select_country_container cmn_modal_field">
                                    <label htmlFor="creative" className='lbl_position'>Creative</label>

                                    <textarea type="text" name="" className='form-control' placeholder='Creative'
                                        value={add_creative_tag}
                                        onChange={(e) => setAdd_creative_tag(e.target.value)}
                                        readOnly={true}
                                        disabled={true}
                                    />

                                </div>
                            </div>

                            }

                            <div className="col-md-6 mb-4">
                                <div className={"form-group  select_country_container cmn_modal_field " + (disableImpressionTracking && 'bg-secondary')}>
                                    <label htmlFor="creative" className={'lbl_position ' + (disableImpressionTracking && 'text-white')}>Impression Tracking</label>
                                    <input
                                        type="text"
                                        name="text"
                                        className={'form-control ' + (disableImpressionTracking && 'bg-secondary text-white placeholderWhite')}
                                        placeholder='Impression Tracking'
                                        value={creative_impressions}
                                        onChange={(e) => setCreative_impressions(e.target.value)}
                                        readOnly={enableRead == 1}
                                        disabled={disableImpressionTracking}
                                    />

                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="form-group  select_country_container cmn_modal_field">
                                    <label htmlFor="creative" className='lbl_position'>Click Tracking</label>
                                    <input type="text" name="text" className='form-control' placeholder='Click Tracking' value={creative_clicks_tracking} onChange={(e) => setCreative_clicks_tracking(e.target.value)} readOnly={enableRead == 1} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group  select_country_container cmn_modal_field">
                                    <label htmlFor="creative" className='lbl_position'>Landing Pages</label>
                                    <input type="text" name="text" className='form-control' placeholder='Landing Pages' onBlur={(e) => validate_length(e)} value={creative_landing_url} onChange={(e) => setCreative_landing_url(e.target.value)} readOnly={enableRead == 1} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-12 mt-4'>
                            <div className="d-flex justify-content-end mt-4"><button type="button" className="me-4 ms-auto cancel_cm_btn" >Cancel</button>
                                {/* this below function is used to store the new creative nitin 15/09/2023 */}
                                <button className="cmn_btn ms-2 " style={{ background: "#78797b" }} type="submit" disabled={enableRead == 1 ? "disabled" : null || disabled_button} onClick={(e) => handle_update_creative(e)}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCreativesSaas