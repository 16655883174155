import React, { useState, useEffect, useCallback, useMemo } from "react";

import { Table } from "react-bootstrap";

import { AiOutlineSearch } from "react-icons/ai";
import { FiAlertCircle, FiPlus } from 'react-icons/fi';

import Dropdown from "react-bootstrap/Dropdown";
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import Loader from "../Loader";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const AdminSaas = () => {
  const [loadershown, setloadershown] = useState(false);
  const [admin_saas_detail, setAdmin_saas_detail] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const [total_pages, setTotal_Pages] = useState(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [admin_saas_margin, setAdmin_saas_margin] = useState(0)
  const [deal_id, setDeal_id] = useState(null);
  const [deal_id_status, setDeal_id_status] = useState(null);
  const [statusmodalShow, setStatusModalShow] = useState(false);
  //setting up the advertiser_id
  const advert = JSON.parse(localStorage.getItem('userdata'));
  const path = location.pathname;
  const [show, setShow] = useState(false);
  const [agency_names, setAgency_names] = useState([])
  const [agency_name, setAgency_name] = useState("")
  const [searched_value, setSearched_value] = useState("")
  const [spinner, setSpinner] = useState(false)
  const [show_div, setShow_div] = useState(false)
  const [deal_names, setDeal_names] = useState([])
  const [deal_name, setDeal_name] = useState("")
  const [deal_status, setDeal_status] = useState(null)
  const [user_count, setUser_count] = useState(null)
  const [margin_saas, setMargin_saas] = useState(null)
  const [selected_status_id, setSelected_status_id] = useState(null)
  const permission = JSON.parse(localStorage.getItem('userdata'));
  const [searched_deal_id, setSearched_deal_id] = useState('')
  const [enableSearchButton, setEnableSearchButton] = useState(false)
  const [enableClearButton, setEnableClearButton] = useState(false)

  useEffect(() => {
    if (permission?.role === 2 || permission?.role === 3) {
      navigate("/home")
    }
  }, [])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowStatus = () => setStatusModalShow(true);
  const handleStatusClose = () => setStatusModalShow(false);
  //this function is used to get all the admin saas details nitin 04/09/2023

  //this is for pagination
  useEffect(() => {
    if (page !== 1 || pageSize !== 10) {
      get_all_admin_saas()
    }
  }, [page, pageSize])

  useEffect(() => {
    get_all_admin_saas();
  }, [])
  const get_all_admin_saas = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/getdeals?page=${page}&pageSize=${pageSize}`, requestOptions)
      .then(response => response.json())
      .then(result => { setAdmin_saas_detail(result?.data?.results); setloadershown(false); setTotal_Pages(result?.data?.page_count) })
      .catch(error => { toast.error('Internal Server error occurred !'); setloadershown(false) });
  }

  //this function is used to get to the userlist page here we are sending the advertiser id from current page and we get the advertiser_id from the localStorage
  const go_to_next = (deal_name, agencyName, advertiser_ID) => {
    navigate("/userlist", {
      state: {
        advertiser_id: advertiser_ID,
        DealName: deal_name,
        Agency_Name: agencyName,
        path: path,
        admin_value: true
      },
    });
  }
  const handleStatusUpdate = (status_id, id, deal_status, count, margin_saas) => {
    handleShowStatus()
    setDeal_id(id)
    setDeal_id_status(status_id)
    setDeal_status(deal_status)
    setUser_count(count)
    setMargin_saas(margin_saas)
  }
  //this below function is used to update the status of admin nitin 04/09/2023
  const handle_update_admin_status = () => {
    if (deal_id_status == 3) {
      toast.error("You cannot update the status to New !!")
    }
    else {
      handleStatusClose()
      setloadershown(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "id": deal_id,
        "status": deal_id_status
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/dealstatus`, requestOptions)
        .then(response => response.json())
        .then(result => get_all_admin_saas())
        .catch(error => { toast.error('Internal Error occurred !!'); setloadershown(false) });
    }

  }

  // this below function is used to get the details of selected deal margin saas nitin 27/09/2023
  const handle_show_edit_pop_up = (id) => {
    setDeal_id(id)
    handleShow();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "id": id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/getSaasMargin`, requestOptions)
      .then(response => response.json())
      .then(result => { setAdmin_saas_margin(result?.data?.saas_margin) })
      .catch(error => toast.error(error));
  }

  // this below function is used to update the margin of admin saas nitin 27/09/2023
  const handle_update_saas_margin = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "id": deal_id,
      "saas_margin": admin_saas_margin
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/UpdateSaasMargin`, requestOptions)
      .then(response => response.json())
      .then(result => { handleClose(); get_all_admin_saas() })
      .catch(error => console.log('error', error));
  }

  // this below function is used to get all the agency names nitin 28/09/2023
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/getagencyName`, requestOptions)
      .then(response => response.json())
      .then(result => { setAgency_names(result?.data) })
      .catch(error => toast.error(error));
  }, [])

  const getFilteredData = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    var url = `${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/getdeals?page=${page}&pageSize=${pageSize}&dealName=${deal_name}`

    if (selected_status_id) {
      url += `&statusId=${selected_status_id}`;
    }
    if (searched_deal_id) {
      url += `&dealId=${searched_deal_id}`;
    }
    if (agency_name) {
      url += `&agencyName=${agency_name}`;
    }

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        setAdmin_saas_detail(result?.data?.results);
        setloadershown(false);
        setTotal_Pages(result?.data?.page_count)
      })
      .catch(error => { toast.error('Internal Server error occurred !'); setloadershown(false) });
  }

  const handle_search_by_deal = (e) => {
    if (!/[a-zA-Z]/.test(e.target.value)) {
      setShow_div(false);
      setDeal_names([]);
    }
    if (/[a-zA-Z]/.test(e.target.value)) {
      setShow_div(true)
      setSpinner(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "deal_name": e.target.value
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/getDealName`, requestOptions)
        .then(response => response.json())
        .then(result => { setDeal_names(result?.data); setSpinner(false) })
        .catch(error => toast.error(error));

    }
  }

  const handleSearchFilters = () => {
    getFilteredData();
    setEnableSearchButton(false);
    setEnableClearButton(true);
  }

  const handleClearFilters = () => {
    setAgency_name("");
    setSearched_value("");
    setDeal_name("");
    setEnableSearchButton(false);
    setEnableClearButton(false);
    get_all_admin_saas()
  }

  return (
    <>
      <div className="content_outer adminSaas">
        <Loader showLoader={loadershown} />
        <div className="cmn_header_top campaign__header mb-3">

          <div className="row w-100">
            <div className="col-lg-10 m-auto">
              <h3>Admin SaaS</h3>
              <div className="flex-grow-1 text-center">
                <div className="cmn_header__search">
                  <AiOutlineSearch size={24} className={searched_value.length > 0 ? "changeIcon" : ""} />
                  <input
                    type="text"
                    value={searched_value}
                    placeholder="Search By Deal Name"
                    onChange={(e) => {
                      handle_search_by_deal(e);
                      if (!isNaN(e.target.value)) {
                        setSearched_deal_id(e.target.value);
                        setSearched_value(e.target.value)
                      } else {
                        setDeal_name(e.target.value);
                        setSearched_value(e.target.value)
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setDeal_name(e.target.value);
                        setSearched_value(e.target.value)
                        setShow_div(false);
                        if (e.target.value == "") {
                          get_all_admin_saas();
                        } else {
                          getFilteredData();
                        }
                      }
                    }}
                    onClick={() => setShow_div(false)}
                  />
                  {show_div && <div className="search_dropown ">
                    {spinner &&
                      <div className="p-3 text-center">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>

                      </div>
                    }
                    <ul>
                      {
                        deal_names?.map((data, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => {
                                setDeal_name(data?.deal_name);
                                setSearched_value(data?.deal_name);
                                setShow_div(false);
                                getFilteredData();
                              }}>
                              {data?.deal_name}
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content pt-0">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="cmn__filters campiagn_filter filter_menu w-100">
                <ul>
                  <li><img src="/assets/images/filter_list.svg" /></li>
                  <li>
                    <Dropdown className="owner_filter">
                      {/* first */}
                      <Dropdown.Toggle id="dropdown-basic">
                        {agency_name ? agency_name : "Status"}
                        <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                          <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { setSelected_status_id(1); setAgency_name("Active"); setEnableSearchButton(true) }}>Active</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setSelected_status_id(2); setAgency_name("Inactive"); setEnableSearchButton(true) }}>Inactive</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setSelected_status_id(3); setAgency_name("New"); setEnableSearchButton(true) }}>New</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setSelected_status_id(4); setAgency_name("Pause"); setEnableSearchButton(true) }}>Pause</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <button
                    className={enableSearchButton ? "clear__btn purple_bg" : "clear__btn"}
                    onClick={handleSearchFilters}
                    disabled={!enableSearchButton}
                  >
                    Search
                  </button>
                  <button
                    className={enableClearButton ? "clear__btn1" : "clear__btn"}
                    onClick={handleClearFilters}
                    disabled={!enableClearButton}
                  >
                    Clear
                  </button>
                </ul>
              </div>
              <div className="App">
                <div className="cmn__filters campiagn_filter filter_menu w-100">
                </div>
                <div className="advert_tabel campaign_showlist campign_index_table">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-start">ID</th>
                        <th className="text-start">Deal</th>
                        <th className="text-start">Agency Name</th>
                        <th className="text-start">Currency</th>
                        <th className="text-start">Status</th>
                        <th className="text-start">Saas Type</th>
                        <th className="text-start">Users</th>
                        <th className="text-start">Agency Fee</th>
                        <th className="text-start">Tech Fee</th>
                        <th className="text-start">Service Fee</th>
                        <th className="text-center">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {admin_saas_detail?.map((admin_detail, index) => {
                        return (
                          <tr key={index}>
                            <td>{admin_detail?.id}</td>
                            <td>{admin_detail?.campaign}</td>
                            <td>{admin_detail?.agency_name}</td>
                            <td>{admin_detail?.currency_code}</td>
                            <td>
                              <Dropdown className="status_dropdown media_plan_dropdown status_design">
                                <Dropdown.Toggle className={`filter_btn`}>
                                  {admin_detail?.status === 1 && <img src="/assets/images/check_circle.svg" />}
                                  {admin_detail?.status === 1 && <span> Active</span>}
                                  {admin_detail?.status === 2 && <img src="/assets/images/cancel_FILL.svg" />}
                                  {admin_detail?.status === 2 && <span> Inactive</span>}
                                  {admin_detail?.status === 3 && <img src="/assets/images/draft_FILL.svg" />}
                                  {admin_detail?.status === 3 && <span> New</span>}
                                  {admin_detail?.status === 4 && <img src="/assets/images/pause_circle.svg" />}
                                  {admin_detail?.status === 4 && <span> Pause</span>}
                                  <FaChevronRight className="ms-auto" />
                                </Dropdown.Toggle>
                                {Object.values(permission.Permissions).includes('editAdminSaas') && <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => { handleStatusUpdate(3, admin_detail?.id, admin_detail?.status, admin_detail?.userCount, admin_detail?.saas_margin) }}>
                                    <img src="/assets/images/draft_FILL.svg" />
                                    New
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => {
                                    if (admin_detail?.saas_margin > 0) {
                                      handleStatusUpdate(1, admin_detail?.id, admin_detail?.status, admin_detail?.userCount, admin_detail?.saas_margin)
                                    } else {
                                      toast.error("You need to assign a Margin Saas to active the deal");
                                    }
                                  }}>
                                    <img src="/assets/images/check_circle.svg" />
                                    Active
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => { handleStatusUpdate(4, admin_detail?.id, admin_detail?.status, admin_detail?.userCount, admin_detail?.saas_margin) }}>
                                    <img src="/assets/images/pause_circle.svg" />
                                    Pause
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => { handleStatusUpdate(2, admin_detail?.id, admin_detail?.status, admin_detail?.userCount, admin_detail?.saas_margin) }}>
                                    <img src="/assets/images/cancel_FILL.svg" />
                                    Inactive
                                  </Dropdown.Item>
                                </Dropdown.Menu>}
                              </Dropdown>
                            </td>
                            {admin_detail?.service_type_id === 2 && <td>Agency</td>}
                            {admin_detail?.service_type_id === 3 && <td>Direct</td>}
                            {admin_detail?.service_type_id === 4 && <td>Managed</td>}
                            <td>{admin_detail?.userCount}</td>

                            <td>{admin_detail?.Kickback ? admin_detail?.Kickback : 0}</td>
                            <td>{admin_detail?.saas_margin ? admin_detail?.saas_margin : 0}</td>
                            <td>
                              <div className="pdf_icon">
                                <FiPlus onClick={() => navigate("/adminSaas/clientServiceFee", { state: { dealId: admin_detail?.id, dealName: admin_detail?.campaign } })} />
                              </div>
                            </td>
                            {/* <td>{admin_detail?.saas_margin}</td> */}


                            <td>
                              <div className="icons_downloads justify-content-center">
                                {Object.values(permission.Permissions).includes('editAdminSaas') && <div className="pdf_icon">
                                  <img src="/assets/images/lock_reset.svg" title="User List" onClick={() => go_to_next(admin_detail?.campaign, admin_detail?.agency_name, admin_detail?.advertiser_id)} />
                                </div>}
                                {<div className="pdf_icon">
                                  <img src="/assets/images/description_file.svg" title="View Campaign Manager Saas" onClick={() => navigate('/campaignsaas', {
                                    state: {
                                      client_id: admin_detail?.advertiser_id,
                                      path: path,
                                      currency: admin_detail?.currency_code,
                                      check_calue: true,
                                      client_deal_id: admin_detail?.id
                                    }
                                  })} />
                                </div>}
                                {Object.values(permission.Permissions).includes('editAdminSaas') && <div className="pdf_icon" title="Edit Margin Saas" onClick={() => { handle_show_edit_pop_up(admin_detail?.id) }}>
                                  <img src="/assets/images/edit_fill.svg" />
                                </div>}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  <div className="cmn__pagination">
                    <ul>
                      <span>Rows per page</span>
                      <li className="no__rows">
                        <select name="" id="" onChange={(e) => setPageSize(e.target.value)}>
                          <option>Select</option>
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={15}>15</option>
                          <option value={20}>20</option>
                        </select>
                      </li>
                      <li className="total_pages me-3">
                        <span className="active_pages"></span>{page} of <span>{total_pages}</span>
                      </li>
                      {total_pages !== 1 && <li>
                        <BsChevronBarLeft
                          onClick={() => setTotal_Pages(1)}
                        />
                      </li>}
                      {total_pages !== 1 && <li>
                        <BsChevronLeft
                          onClick={() => setPage(page - 1)}
                        />
                      </li>}
                      {page !== total_pages && <li>
                        <BsChevronRight
                          onClick={() => setPage(page + 1)}
                        />
                      </li>}
                      {page !== total_pages && <li>
                        <BsChevronBarRight
                          onClick={() => setPage(total_pages)}
                        />
                      </li>}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Edit Tech Fee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content pt-0">

            <div className="form-group select_country_container cmn_modal_field mb-3">
              <label className="lbl_position " for="campaignName">Tech Fee</label>
              <input type="number" className="form-control" name="name" value={admin_saas_margin} onChange={(e) => setAdmin_saas_margin(e.target.value)} />
            </div>
            <div className="d-flex justify-content-end pt-4">
              <button className="ms-auto me-4 cancel_cm_btn" onClick={handleClose}>Cancel</button>
              <button className="cmn_btn ms-2" onClick={() => handle_update_saas_margin()}>Save</button>
            </div>
          </div>
        </Modal.Body>

      </Modal>
      <Modal
        show={statusmodalShow}
        onHide={() => {
          setStatusModalShow(false);

        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="model_content">
            <div className='text-center mb-4'>
              <FiAlertCircle className='alert_icon' size={64} />
            </div>
            {deal_id_status == 1 && <h4 className="text-center border-bottom pb-5">Are you Sure you want to Active this Deal ?</h4>}
            {deal_id_status == 2 && <h4 className="text-center border-bottom pb-5">Are you Sure you want to Inactive this Deal ?</h4>}
            {deal_id_status == 3 && <h4 className="text-center border-bottom pb-5">Are you Sure you want to Change this Deal to New ?</h4>}
            {deal_id_status == 4 && <h4 className="text-center border-bottom pb-5">Are you Sure you want to Pause this Deal ?</h4>}
            <div className="d-flex justify-content-end mt-5">
              <button
                className="me-4 cancel_cm_btn"
                onClick={() => setStatusModalShow(false)}
              >
                Cancel
              </button>
              <button
                className="cmn_btn ms-2 "
                onClick={() => handle_update_admin_status()}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminSaas;

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
