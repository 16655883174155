import React, { useState, useEffect } from "react";
import "./campaign.css";
import { Row, Col, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronRight } from "react-icons/fa";
import { BsPencil } from "react-icons/bs";
import {
	AiOutlineSearch
} from "react-icons/ai";
import { FiAlertCircle } from 'react-icons/fi';


import {
	AiOutlineBars,
	AiOutlineLeft,
	AiOutlineCheckCircle
} from "react-icons/ai";
import { HiPencil } from "react-icons/hi";
import { MdOutlineHandshake } from "react-icons/md";
import { BiCalendar } from "react-icons/bi";
import { FiEdit, FiGrid } from "react-icons/fi";

import { toast } from "react-hot-toast";
import Moment from "react-moment";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import {
	clearState,
	CreativeSelector,
	getLineItem,
	listCreative,
	CreativeOnOff,
} from "../../app/features/Creative/CreativeSlice";
import {
	campaignManagerSelector,
	deal_currency,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import {
	CommonSelector,
	updateBreadCrumb,
} from "../../app/features/Common/CommonSlice";
import {
	BsChevronLeft,
	BsChevronRight,
	BsChevronBarLeft,
	BsChevronBarRight,
} from "react-icons/bs";
import NewCreatives from "./newCreatives";
import NewLineItem from "./newLineItem";
import Loader from "../Loader";
import EditLineItems from "./editLineItems";
import Spinner from 'react-bootstrap/Spinner';


const Creatives = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const [page, setPage] = useState(1);
	const [creativeID, setCreativeID] = useState()
	const [creativeStatus, setCreativeStatus] = useState()

	const [statusmodalShow, setStatusModalShow] = useState(false);
	const [creativemodalShow, setCreativeModalShow] = useState(false);
	const [modaleditShow, setEditLineItemModal] = useState(false);
	const [loadershown, setloadershown] = useState(false);
	const [filteredCreatives, setFilteredCreatives] = useState([])
	const [asc, setAsc] = useState(false)
	const [nameCreative, setNameCreative] = useState()
	const [idCreative, setIdCreative] = useState()
	const [pageNo, setPageNo] = useState(1)
	const [pageLimit, setPageLimit] = useState(10)
	const [page_Count, setPage_count] = useState(0)
	const [current_Page, setCurrent_Page] = useState(1)
	const [creative_list, setcreatives_list] = useState([])
	const [spinner, setSpinner] = useState(false)
	const [show_div, setShow_div] = useState(false)

	const [creative_edit_modal_Show, setCreative_edit_modal_show] = useState(false);
	const [showError, setShowError] = useState(false);
	const [id, setId] = useState();
	const [statusCreative, setStatusCreative] = useState(false)
	const [showtable, setShowtable] = useState(true)
	const [searchValue, setSearchValue] = useState("")
	const [showVideocard, setShowVideocard] = useState(false)
	const [text, setText] = useState("")
	const show_table = () => {
		setShowtable(!showtable)
		setShowVideocard(false)
	}
	const show_videocard = () => {
		setShowVideocard(!showVideocard)
		setShowtable(false)
	}
	const [statusmodel, SetStatusModel] = useState({
		messageText: "",
		buttonText: "",
		buttonClass: "",
		creativeId: "",
		creativeStatus: 0,
	});

	const {
		campaignId,
		lineItem,
		advertiser,
		campaignName,
		deal_id,
		clientId,
		total_Budget,
		sum_Budget,
		startDate,
		endDate,
		status_id,
		lineitem_id,
		lineitem_name,
		lineitem_inventory,
		lineitem_start_date,
		lineitem_end_date,
		lineitem_format,
		lineitem_offer,
		lineitem_country,
		lineitem_status,
		currency_code
	} = location.state ? location.state : location;
	const {
		list_creative,
		line_Item,
		isSuccesslistCreative,
		isErrorlistCreative,
	} = useSelector(CreativeSelector);
	const { dealCurrency } = useSelector(campaignManagerSelector);
	useEffect(() => {
		if (dealCurrency == "" && line_Item) {
			dispatch(
				deal_currency({
					token: localStorage.getItem("token"),
					dealId: line_Item?.deal_id,
				})
			);
		}
	}, [line_Item]);
	const [filter, setFilter] = useState({
		name: {
			name: "Name",
			type: "text",
			values: "",
			shown: true,
			multiselect: false,
		},
		id: {
			name: "Id",
			type: "number",
			values: "",
			shown: true,
			multiselect: false,
		},
	});
	const [selectedfilter, SetSelectedfilter] = useState({
		name: {
			name: "",
			value: "",
			key: "name",
		},
		id: {
			name: "",
			value: "",
			key: "id",
		},
	});
	const [filtervalue, setFilterValue] = useState({
		name: "",
		id: "",
	});

	useEffect(() => {
		getLine_Item();
	}, []);

	useEffect(() => {
		setFilteredCreatives(list_creative?.results)
		setCurrent_Page(list_creative?.current_page)
		setPage_count(list_creative?.page_count)
	}, [list_creative?.results])

	useEffect(() => {
		searchFilter();
		if (Object.keys(line_Item)?.length > 0) {
			dispatch(
				updateBreadCrumb([
					{
						name: advertiser,
						params: { dealid: line_Item?.deal_id, deal_id: deal_id },
						path: "campaign/mediaplan",
					},
					{
						name: campaignName,
						params: {
							dealid: line_Item?.deal_id,
							deal_id: deal_id,
							campaignId: campaignId,
							advertiser: advertiser,
							clientId: clientId,
						},
						path: "campaign/lineitems",
					},
					{
						name: line_Item?.name,
						params: {
							dealid: line_Item?.deal_id,
							deal_id: deal_id,
							campaignId: campaignId,
							advertiser: advertiser,
							clientId: clientId,
						},
						path: "campaign/creatives",
					},
				])
			);
		}
	}, [line_Item]);

	useEffect(() => {
		searchFilter();
	}, [page]);

	const getLine_Item = () => {
		dispatch(
			getLineItem({
				lineitem_id: lineItem,
				token: localStorage.getItem("token"),
			})
		).then((response) => {
			setloadershown(false);
		});
	};

	const searchFilter = () => {
		let posted_array = Object.assign({}, filtervalue);

		Object.keys(posted_array).forEach((key) => {
			if (
				posted_array[key] === "" ||
				(typeof posted_array[key] === "object" && posted_array[key]?.length == 0)
			) {
				delete posted_array[key];
			}
		});
		const u = new URLSearchParams(posted_array).toString();
	};

	const StatusPopup = (id, status) => {
		if (status == 0) {
			setText("Are you sure you want to OFF the Creative")
		}
		if (status == 1) {
			setText("Are you sure you want to ON the Creative")

		}
		if (status_id !== "ACTIVE") {
			setStatusCreative(true)
			setCreativeID(id)
			setCreativeStatus(status)
		} else {
			switch (status) {
				case 1:
					SetStatusModel({
						...statusmodel, // Copy the old fields
						messageText: "Do you want to confirm the status change for the selected IDs",
						buttonText: "Confirm",
						buttonClass: "cmn_btn ms-2 ",
						creativeId: id,
						creativeStatus: status,
					});
					setStatusModalShow(true);
					break;
				case 0:
					SetStatusModel({
						...statusmodel, // Copy the old fields
						messageText: "Do you want to confirm the status change for the selected IDs",
						buttonText: "Confirm",
						buttonClass: "cmn_btn ms-2",
						creativeId: id,
						creativeStatus: status,
					});
					setStatusModalShow(true);
					break;
			}
		}
	};

	const resetstatus = () => {
		SetStatusModel({
			...statusmodel, // Copy the old fields
			messageText: "",
			buttonText: "",
			buttonClass: "",
			creativeId: "",
			creativeStatus: "",
		});
	};

	const updateStatus = () => {
		setStatusModalShow(false);
		setloadershown(true);
		dispatch(
			CreativeOnOff({
				token: localStorage.getItem("token"),
				id: statusmodel.creativeId,
			})
		).then(() => {
			getSearchedData();
		});
	};
	const updatestatus = () => {
		setloadershown(true);
		dispatch(
			CreativeOnOff({
				token: localStorage.getItem("token"),
				id: creativeID,
			})
		).then(() => {
			getSearchedData();
		});
	}
	const reset_filter = () => {
		let posted_array = Object.assign({}, filtervalue);
		Object.keys(posted_array).forEach((key) => {
			if (
				posted_array[key] !== "" ||
				(typeof posted_array[key] === "object" && posted_array[key]?.length > 0)
			) {
				if (typeof posted_array[key] === "object") {
					posted_array[key] = [];
					SetSelectedfilter((prev_val) => ({
						...prev_val,
						[key]: { ...selectedfilter[key], value: [] },
					}));
				} else {
					posted_array[key] = "";
					SetSelectedfilter((prev_val) => ({
						...prev_val,
						[key]: { ...selectedfilter[key], value: "" },
					}));
				}
			}
		});
		setFilterValue(posted_array);
	};

	const editLineItem = () => {
		setEditLineItemModal(true);
	};

	const handle_Edit_Crative = (id) => {
		setId(id);
		setShowError(true);
		setCreative_edit_modal_show(true);
	};

	const handleSort = (field) => {
		if (line_Item) {
			setloadershown(true)
			setAsc(!asc)
			let assc;
			if (asc) {
				assc = "asc"
			} else {
				assc = "desc"
			}

			var myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

			var requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};

			fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/creatives/${line_Item?.id}?page=1&FieldName=${field}&Order=${assc}`, requestOptions)
				.then(response => response.json())
				.then(result => { setFilteredCreatives(result?.data?.results); setloadershown(false) })
				.catch(error => { toast.error(error); setloadershown(false) });
		}

	}

	const handleSearchByIdAndName = (e) => {
		if (/[a-zA-Z]/.test(e.target.value)) {
		} else {
			setNameCreative()
			setIdCreative(e.target.value)
			setSearchValue(e.target.value)
		}
	}

	useEffect(() => {
		getSearchedData()
	}, [nameCreative, idCreative, pageNo, pageLimit])

	const getSearchedData = () => {
		if (line_Item) {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

			var requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};

			let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/creatives/${lineItem}?page=${pageNo}&pageSize=${pageLimit}`;

			if (nameCreative) {
				url += `&name=${nameCreative}`;
			}
			if (idCreative) {
				url += `&id=${idCreative}`;
			}
			fetch(url, requestOptions)
				.then(response => response.json())
				.then(result => {
					setFilteredCreatives(result?.data?.results);
					setCurrent_Page(result?.data?.current_page);
					setPage_count(result?.data?.page_count)
					setloadershown(false)

				})
				.catch(error => toast.error('internal server error occured'));
		}
	}

	const handleStatusUpdate = () => {
		setStatusCreative(false);
		setloadershown(true);
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

		var raw = JSON.stringify({
			"id": creativeID,
			"Enabled": creativeStatus
		});

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/creative_status`, requestOptions)
			.then(response => response.json())
			.then(result => { updatestatus(); getLine_Item() })
			.catch(error => toast.error(error));
	}

	const permission = JSON.parse(localStorage.getItem('userdata'));

	const handle_search_creatives_name = (e) => {
		if (/[a-zA-Z]/.test(e.target.value)) {
		} else {
			setNameCreative()
			setIdCreative(e.target.value)
			setSearchValue(e.target.value)
		}
		if (!/[a-zA-Z]/.test(e.target.value)) {
			setShow_div(false);
			setcreatives_list([]);
		}
		if (/[a-zA-Z]/.test(e.target.value)) {
			setNameCreative(e.target.value)
			setSpinner(true)
			setShow_div(true)
			setSearchValue(e.target.value)
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

			var raw = JSON.stringify({
				"name": e.target.value,
				"lineitem_id": line_Item?.id,
			});

			var requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			};

			fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManager/filter-creative`, requestOptions)
				.then(response => response.json())
				.then(result => { setcreatives_list(result?.data); setSpinner(false) })
				.catch(error => toast.error(error));
		}
	}

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Enter') {
				getSearchedData();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [getSearchedData]);

	return (
		<div className="content_outer">
			<Loader showLoader={loadershown} />
			<div className="cmn_header_top">
				<h3>
					<img src="/assets/images/chevron_left.svg" onClick={() => navigation("/campaign/lineitems", {
						state: {
							campaignId,
							lineItem,
							advertiser,
							campaignName,
							dealid: line_Item?.deal_id,
							deal_id,
							clientId,
							total_Budget,
							sum_Budget,
							startDate,
							endDate,
							status_id,
							lineitem_id,
							lineitem_name,
							lineitem_inventory,
							lineitem_start_date,
							lineitem_end_date,
							lineitem_format,
							lineitem_offer,
							lineitem_country,
							lineitem_status,
							filterValue: true,
							currency_code,
							fromCreatives: true
						}
					})} />
					Campaign Manager</h3>
				<div className="cmn_header__search position-relative">
					<AiOutlineSearch size={24} className={searchValue.length > 0 ? "changeIcon" : ""} />	<input type="text" value={searchValue} placeholder="Search by Id or Name" onBlur={(e) => {
						handleSearchByIdAndName(e);
						if (!isNaN(e.target.value)) {
							setIdCreative(e.target.value);
						} else {
							setNameCreative(e.target.value);
						}
					}} onChange={(e) => {
						handle_search_creatives_name(e);
						setSearchValue(e.target.value);
						if (!isNaN(e.target.value)) {
							setIdCreative(e.target.value);
						} else {
							setNameCreative(e.target.value);
						}
					}} />
					{show_div && <div className="search_dropown">
						{spinner &&
							<div className="p-3 text-center">
								<Spinner animation="border" role="status">
									<span className="visually-hidden">Loading...</span>
								</Spinner>

							</div>
						}
						<ul>
							{
								creative_list?.map((data, index) => {
									return (
										<li key={index} onClick={() => { setNameCreative(data?.name); setSearchValue(data?.name); setShow_div(false); setSearchValue(data?.name) }}>{data?.name}</li>
									)
								})
							}
						</ul>
					</div>}
				</div>
				{Object.values(permission.Permissions).includes('editcampaign') && <div className="">
					<div className="newcampaign ">
						<button
							className="cmn_btn ms-0"
							onClick={() => {
								setCreativeModalShow(true);
								reset_filter();
							}}
						>
							{" "}
							<FontAwesomeIcon className="me-2" icon={faPlus} />
							New Creative
						</button>
					</div>

				</div>}

			</div>
			<div className="content pt-0">

				<div className="title__active">
					<h3> {line_Item?.name}</h3>
					<div className="status_badge">
						{status_id == "ACTIVE" && <p><AiOutlineCheckCircle size={24} className="me-2" />{line_Item?.lineItemStatusNavigation?.description}</p>}
						{status_id == "INACTIVE" && <p><AiOutlineCheckCircle size={24} className="me-2" />{line_Item?.lineItemStatusNavigation?.description}</p>}
						{status_id == "PAUSE" && <p><AiOutlineCheckCircle size={24} className="me-2" />{line_Item?.lineItemStatusNavigation?.description}</p>}
						{status_id == "DRAFT" && <p><AiOutlineCheckCircle size={24} className="me-2" />{line_Item?.lineItemStatusNavigation?.description}</p>}
					</div>
				</div>
				<div className="mediaplan_details kite_space_cmn">
					<div className="advert_tabel ">
						<div className="media_plan_info_list">
							<ul className="header__media">
								<li>
									<MdOutlineHandshake size={24} /> 	<strong>LineItem Id</strong>:
									<p> {line_Item?.id}</p>
								</li>
								<li>
									<BiCalendar size={24} />  	<strong>Start Date:</strong>
									<p>
										{""}
										<Moment format="DD/MM/YYYY">{line_Item?.date_from}</Moment>
									</p>
								</li>
								<li>
									<strong>End Date:</strong>
									<p>
										{""}
										<Moment format="DD/MM/YYYY">{line_Item?.date_to}</Moment>
									</p>
								</li>
							</ul>
							<ul>
								<li>
									<strong>Inventory Type</strong>:
									<p> {line_Item?.inventoryNavigation?.description}</p>
								</li>
								<li>
									<strong>Offer type</strong>:
									<p>{line_Item?.offerTypeNavigation?.descripcion}</p>
								</li>
								<li>
									<strong>Country</strong>:
									<div className="countries_list">
										{line_Item?.countryList?.map((data, index) => {
											return <p key={index}>{`${data.country.ab?.toUpperCase()},`}</p>;
										})}
									</div>
								</li>

								<li>
									<strong>Format</strong>:
									<p> {line_Item?.formatNavigation?.name}</p>
								</li>
								<li>
									<strong>Goal</strong>:<p> {line_Item?.inversion_goal?.toLocaleString()}</p>
								</li>
								<li>
									<strong>Audience </strong>:
									<p>
										{" "}
										{line_Item?.audienceList?.map((data) => {
											return `${data.audience.audience},`;
										})}
									</p>
								</li>
								<li>
									<strong>Gender</strong>:
									{line_Item?.targetgender_id == 4 && <p>Boys</p>}
									{line_Item?.targetgender_id == 5 && <p>Girls</p>}
									{line_Item?.targetgender_id == 6 && <p>Both</p>}
								</li>
								<li>
									<strong>Status</strong>:
									<p>{line_Item?.lineItemStatusNavigation?.description}</p>
								</li>
								{line_Item?.white_list && (
									<li>
										<strong>White List</strong>:
										<p>
											<a href={line_Item?.white_list} target="blank">
												Link
											</a>
										</p>
									</li>
								)}
								{Object.values(permission.Permissions).includes('editcampaign') && <li>
									<div className="pdf_icon " onClick={editLineItem}><img src="/assets/images/edit_fill.svg" /></div>
								</li>}

							</ul>
						</div>
					</div>
				</div>

				<div className="new_compaign">
					<div className="campiagn_outer border-0 p-0">
						<div className="toggle_navgations text-end">
							<FiGrid size={20} className="me-4" onClick={show_videocard} />
							<AiOutlineBars size={20} onClick={show_table} />
						</div>
						{showtable &&
							<div className="advert_tabel campign_index_table">
								<div className="table-responsive-sm">
									<Table
										responsive
										className={
											filteredCreatives?.length === 0 ? "no_data" : null
										}
									>
										<thead>
											<tr>
												<th onClick={() => handleSort('id')} className="text-start">Creative Id </th>
												<th onClick={() => handleSort('format_id')} className="text-start">Format </th>
												<th onClick={() => handleSort('name')} className="text-start">Creative Name</th>
												<th className="text-start">Status</th>
												<th className="text-start">Status Approval</th>
												<th className="text-start">Landing Page</th>
												<th className="text-start"></th>
											</tr>
										</thead>
										<tbody>
											{filteredCreatives?.length === 0 ? (
												<img
													className="nodata"
													src="/assets/images/notfound.svg"
												/>
											) : (
												filteredCreatives?.map((creative, index) => {
													return (
														<tr key={index}>
															<td className="text-start">{creative?.id}</td>
															<td className="text-start">
																{creative?.formatNavigation?.name}
															</td>
															<td className="text-start">{creative?.name}</td>
															<td className="text-start">
																<div className="form-check form-switch ps-0">
																	<Dropdown className="status_dropdown media_plan_dropdown status_design on_off">
																		<Dropdown.Toggle className={creative.enabled == 0 ? "filter_btn off" : "filter_btn on"}>

																			<span>{creative.enabled == 0 ? "Off" : "On"}</span>
																			<FaChevronRight className="ms-auto" />
																		</Dropdown.Toggle>
																		{Object.values(permission.Permissions).includes('editcampaign') && <Dropdown.Menu>
																			<Dropdown.Item className="on"
																				onClick={() => {
																					StatusPopup(creative?.id, 1);
																				}}
																			>
																				<img src="/assets/images/check_circle.svg" />	On
																			</Dropdown.Item>

																			<Dropdown.Item className="off"
																				onClick={() => {
																					StatusPopup(creative?.id, 0);
																				}}
																			>
																				<img src="/assets/images/cancel_FILL.svg" />	Off
																			</Dropdown.Item>
																		</Dropdown.Menu>}
																	</Dropdown>
																</div>
															</td>
															<td className="text-start">{creative?.approvalStatusText}</td>
															<td className="text-start">
																<Link to={creative?.urlLandingPage} target="blank">
																	Visit Link
																</Link>
															</td>
															{Object.values(permission.Permissions).includes('editcampaign') && <td
																className="text-start"
																onClick={() => {
																	handle_Edit_Crative(creative?.id);
																}}
															>
																<FiEdit />
															</td>}
														</tr>
													);
												})
											)}
										</tbody>
									</Table>
									{filteredCreatives?.length != 0 &&
										<div className="cmn__pagination">
											<ul>
												<span>Rows per page</span>
												<li className="no__rows">
													<select
														name=""
														id=""
														onChange={(e) => setPageLimit(e.target.value)}

													>
														{/* <option value="5">5</option> */}
														<option value="10">10</option>
														<option value="15">15</option>
														<option value="20">20</option>
													</select>
												</li>
												<li className="total_pages me-3">
													<span className="active_pages">{current_Page}</span> of <span>{page_Count}</span>
												</li>
												{pageNo != 1 && <li>
													<BsChevronLeft onClick={() => setPageNo(pageNo - 1)} />
												</li>}
												{pageNo != page_Count && <li>
													<BsChevronRight onClick={() => setPageNo(pageNo + 1)} />
												</li>}
												{pageNo != page_Count && <li>
													<BsChevronBarRight onClick={() => setPageNo(page_Count)} />
												</li>}
											</ul>
										</div>}
								</div>
							</div>
						}
						{showVideocard &&
							<Row className="mt-4">

								{filteredCreatives?.length === 0 ?
									<div className="No_Data pos">

										<img
											className="nodata"
											src="/assets/images/notfound.svg"
										/>
									</div>
									:
									filteredCreatives?.map((creative, index) => {
										if (creative?.creativeTypeId === 1) {
											return (
												<Col lg={2} index={index}>
													<div className="video_view_card">
														<div className="creativesimg">
															<video className="w-100" controls>
																<source
																	src={`http://creatives.kitemedia.digital/${creative.object}`}
																	type="video/mp4"
																/>
															</video>
														</div>
														<div className="creative_text pb-0">
															<p>Creative ID : <strong>{creative?.id}</strong></p>
															<p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
															<p>Name : <strong>{creative.name}</strong></p>
															<p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
															<p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
															<p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
														</div>
													</div>
												</Col>

											)
										}

									})
								}
								{
									filteredCreatives?.map((creative, index) => {
										if (creative?.creativeTypeId === 4) {
											return (
												<Col lg={2} index={index}>
													<div className="video_view_card">
														<div className="creativesimg">
															<img
																className="img-fluid"
																src={`http://creatives.kitemedia.digital/${creative.object}`}
																alt=""
															/>
														</div>
														<div className="creative_text pb-0">
															<p>Creative ID : <strong>{creative?.id}</strong></p>
															<p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
															<p>Name : <strong>{creative.name}</strong></p>
															<p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
															<p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
															<p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
														</div>
													</div>
												</Col>

											)
										}

									})
								}
								{
									filteredCreatives?.map((creative, index) => {
										if (creative?.creativeTypeId === 5) {
											return (
												<Col lg={2} index={index}>
													<div className="video_view_card">
														<div className="creativesimg">
															<img
																className="img-fluid"
																src="https://ahrefs.com/blog/wp-content/uploads/2020/02/blog-meta-tags-400x200.png"
																alt=""
															/>
														</div>
														<div className="creative_text pb-0">
															<p>Creative ID : <strong>{creative?.id}</strong></p>
															<p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
															<p>Name : <strong>{creative.name}</strong></p>
															<p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
															<p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
															<p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
														</div>
													</div>
												</Col>

											)
										}

									})
								}
								{
									filteredCreatives?.map((creative, index) => {
										if (creative?.creativeTypeId === 3) {
											return (
												<Col lg={2} index={index}>
													<div className="video_view_card">
														<div className="creativesimg">
															<iframe
																src={
																	creative?.object.includes("youtu.be") // Check if the URL is in the short format
																		? `https://www.youtube.com/embed/${creative?.object.split('/').pop()}`
																		: creative?.object.includes("watch") // Check if the URL is in the long format
																			? `https://www.youtube.com/embed/${creative?.object.split('v=')[1]}`
																			: creative?.object // Use the original URL if it doesn't match the above conditions
																}
																className="w-100"
																frameBorder="0"
																allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
																allowFullScreen
															></iframe>

														</div>
														<div className="creative_text pb-0">
															<p>Creative ID : <strong>{creative?.id}</strong></p>
															<p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
															<p>Name : <strong>{creative.name}</strong></p>
															<p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
															<p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
															<p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
														</div>
													</div>
												</Col>
											)
										}
									})
								}
								{
									filteredCreatives?.map((creative, index) => {
										if (creative?.creativeTypeId === 2) {
											return (
												<Col lg={2} index={index}>
													<div className="video_view_card">
														<div className="creativesimg">
															<img
																className="img-fluid"
																src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0crO16mI5mGJwYUh1Al9Faxf765RbmQZahg&usqp=CAU"
																alt=""
															/>
														</div>
														<div className="creative_text pb-0">
															<p>Creative ID : <strong>{creative?.id}</strong></p>
															<p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
															<p>Name : <strong>{creative.name}</strong></p>
															<p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
															<p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
															<p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
														</div>
													</div>
												</Col>

											)
										}

									})
								}
								{
									filteredCreatives?.map((creative, index) => {
										if (creative?.creativeTypeId === 6) {
											return (
												<Col lg={2} index={index}>
													<div className="video_view_card">
														<div className="creativesimg">
															<img
																className="img-fluid"
																src="https://ahrefs.com/blog/wp-content/uploads/2020/02/blog-meta-tags-400x200.png"
																alt=""
															/>
														</div>
														<div className="creative_text pb-0">
															<p>Creative ID : <strong>{creative?.id}</strong></p>
															<p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
															<p>Name : <strong>{creative.name}</strong></p>
															<p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
															<p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
															<p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
														</div>
													</div>
												</Col>

											)
										}
									})
								}
							</Row>
						}
					</div>
				</div>
			</div>
			<Modal
				show={statusmodalShow}
				onHide={() => {
					setStatusModalShow(false);
					resetstatus();
				}}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body>
					<div className="model_content">
						<div className='text-center mb-4'>
							<FiAlertCircle className='alert_icon' size={64} />
						</div>
						<h4 className="text-center border-bottom pb-5"> {statusmodel?.messageText}</h4>
						<div className="d-flex justify-content-end mt-5">
							<button
								className="me-4 cancel_cm_btn"
								onClick={() => setStatusModalShow(false)}
							>
								Cancel
							</button>
							<button
								className={statusmodel?.buttonClass}
								onClick={() => {
									updateStatus();
								}}
							>
								{statusmodel?.buttonText}
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={creativemodalShow}
				onHide={() => setCreativeModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="campaign_modal"
			>
				<Modal.Body>
					<Modal.Header closeButton>
						<Modal.Title>New Creative</Modal.Title>
					</Modal.Header>
					<NewCreatives
						setCreativeModalShow={setCreativeModalShow}
						campaignId={campaignId}
						lineItem={lineItem}
						searchFilter={searchFilter}
						getNewDataCreatives={getSearchedData}
						getCreatives={getSearchedData}
					/>
				</Modal.Body>
			</Modal>

			{/* creative edit modal */}

			<Modal
				show={creative_edit_modal_Show}
				onHide={() => setCreative_edit_modal_show(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="campaign_modal"
			>
				<Modal.Body>
					<Modal.Header closeButton>
						<Modal.Title>Edit Creative</Modal.Title>
					</Modal.Header>
					<NewCreatives
						setCreative_edit_modal_show={setCreative_edit_modal_show}
						campaignId={campaignId}
						lineItem={lineItem}
						id={id}
						showError={showError}
						sum_Budget={sum_Budget}
						edit={true}
						getNewDataCreatives={getSearchedData}
					/>
				</Modal.Body>
			</Modal>

			<Modal
				show={modaleditShow}
				onHide={() => setEditLineItemModal(false)}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="campaign_modal"
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						{line_Item ? "Update Line Item" : "New Line Item"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditLineItems
						setEditLineItemModal={setEditLineItemModal}
						lineItemId={line_Item?.id}
						startDate={startDate}
						endDate={endDate}
						total_Budget={total_Budget}
						sum_Budget={sum_Budget}
						dealid={line_Item?.deal_id}
						campaignId={campaignId}
						onRefresh={searchFilter}
						editStatus={line_Item?.lineItemStatusNavigation?.description}
						dealCurrency={dealCurrency}
						getLine_Item={getLine_Item}
					/>
				</Modal.Body>
			</Modal>

			<Modal
				show={statusCreative}
				onHide={() => setStatusCreative(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body className="p-3">
					<div className='text-center mb-4'>
						<FiAlertCircle className='alert_icon' size={64} />
					</div>
					<h4 className="text-center border-bottom pb-5">{text}</h4>
					<div className="d-flex justify-content-end mt-5">
						<button className="me-4 cancel_cm_btn" onClick={() => setStatusCreative(false)}>Cancel</button>
						<button className="cmn_btn ms-2" onClick={handleStatusUpdate}>Yes</button>
					</div>

				</Modal.Body>
			</Modal>
		</div>
	);
};
export default Creatives;
