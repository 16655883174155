import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { lineitem_log_history } from '../../app/features/lineitemHistory/lineitemHistorySlice';
import Moment from "react-moment";
import Loader from '../Loader';
import { clear_log_history } from '../../app/features/lineitemHistory/lineitemHistorySlice';
const LineItemshistory = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    useEffect(() => {
        return () => {
            dispatch(clear_log_history())
        };
    }, [])

    const { id, pathname, campaignId, campaign_id, advertiser, client_id, dealid, dealId, deal_status_id, deal_id, currency_code,deal_currency } = location?.state ? location?.state : location;

    useEffect(() => {
        dispatch(lineitem_log_history({ id, entityId: 1 }))
    }, [id])
    const historyData = useSelector((state) => { return state.log_history })
    console.log(historyData, "historyData historyData historyData historyData")

    useEffect(() => {
        if (historyData?.loading) {
            setShow(true)
        }
        else {
            setShow(false)
        }
    }, [historyData])

    const dates = [];

    historyData?.data?.data?.properties?.forEach((record) => {
        const modifiedTime = record?.propertyChanges[0]?.modifiedAt;
        const changedValues = record?.propertyChanges.map((data) => ({
            oldValue: data?.oldValue,
            newValue: data?.newValue,
            user: data?.modifiedBy,
            modifiedAt: data?.modifiedAt,
            propertyName: data?.propertyName
        }));
        dates.push({ modifiedTime, changedValues });
    });
    return (
        <div className='content_outer'>
            <Loader showLoader={show} />
            <div class="cmn_header_top campaign__header mb-3">
                <div class="row w-100 m-0">
                    <div class="col-lg-11 m-auto">
                        <h3><img src="/assets/images/chevron_left.svg" onClick={() => { navigate(pathname, { state: { campaignId: campaignId ? campaignId : null, campaign_id: campaign_id ? campaign_id : null, advertiser: advertiser, currency_code: currency_code, client_id: client_id, dealid: dealid, dealId: dealId, deal_status_id: deal_status_id, deal_id,deal_currency } }) }} /> History</h3>
                        <div class="flex-grow-1 text-center">
                            {/* <div class="cmn_header__search">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                </svg>
                                <input type="text" placeholder="Search By Deal Name" value="" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row m-0">
                <div className="col-lg-11 m-auto">
                    <div class="title__active mt-3">
                        <h3>{historyData?.data?.data?.lineitemValue?.lineItems?.name}</h3>
                        <div class="status_badge">
                            <p>
                                {historyData?.data?.data?.lineitemValue?.lineItems?.status == 'DRAFT' && <img src="/assets/images/draft_FILL.svg" />}
                                {historyData?.data?.data?.lineitemValue?.lineItems?.status == 'ACTIVE' && <img src="/assets/images/check_circle.svg" />}
                                {historyData?.data?.data?.lineitemValue?.lineItems?.status == 'PAUSE' && <img src="/assets/images/pause_circle.svg" />}
                                {historyData?.data?.data?.lineitemValue?.lineItems?.status == 'INACTIVE' && <img src="/assets/images/cancel_FILL.svg" />}<span class="text-secondary">
                                    {historyData?.data?.data?.lineitemValue?.lineItems?.status}</span>
                            </p>
                        </div>
                    </div>
                    <div className='advert_tabel history_list'>

                        <div className='media_plan_info_list mb-5'>
                            <ul>
                                <li>
                                    <strong>ID</strong>
                                    <p> {historyData?.data?.data?.lineitemValue?.lineItems?.id}</p>
                                </li>
                                <li>
                                    <strong>Inventory type</strong>
                                    <p> {historyData?.data?.data?.lineitemValue?.lineItems?.inventioryType}</p>
                                </li>
                                <li>
                                    <strong>Format</strong>
                                    <p> {historyData?.data?.data?.lineitemValue?.lineItems?.format}</p>
                                </li>
                                <li>
                                    <strong>Offer Type</strong>
                                    <p> {historyData?.data?.data?.lineitemValue?.lineItems?.offerType}</p>
                                </li>
                                <li>
                                    <strong>Country</strong>
                                    {historyData?.data?.data?.lineitemValue?.lineItems?.countryList?.map((country, i) => {
                                        return (
                                            <p>{country?.country?.labelKey}</p>
                                        )
                                    })}

                                </li>
                                <li>
                                    <strong>Budget {currency_code ? currency_code : deal_currency}</strong>
                                    <p> {historyData?.data?.data?.lineitemValue?.lineItems?.inversionBudget}</p>
                                </li>
                                {historyData?.data?.data?.lineitemValue?.lineItems?.serviceId == 1 ? <li>
                                    <strong>Sell Rate</strong>
                                    <p> {historyData?.data?.data?.lineitemValue?.lineItems?.sellRate}</p>
                                </li>
                                    :
                                    <li>
                                        <strong>Bid</strong>
                                        <p> {historyData?.data?.data?.lineitemValue?.lineItems?.bidKite}</p>
                                    </li>}
                                {historyData?.data?.data?.lineitemValue?.lineItems?.serviceId == 1 ? <li>
                                    <strong>Goal</strong>
                                    <p> {historyData?.data?.data?.lineitemValue?.lineItems?.goal}</p>
                                </li>
                                    :
                                    <li>
                                        <strong>Estimated Impressions</strong>
                                        <p> {historyData?.data?.data?.lineitemValue?.lineItems?.estimatedimpressions}</p>
                                    </li>}
                                <li className=''>
                                    <ul class="header__media p-0">
                                        <li>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
                                                <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
                                            </svg>
                                            <strong>Start Date:</strong>
                                            <p><time datetime="1721759400000"><Moment format='  Do MMMM YYYY'>{historyData?.data?.data?.lineitemValue?.lineItems?.dateFrom}</Moment></time></p>
                                        </li>
                                        <li class="block_divider"><span></span></li>
                                        <li className='grow-1'>
                                            <strong>End Date:</strong>
                                            <p><time datetime="1722018600000"><Moment format='  Do MMMM YYYY'>{historyData?.data?.data?.lineitemValue?.lineItems?.dateTo}</Moment></time></p>
                                        </li>
                                    </ul>
                                </li>
                                {/* <li className='d-flex gap-2 ms-auto'>
                                    <div class="pdf_icon" title="Edit Campaign">
                                        <img src="/assets/images/play_circle.svg" />
                                    </div>
                                    <div class="pdf_icon" title="Edit Campaign">
                                        <img src="/assets/images/edit_fill.svg" />
                                    </div>
                                    <div class="pdf_icon" title="Edit Campaign">
                                        <img src="/assets/images/description_file.svg" />
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    {historyData?.data?.data?.properties.length != 0 ?
                        <div className='list_wrapper'>
                            {dates.map((val, i) => {
                                if (val?.changedValues?.length !== 0) {
                                    return (
                                        <div className='list_items' key={i}>
                                            <div className='history_grid mb-5'>
                                                <h4>
                                                    <Moment format='MMMM Do'>
                                                        {val?.modifiedTime}
                                                        {/* Uncomment the above line and replace 21542 with the correct value */}
                                                    </Moment>
                                                    {" "}at{" "}
                                                    <Moment format='h:mm'>
                                                        {val?.modifiedTime}
                                                        {/* Uncomment the above line and replace 21542 with the correct value */}
                                                    </Moment>
                                                    &nbsp;(Time zone UTC +0)
                                                </h4>
                                                <div className="advert_tabel campign_index_table">
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                {/* <th className='text-start w-25'>Time</th> */}
                                                                <th className='text-start w-50'>Description</th>
                                                                <th className='text-start'>User</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {val?.changedValues?.map((data, index) => {
                                                                if (data?.propertyName !== "WhiteListId") {
                                                                    return (
                                                                        <tr key={index}>
                                                                            {/* <td className='align-top'>
                                                                                <Moment format='h:mm'>
                                                                                    {data?.modifiedAt}
                                                                                    
                                                                                </Moment>
                                                                            </td> */}
                                                                            <td className='align-top'>
                                                                                <div>
                                                                                    {data?.propertyName === "DateFrom" || data?.propertyName === "DateTo" ? <Accordion>
                                                                                        <Accordion.Item eventKey="0">
                                                                                            <Accordion.Header>
                                                                                                {data?.propertyName === "DateFrom" ? "Date From" : "Date To"}
                                                                                            </Accordion.Header>
                                                                                            <Accordion.Body>
                                                                                                <b>
                                                                                                    From {<Moment format='l'>{data?.oldValue}</Moment>} to {<Moment format='l'>{data?.newValue}</Moment>}
                                                                                                    {/* Replace with the correct value */}

                                                                                                </b>
                                                                                            </Accordion.Body>
                                                                                        </Accordion.Item>
                                                                                    </Accordion> :
                                                                                        <Accordion>
                                                                                            <Accordion.Item eventKey="0">
                                                                                                <Accordion.Header>
                                                                                                    {data?.propertyName === "AudienceId" ? "Audience" :
                                                                                                        data?.propertyName === "DeviceId" ? "Devices" :
                                                                                                            data?.propertyName === "PassionpointId" ? "Passion Points" :
                                                                                                                data?.propertyName === "CountryId" ? "Country" :
                                                                                                                    data?.propertyName === "DateFrom" ? "Start Date" :
                                                                                                                        data?.propertyName === "DateTo" ? "End Date" :
                                                                                                                            data?.propertyName === "InversionBudget" ? "Budget" :
                                                                                                                                data?.propertyName === "InversionBudgetUsd" ? "Budget USD" :
                                                                                                                                    data?.propertyName === "InversionGoal" ? "Goal" :
                                                                                                                                        data?.propertyName === "InversionNetMargin" ? "Margin" :
                                                                                                                                            data?.propertyName === "InversionNetRate" ? "Net Rate" :
                                                                                                                                                data?.propertyName === "InversionNetRateUsd" ? "Net Rate USD" :
                                                                                                                                                    data?.propertyName === "InversionSellRate" ? "Sell Rate" :
                                                                                                                                                        data?.propertyName === "InversionSellRateUsd" ? "Sell Rate USD" :
                                                                                                                                                            data?.propertyName === "PacingId" ? "Pacing" :
                                                                                                                                                                data?.propertyName === "PacingRate" ? "Pacing Rate" :
                                                                                                                                                                    data?.propertyName === "TargetGenderId" ? "Gender" :
                                                                                                                                                                        data?.propertyName === "estimatedimpressions" || data?.propertyName === "Estimatedimpressions" ? "Estimated Impressions" :
                                                                                                                                                                            data?.propertyName === "BidKite" ? "Bid Kite" :
                                                                                                                                                                                data?.propertyName === "BidKiteUsd" ? "Bid Kite USD" :
                                                                                                                                                                                    data?.propertyName === "StateId" ? "States" :
                                                                                                                                                                                        data?.propertyName === "InversionGoalUsd" ? "Inversion Goal Usd" :
                                                                                                                                                                                            data?.propertyName}
                                                                                                </Accordion.Header>
                                                                                                <Accordion.Body>
                                                                                                    <b>
                                                                                                        From {typeof (data?.oldValue) === "number" ? Number(data?.oldValue)?.toLocaleString() : data?.oldValue} to {typeof (data?.newValue) === "number" ? Number(data?.newValue)?.toLocaleString() : data?.newValue}
                                                                                                        {/* Replace with the correct value */}

                                                                                                    </b>
                                                                                                </Accordion.Body>
                                                                                            </Accordion.Item>
                                                                                        </Accordion>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className='align-top'>
                                                                                {data?.user}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        : <div className="no_data_cmn mt-3 bg-white"><img class="nodata" src="/assets/images/notfound.svg" /></div>


                    }
                </div>
            </div>

        </div>
    )
}

export default LineItemshistory;