import React, { useState, useEffect, useRef } from 'react'
import './campaign.css'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-hot-toast";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import Loader from '../Loader';
import { developedBy, clearState, CreativeSelector, getLineItem, getCreativeTypes, StoreCreative, clearstorecreative, UpdateCreative, clearupdateCreative } from '../../app/features/Creative/CreativeSlice';

const initialValues = {
	file: "",
	name: "",
	developedBy: "",
	creativeTypeId: "",
	clicksTracking: "",
	impressionTracking: "",
	lineItemId: "",
	urlLandingPage: "",
	formatId: "",
	link: "",
	tags: "",
	VastTags: "",
	fileAvailable: "0",
	designid: "",
	token: localStorage.getItem('token')
};

const NewCreatives = (props) => {
	const formikRef = useRef();
	const location = useLocation();
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const [loadershown, setloadershown] = useState(false);
	const [stopundefine, setStopUndefined] = useState(false)
	const [creative_id, setCreative_id] = useState()
	const [format_id, setFormat_id] = useState()
	const [line_item_id, setLine_Item_Id] = useState()
	const [edit_data, setEdit_data] = useState([])
	const [reload, setReload] = useState(false)
	const [reload_check, setReload_check] = useState(false)
	const [designIdError, setDesignIdError] = useState(false)
	const [creativeFields, setCreativeFields] = useState({
		type: "",
		name: ""
	});
	const [selectedField, setSelectedField] = useState('');
	const [updateName, setUpdateName] = useState(false)
	const [dataName, setDataName] = useState('')
	const [designId, setDesignId] = useState("")

	const {
		developed_by, line_Item,
		creativeTypes, errorMessage, error,
		isSuccessStoreCreative, isErrorStoreCreative,
		isFetchingStoreCreative,
		isSuccessUpdateCreative, isErrorUpdateCreative,
		isFetchingUpdateCreative
	} = useSelector(CreativeSelector);
	useEffect(() => {
		// dispatch(getLineItem({ lineitem_id: props.lineItem, token: localStorage.getItem('token') }));
		dispatch(developedBy({ token: localStorage.getItem('token') }));
	}, [])

	useEffect(() => {
		if (Object.keys(line_Item)?.length > 0) {
			dispatch(getCreativeTypes({ type_id: line_Item?.formatNavigation?.id, token: localStorage.getItem('token') }));
		}
	}, [line_Item])


	useEffect(() => {
		if (isSuccessUpdateCreative) {
			toast.success('Creative Updated Successfully')
			setTimeout(() => {
				setReload(false)
				props.setCreative_edit_modal_show(false)
				props?.getNewDataCreatives();
				setloadershown(false);
				props.getCreatives();
			}, 1000)
			setloadershown(false)
			dispatch(clearupdateCreative());

		}
		if (isErrorUpdateCreative) {
			toast.error(errorMessage)
			setloadershown(false)
			dispatch(clearupdateCreative());
		}

	}, [isSuccessUpdateCreative, isErrorUpdateCreative, isFetchingUpdateCreative])


	useEffect(() => {
		if (isSuccessStoreCreative) {
			console.log('entra en ifsuccessstorecreative')
			toast.success()
			setTimeout(() => {
				props.setCreativeModalShow(false);
				props?.getNewDataCreatives();
				setloadershown(false);
				setReload_check(false)
				props.setCreativeModalShow(false)
				props.getCreatives();
			}, 1000)
			setloadershown(false)
			dispatch(clearstorecreative());
			console.log('pasa')

		}
		if (isErrorStoreCreative) {
			toast.error(errorMessage)

		}
		/*if (isFetchingStoreCreative) {
			// setloadershown(true)
		}*/

	}, [isSuccessStoreCreative, isErrorStoreCreative, isFetchingStoreCreative])

	const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
		const allowedExtensions = ["mp4"];

		if (values.urlLandingPage === values.clicksTracking) {
			toast.error("Landing Page and Click Tracker must be different")
		}
		else if (values.creativeTypeId == 3 && !(values.link.startsWith("https://www.youtube.com/") || values.link.startsWith("https://youtu.be/"))) {
			toast.error("Youtube Creative must start with “https://www.youtube.com/” or “https://youtu.be/”");
		}
		else if (values.file && (values.file.size > 10 * 1024 * 1024)) {
			toast.error("File size should not be more than 10 MB");
		}
		else if (values.file && !allowedExtensions.includes(values.file.name.split(".").pop().toLowerCase())) {
			toast.error("File should be video with extension .mp4");
		}
		else {
			if (!values.urlLandingPage.includes("https://")) {
				values.urlLandingPage = `https://${values.urlLandingPage}`
			}
			values.formatId = line_Item?.format_id;
			values.lineItemId = props.lineItem;
			const formData = new FormData();
			for (let value in values) {
				formData.append(value, values[value]);
			}
			setloadershown(true)
			if (props.id === undefined) {
				var myHeaders = new Headers();
				myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

				var formdata = new FormData();
				formdata.append("name", values.name);
				formdata.append("developedBy", values.developedBy);
				formdata.append("creativeTypeId", values.creativeTypeId);
				formdata.append("impressionTracking", values.impressionTracking ? values.impressionTracking : null);
				formdata.append("clicksTracking", values.clicksTracking ? values.clicksTracking : null);
				formdata.append("lineItemId", values.lineItemId);
				formdata.append("urlLandingPage", values.urlLandingPage);
				formdata.append("formatId", line_Item?.formatNavigation?.id);
				formdata.append("tags", values.tags ? values.tags : "");
				formdata.append("link", values.link ? values.link : "");
				formdata.append("vastTags", values.VastTags ? values.VastTags : "");
				formdata.append("file", values.file ? values.file : "");

				var requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: formdata,
					redirect: 'follow'
				};

				fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/store-creative`, requestOptions)
					.then(response => response.json())
					.then(result => {
						props.setCreativeModalShow(false);
						result?.success == false ?
							toast.error("Name is already exist") :
							result.file ? toast.error(result.file) :
								toast.success("Creative saved successfully. It will be Under Review for up to 48hs");
						setloadershown(false);
						props.getCreatives()
					})
					.catch(error => toast.error("Internal server error occurred"));
			} else {
				var myHeaders = new Headers();
				myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

				var formdata = new FormData();
				formdata.append("name", values.name);
				formdata.append("developedBy", values.developedBy);
				formdata.append("creativeTypeId", values.creativeTypeId);
				formdata.append("impressionTracking", values.impressionTracking ? values.impressionTracking : null);
				formdata.append("clicksTracking", values.clicksTracking ? values.clicksTracking : null);
				formdata.append("lineItemId", values.lineItemId);
				formdata.append("urlLandingPage", values.urlLandingPage);
				formdata.append("formatId", edit_data?.formatId);
				formdata.append("tags", values.tags ? values.tags : "");
				formdata.append("link", values.link ? values.link : "");
				formdata.append("vastTags", values.VastTags ? values.VastTags : "");
				formdata.append("file", values.file ? values.file : "");
				formdata.append("updatedname", updateName);

				var requestOptions = {
					method: 'PUT',
					headers: myHeaders,
					body: formdata,
					redirect: 'follow'
				};

				fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-creative/${props.id}`, requestOptions)
					.then(response => response.json())
					.then(result => {
						if (result?.success === false) {
							toast.error("Name already exists");
							setloadershown(false);
							props?.setCreative_edit_modal_show(false);
						} else {
							setloadershown(false);
							props?.setCreative_edit_modal_show(false);
							toast.success("Creative updated successfully");
							props?.getNewDataCreatives();
						}
					})

					.catch(error => toast.error("Internal Server error occured"));
			}
		}
	};

	const typeChange = (type) => {
		if (type != "") {
			let creative = creativeTypes?.find(o => o.id === parseInt(type));
			formikRef.current?.setFieldValue(selectedField, "", false);
			setCreativeformapi(creative?.description)
		}
	}

	const setCreativeformapi = (description) => {
		switch (description) {
			case 'Video':
				setCreativeFields({
					type: "file",
					name: "file"
				});
				setSelectedField("file");
				break;
			case 'HTML5':
				setCreativeFields({
					type: "file",
					name: "file"
				})
				setSelectedField("file");
				break;
			case '.png, .jpg, .bmp':
				setCreativeFields({
					type: "file",
					name: "file"
				})
				setSelectedField("file");
				break;
			case 'Tags':
				setCreativeFields({
					type: "text",
					name: "tags"
				})
				setSelectedField("tags");
				break;
			case 'Youtube video link':
				setCreativeFields({
					type: "text",
					name: "link"
				})
				setSelectedField("link");
				break;
			case 'Vast Tags':
				setCreativeFields({
					type: "text",
					name: "VastTags"
				})
				setSelectedField("vast");
				break;
		}
	}

	const changeCreative = (e) => {
		if (selectedField == "file") {
			setTimeout(() => {
				formikRef.current?.setFieldValue("file", e.currentTarget.files[0], true);
				formikRef.current?.setFieldValue("fileAvailable", "1", true);
			}, 500);
		}

		if (selectedField == "vast") {
			formikRef.current?.setFieldValue("VastTags", e.target.value, true);
			formikRef.current?.setFieldValue("fileAvailable", "0", true);
		}

		if (selectedField == "tags") {
			formikRef.current?.setFieldValue("tags", e.target.value, true);
			formikRef.current?.setFieldValue("fileAvailable", "0", true);
		}
		else {
			formikRef.current?.setFieldValue("link", e.target.value, true);
			formikRef.current?.setFieldValue("fileAvailable", "0", true);
		}
	}

	function validate_Field(value) {
		let error;
		if (!value) {
			error = 'this field is Required';
		}
		return error;
	}

	useEffect(() => {
		props.id !== undefined && getdata()
	}, [])


	const getdata = async () => {
		const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/creative/${props.id}`, {
			method: "GET",
			headers: {
				Authorization: "Bearer " + localStorage.getItem('token')
			}
		})
		const result = await response.json()
		setEdit_data(result?.data)
		setCreative_id(result?.data?.creativeTypeId)
		setLine_Item_Id(result?.data?.lineitemId)
		setFormat_id(result?.data?.formatId)
	}

	useEffect(() => {
		if (edit_data?.length != 0) {
			let creative = creativeTypes?.find(o => o.id === parseInt(edit_data?.creativeTypeId));
			if (creative && Object.keys(creative)?.length > 0) {
				setCreativeformapi(creative?.description)
			}
			formikRef.current?.setFieldValue("name", edit_data?.name, true);
			setDataName(edit_data?.name)
			formikRef.current?.setFieldValue("tags", edit_data?.object, true);
			formikRef.current?.setFieldValue("link", edit_data?.object, true);
			formikRef.current?.setFieldValue("developedBy", edit_data?.developedBy, true);
			formikRef.current?.setFieldValue("creativeTypeId", edit_data?.creativeTypeId, true);
			formikRef.current?.setFieldValue("impressionTracking", edit_data?.impressionTracking, true);
			formikRef.current?.setFieldValue("clicksTracking", edit_data?.clicksTracking, true);
			formikRef.current?.setFieldValue("urlLandingPage", edit_data?.urlLandingPage, true);
			formikRef.current?.setFieldValue("designid", edit_data?.designId, true);
			formikRef.current?.setFieldValue("formatId", edit_data?.formatId, true);
		}
	}, [edit_data])


	const handleName = (e) => {
		if (e.target.value !== dataName) setUpdateName(true)
		else setUpdateName(false)
	}

	const handleUploadFile = (e, setFieldValue) => {
		const fileExtension = e.currentTarget.files[0].name.split(".").pop().toLowerCase();
		const allowedExtensions = ["mp4"];

		if (!allowedExtensions.includes(fileExtension)) {
			toast.error("File should be video with extension .mp4");
		}
		if (e.currentTarget.files[0].size > 10 * 1024 * 1024) {
			toast.error("File size should not be more than 10 MB");
		}

		const video = document.createElement('video');
		video.src = URL.createObjectURL(e.currentTarget.files[0]);
		video.onloadedmetadata = function () {
			if (video.duration > 30) {
				toast.error("Video duration should not be more than 30 seconds");
			}
		};

		setFieldValue("file", e.currentTarget.files[0]);
		setFieldValue("fileAvailable", "1");
	}

	return (
		<>
			<div className=''>
				<Loader showLoader={loadershown} />
				<div className='content'>
					<Formik
						initialValues={initialValues}
						validationSchema={Yup.object().shape({
							name: Yup.string().required('Name is required'),
							developedBy: Yup.string().required('Select Developed Type'),
							creativeTypeId: Yup.string().required('Select Creative Type'),
							urlLandingPage: Yup.string().required('Url is Required'),
						})}
						onSubmit={handleFormSubmit}
						innerRef={formikRef}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setFieldValue,
							validateField
							/* and other goodies */
						}) => (
							<Form>
								<div className='row'>
									<div className="col-md-6 mb-4">
										<div className={props?.edit == true ? "form-group  select_country_container cmn_modal_field bg-secondary" : "form-group  select_country_container cmn_modal_field"}>
											<label htmlFor="creative" className={props?.edit == true ? "lbl_position text-white" : 'lbl_position'}>Creative Name</label>
											<input type="text" className={props?.edit == true ? 'form-control bg-secondary text-white' : "form-control"} disabled={props?.edit} placeholder='Creative Name' name="name" onChange={handleChange} onBlur={(e) => { handleName(e) }}
												value={values.name} />
										</div>
										{errors.name && touched.name ? (
											<span className='text-danger'>{errors.name}</span>
										) : null}
									</div>
									<div className="col-md-6 mb-4 ">
										<div className={props?.edit == true ? "form-group  select_country_container cmn_modal_field bg-secondary" : "form-group  select_country_container cmn_modal_field"}>
											<label htmlFor="creative" className={props?.edit == true ? "lbl_position text-white" : 'lbl_position'}>Developed By</label>

											<select name="developedBy" disabled={props?.edit} className={props?.edit == true ? 'form-control bg-secondary form-select text-white' : "form-control form-select"} value={values?.developedBy} onChange={(e) => { handleChange(e); }} >
												<option value="">--Select--</option>
												{developed_by?.map((develop, index) => {
													return (
														<option value={develop?.id} key={index}>{develop?.name}</option>
													)
												})}
											</select>
										</div>
										{errors.developedBy && touched.developedBy ? (
											<span className='text-danger'>{errors.developedBy}</span>
										) : null}
									</div>
									<div className='col-12'>
										<div className="">
											<div className="row">
												<div className="col-md-6 mb-4">
													<div className={props?.edit == true ? "form-group  select_country_container cmn_modal_field bg-secondary" : "form-group  select_country_container cmn_modal_field"}>
														<label htmlFor="creative" className={props?.edit == true ? "lbl_position text-white" : 'lbl_position'}>Creative Type</label>
														<select
															name="creativeTypeId"
															className={props?.edit == true ? 'form-control bg-secondary form-select text-white' : "form-control form-select"}
															value={values?.creativeTypeId}
															disabled={props?.edit}
															onChange={(e) => {
																handleChange(e);
																typeChange(e.target.value)
															}}
														>
															<option value="">--Select--</option>
															{creativeTypes?.map((creative, index) => {
																return (
																	<option value={creative?.id} key={index}>{creative?.description}</option>
																)
															})}
														</select>
													</div>
													{errors.creativeTypeId && touched.creativeTypeId ? (
														<span className='text-danger'>{errors.creativeTypeId}</span>
													) : null}
												</div>

												<div className="col-md-6 mb-4">
													<div className={props?.edit == true ? "form-group  select_country_container cmn_modal_field bg-secondary" : "form-group  select_country_container cmn_modal_field"}>
														<label htmlFor="creative" className={props?.edit == true ? "lbl_position text-white" : 'lbl_position'}>Creative</label>
														{creativeFields?.type != "" && creativeFields?.type == "file" &&
															<input
																type="file"
																name="file"
																onChange={(e) => { handleUploadFile(e, setFieldValue) }}
																className={props?.edit == true ? 'form-control bg-secondary text-white' : "form-control"}
																placeholder='Creative'
																required={!edit_data}
																disabled={props?.edit}
															/>
														}
														{
															creativeFields?.type !== "" && creativeFields?.type !== "file" && selectedField === "tags" && (
																<Field name="tags" as="textarea" disabled={props?.edit} value={values.tags} onChange={(e) => { changeCreative(e); }}
																	className={props?.edit == true ? 'form-control bg-secondary text-white' : "form-control"} placeholder='' validate={validate_Field} />
															)
														}
														{creativeFields?.type != "" && creativeFields?.type != "file" && selectedField == "vast" &&

															<Field type="text" disabled={props?.edit} name="VastTags" value={values.VastTags} onChange={(e) => { changeCreative(e); }}
																className={props?.edit == true ? 'form-control bg-secondary text-white' : "form-control"} placeholder='Creative' validate={validate_Field} />
														}
														{creativeFields?.type != "" && creativeFields?.type != "file" && selectedField == "link" &&

															<Field type="text" disabled={props?.edit} name="link" value={values.link} onChange={(e) => { changeCreative(e); }}
																className={props?.edit == true ? 'form-control bg-secondary text-white' : "form-control"} placeholder='Creative' validate={validate_Field} />
														}
													</div>
													{errors[creativeFields?.name] && touched[creativeFields?.name] && <span className='text-danger'>{errors[creativeFields?.name]}</span>}
													{(edit_data?.creativeTypeId == 1 || edit_data?.creativeTypeId == 2 || edit_data?.creativeTypeId == 4 || edit_data?.creativeTypeId === 6) && <span className='mt-1 d-block'>current file: {edit_data?.creativeTypeId === 6 ? edit_data?.object : (edit_data?.object).slice(35)}</span>}
													{/* {(edit_data?.creativeTypeId == 5 ) && <span className='text_ellips'>current tags: {(edit_data?.object)}</span>}
                                                        {(edit_data?.creativeTypeId == 3 ) && <span className='text_ellips' >current link: {(edit_data?.object)}</span>} */}
												</div>
												{selectedField == "vast" || selectedField == "tags" || selectedField == "link" ? null : <div className="col-md-6">
													<div className="form-group mb-4 select_country_container cmn_modal_field">
														<label htmlFor="Impressiontracking" className='lbl_position'>Impression Tracking </label>
														<input
															type="text"
															className='form-control'
															name="impressionTracking"
															onChange={(e) => { handleChange(e) }}
															placeholder='Impression Tracking'
															value={values.impressionTracking}
														/>
													</div>
													{errors.impressionTracking && touched.impressionTracking ? (
														<span className='text-danger'>{errors.impressionTracking}</span>
													) : null}
												</div>}
												{selectedField == "vast" || selectedField == "tags" ? null : <div className="col-md-6">
													<div className="form-group mb-4 select_country_container cmn_modal_field">
														<label htmlFor="clickTracking" className='lbl_position'>Click Tracking </label>
														<input type="text" className='form-control' name="clicksTracking" onChange={(e) => { handleChange(e) }} placeholder='Click Tracking' value={values.clicksTracking} />
													</div>
													{errors.clicksTracking && touched.clicksTracking ? (
														<span className='text-danger'>{errors.clicksTracking}</span>
													) : null}
												</div>

												}
												<div className={values?.developedBy == 2 ? "col-md-6 mb-4" : "col-md-12 mb-4"}>
													<div className={props?.edit == true ? "form-group  select_country_container cmn_modal_field bg-secondary" : "form-group  select_country_container cmn_modal_field"}>
														<label htmlFor="creative" className={props?.edit == true ? "lbl_position text-white" : 'lbl_position'}>Landing Pages  </label>
														<input type="text" className={props?.edit == true ? 'form-control bg-secondary text-white' : "form-control"} name="urlLandingPage" disabled={props?.edit} onChange={(e) => { handleChange(e) }} placeholder='landing Pages' value={values.urlLandingPage} />
													</div>
													{errors.urlLandingPage && touched.urlLandingPage ? (
														<span className='text-danger'>{errors.urlLandingPage}</span>
													) : null}
												</div>
												{values?.developedBy == 2 && selectedField == "tags" && <div className="col-md-6">
													<div className={props?.edit == true ? "form-group  select_country_container cmn_modal_field bg-secondary" : "form-group  select_country_container cmn_modal_field"}>
														<label htmlFor="creative" className={props?.edit == true ? "lbl_position text-white" : 'lbl_position'}>Design ID</label>
														<input type="text" className={props?.edit == true ? 'form-control bg-secondary text-white' : "form-control"} name="designid" disabled={props?.edit} placeholder='Design ID' onChange={(e) => { handleChange(e); setDesignId(e.target.value) }} value={values.designid} />
													</div>
													{designIdError && (
														<span className='text-danger'>{"designid is required"}</span>
													)}
												</div>}

											</div>
										</div>
										{/*  */}
										<div className="row">
											<div className='col-12 mt-4'>
												<div className="d-flex justify-content-end mt-4">
													{props.id === undefined ? <button type="button" className="me-4 ms-auto cancel_cm_btn" onClick={() => { props?.setCreativeModalShow(false) }}>Cancel</button>
														: <button type="button" className="me-4 ms-auto cancel_cm_btn" onClick={() => { props?.setCreative_edit_modal_show(false); }}>Cancel</button>}
													<button className="cmn_btn ms-2 " type="submit">Save</button>
												</div>
												{/* <button type='submit' className="cmn_btn ms-auto">Save</button> */}

												{/* {!edit_data ? <button type="submit" className="cmn_btn ms-auto">Save</button> : <button type="submit" className="cmn_btn ms-auto" onClick={() => {handle_update_creative(values)}}>Edit</button>} */}
											</div>
										</div>
									</div>
								</div>
							</Form>
						)}
					</Formik>

				</div>
			</div>
		</>
	)
}
export default NewCreatives